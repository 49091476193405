import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  ArrowDown01Icon,
  ArrowRight01Icon,
  SidebarLeft01Icon,
  SidebarRight01Icon,
} from "hugeicons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tocDataCollection } from "./table-of-contents-data";
const TableOfContents = ({
  setSidebarOpen = () => {},
  sidebarOpen = true,
  userGroup = "",
  userRole = "",
}) => {
  // State to track which sections are expanded
  const [expandedSections, setExpandedSections] = useState({});
  // State to track the active section based on the URL hash
  const [activeSection, setActiveSection] = useState("");
  const location = useLocation();
  // Table of Contents Data
  const tocData = tocDataCollection;

  const navigate = useNavigate();
  // Update the active section when the location changes
  useEffect(() => {
    const currentHash = location.hash.substring(1); // Remove the '#' from the hash
    setActiveSection(currentHash);

    // Optionally, expand the hierarchy to the active section
    const expandToSection = (data, sectionId) => {
      for (const section of data) {
        if (section.id === sectionId) {
          return [section.id];
        }
        if (section.subSections && section.subSections.length > 0) {
          const expandedIds = expandToSection(section.subSections, sectionId);
          if (expandedIds.length > 0) {
            return [section.id, ...expandedIds];
          }
        }
      }
      return [];
    };

    const expandedIds = expandToSection(tocData, currentHash);
    setExpandedSections((prevState) =>
      expandedIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
    );
  }, [location]);

  // Toggle section visibility
  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  // Recursive function to render the Table of Contents
  const renderTOC = (data, currentUserGroup, currentUserRole) => (
    <ul>
      {data
        .filter(
          (section) =>
            section.userGroup.includes(currentUserGroup) &&
            section.userRole.includes(currentUserRole)
        ) // Check both userGroup and userRole
        .map((section) => (
          <li
            key={section.id}
            className={
              activeSection === section.id ? `${styles["active"]}` : ""
            }
          >
            {section.subSections && section.subSections.length > 0 ? (
              <>
                <button
                  onClick={() => {
                    // console.log(currentUserGroup);
                    navigate(`#${section.id}`); // Update the URL hash
                    toggleSection(section.id);
                    if (window.innerWidth < 900) {
                      setSidebarOpen(false); // Close the sidebar
                    }
                  }}
                  aria-expanded={expandedSections[section.id] || false}
                  className={styles["expand-button"]}
                >
                  {section.title}
                  {expandedSections[section.id] ? (
                    <ArrowDown01Icon />
                  ) : (
                    <ArrowRight01Icon />
                  )}
                </button>
                {expandedSections[section.id] &&
                  renderTOC(
                    section.subSections,
                    currentUserGroup,
                    currentUserRole
                  )}
              </>
            ) : (
              <Link
                to={`#${section.id}`}
                onClick={() => {
                  if (window.innerWidth < 900) {
                    setSidebarOpen(false); // Close the sidebar
                  }
                }}
              >
                {section.title}
              </Link>
            )}
          </li>
        ))}
    </ul>
  );

  return (
    <nav
      aria-label="Table of Contents"
      className={styles["sidebar"]}
      style={sidebarOpen ? { opacity: 1 } : { opacity: 0 }}
    >
      {/* <h2>Table of Contents</h2> */}
      {renderTOC(tocData, userGroup, userRole)}
    </nav>
  );
};

export default TableOfContents;
