const EN = {
  language: "Language:",
  "language.Preferred": "Preferred Language",
  Amount: "Amount",
  Office: "Office",
  TreatmentType: "Treatment Type",
  TreatmentDuration: "Treatment Duration",
  ChartNo: "Chart No.",
  "Information.Contact": "Contact Information",
  "Information.Personal": "Personal Information",
  "Information.Credit": "Credit & Finantial Information",
  preferredMonthlyPayment: "Preferred Monthly Payment",
  "preferredMonthlyPayment.Description":
    "Let us know your preferred monthly payment to tailor financing options to your budget.",
  annualIncome: "Annual Household Income",
  "annualIncome.Description":
    "Your annual income helps determine the financing you qualify for.",
  availableCredit: "Available Credit on Your Current Credit Cards",
  "availableCredit.Description":
    "The total credit currently available on your credit cards.",
  ssnNumber: "Social Security Number",
  AdvertisementDisclosure: "Advertisement Disclosure",
  "there is no preapproval for your request":
    "There is no preapproval for your request",
  "contact suggested lender for further processing":
    "Contact suggested lender for further processing",
  "You can fill your ptreferred application":
    "You can fill your ptreferred application",
  here: "Here",
  "it may help you with lenders": "It may help you with lenders",
  Congratulation: "Congratulations!",
  "We got you pre-approved with": "We got you pre-approved for",
  lenders: "financial products",
  "Andhara bank": "Andhara bank",
  Rated: "Rated",
  "Monthly payment": "Monthly Payment",
  "Financing type": "Financing Type",
  "Financing amount": "Financing Amount",
  "Number of installments": "Number of installments",
  "Interest rate": "Interest Rate",
  Apply: "Apply",
  ApplyNow: "Apply Now",
  "About Us": "About Us",
  "Terms of Service": "Terms of Service",
  "Privacy Policy": "Privacy Policy",
  "Terms Conditions": "Terms Conditions",
  "Help Center": "Help Center",
  "Cookies Settings": "Cookies Settings",

  TreatmentType_GENERALDENTISTRY: "General Dentistry",
  TreatmentType_ORTHODONTICS: "Orthodontics",
  TreatmentType_OTHERSPECIALTIES: "Other Specialties",

  Recommended: "Recommended",
  MinimumMonthlyPayment: "Minimum Monthly Payment",
  BestInterestRate: "Best Interest Rate",
  HighestAmount: "Highest Amount",

  LoanType_NONE: "None",
  LoanType_CREDITLINE: "Credit Line",
  LoanType_LOAN: "Loan",
  LoanType_CREDITREVOLVER: "Credit Revolver",
  LoanType_INSTALLMENTPLAN: "Installment Plan",
  LoanType_OTHER: "Other",

  "Errors.UnknownError": "An Error Occured",
  "Errors.InvalidLink": "Invalid Link",
  "Errors.InvalidApplication": "Invalid application",
  "Errors.Initiated": "This application is yet to be completed by the office.",
  "Errors.Submitted": "This application was submitted. Please wait...",
  "Errors.Processing": "This application is being processed. Please wait...",
  "Errors.Abandoned": "This application has been abandoned.",
  "Errors.ProcessingError":
    "There was an error in processing the application. Please ask the office to fill out a new application for you.",
  "Errors.NotAccessible":
    "This application is expired and no longer accessible.",
  "Errors.UnableToLoadApplication": "Unable to load the application.",
  "Errors.ApplicantTooManyAttempts":
    "This link is no longer valid because of too many unsuccessful attempts.",
  "Errors.ApplicationExpired":
    "This application is expired and no longer accessible.",
  "Errors.InvalidEntries":
    "Invalid entries. Please review your information and try again.",
  "Errors.Validation.YearOfBirth": "Invalid year of birth",
  "Errors.Validation.ZipCode": "Invalid ZIP code",
  "Errors.Validation.Consent":
    "You need to agree with the terms and conditions",
  Confirmation: "Please confirm your data for the next step.",
  "Confirmation.personal":
    "Please confirm your personal Information for the next step.",
  WelcomeToDentirate: "Welcome to DentiRate",
  YearOfBirth: "Year of Birth",
  "YearOfBirth.Enter": "Please complete the steps to access your application",
  ZipCode: "Zip Code ",
  "ZipCode.Enter": "Please enter your zip code to proceed",
  Submit: "Submit",
  SSN: "SSN",
  "SSN.Enter": "Please enter the required personal informations",
  "SSN.noSsn": "SSN is not available",
  "SSN.Description":
    "Providing your SSN helps us find the best financing options.",
  "SSN.CreditEffect": "“This process will not affect your credit score.”",
  IAgree: "I Agree",
  And: "and",

  "Button.Back": "Back",
  "Button.Continue": "Continue",

  "ContinuingAgreement.Part1": "By continuing, you agree to our ",
  "ContinuingAgreement.Part2": "Terms of Service",

  "Consent.WeNeedYourConsent": "We need your consent",
  "Consent.ReadLenderPartners":
    "Please carefully read the <terms>Terms and Conditions</terms> of our <partners>lender partners</partners>.",
  "Consent.ContinuingAgreement.Part1":
    "By continuing, you agree to all above items and our ",
  "Consent.ContinuingAgreement.Part2": "Terms of Service",
  "Consent.Agreement":
    'By continuing, you agree to all above items and our <terms>Terms of Service</terms> and <privacy>Privacy Policy</privacy>. I understand that by clicking on the I AGREE button immediately following this notice, I am providing "written instructions" to Dentirate under the Fair Credit Reporting Act authorizing Dentirate to obtain information from my personal credit profile or other information from Reseller. I authorize Dentirate to obtain such information solely to prequalify me for credit options related to dental treatment financing. Credit Information accessed for my pre-qualification request may be different from the Credit Information accessed by a credit grantor on a date after the date of my original prequalification request to make the credit decision.',
  "ConsentItem.ReadAndAgreed": "I have read and agreed to",
  "ConsentItem.TermsConditions": "Terms and Conditions",
  "ConsentItem.PrivacyPolicy": "Privacy Policy",
  "ConsentItem.LenderNamePossessive": "{lenderName}'s",
  ApplicantPageTitle: "Your Application | DentiRate",
  Identity: "Verify your identity",
  FirstName: "First Name",
  LastName: "Last Name",
  doB: "Date Of Birth",
  street1: "Street Line 1",
  street2: "Street Line 2",
  appUnit: "App/Unit",
  city: "City",
  State: "State",
  CellPhone: "Cell Phone Number",
  EmailAddress: "Email Address",
  InsuranceCoverage: "Insurance Coverage",
  DownPayment: "Upfront payment",
  notEditable:
    "For fields that cannot be edited, please contact our support team for modifications.",
  prefilled: "Enable Prefill Option",
  locked:
    "Fields are currently locked. To unlock and enable the Submit button, please activate the Prefill option.",
  homePhone: "Home Phone Number",
  workPhone: "Work Phone Number",
};
export default EN;
