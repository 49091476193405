import { useEffect, useState } from "react";
import {
  apiHelperMethods,
  commaSeperatedCurrency,
} from "../../infrastructure/helpers";
import ModalContainer from "../modal-control";
import styles from "./styles.module.scss";
import { apiLoadingStatus, apiUrls } from "../../infrastructure/constants/api";
import { sendGetKeyValue } from "../../utils/httpUtils";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import LoadingSpinner from "../loading-spinner/loading-spinner";

function formatDate(dateString) {
  if (!dateString) {
    return ``;
  }
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = "+1 ";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

const ApplicationDetailsModal = ({
  show = false,
  handleClose = () => {},
  application = {},
}) => {
  const [applicationInfo, setApplicationInfo] = useState({});
  const [isLoading, setIsloading] = useState(apiLoadingStatus.unloaded);

  useEffect(() => {
    if (!show) {
      return;
    }
    setIsloading(apiLoadingStatus.loading);
    const url = apiUrls.userIdentityUrls.GetApplicationById;
    const data = {
      id: application.id,
      healthCareGroupId: application.healthCareGroupId,
    };
    apiHelperMethods
      .getWithReturn(url, data)
      .then((res) => {
        setApplicationInfo(res.data.data.consumerApplication);
        // console.log(res.data.data.consumerApplication);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsloading(apiLoadingStatus.loaded));
  }, [show]);
  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose(false);
        // refreshapp();
      }}
      modalSize={"lg"}
      modalTitle={"Application Details"}
      centered
    >
      <LoadingSpinner isLoading={isLoading === apiLoadingStatus.loading} />
      <div className={styles["details-container"]}>
        <div className={styles["loc-info"]}>
          <div className={styles["title"]}>application Information</div>
          <div className={styles["item"]}>
            Application Type:{" "}
            <span>{application?.applicationInitiationType}</span>
          </div>
          <div className={styles["item"]}>
            Treatment Type:{" "}
            <span>
              {
                treatmentType.collection.find(
                  (i) =>
                    i.value ===
                    treatmentType.getValueByText(application?.treatmentType)
                ).label
              }
            </span>
          </div>
          <div className={styles["item"]}>
            Treatment Duration:{" "}
            <span>{application?.treatmentInMonth} Months</span>
          </div>
          <div className={styles["item"]}>
            Treatment Amount:{" "}
            <span>{commaSeperatedCurrency(application?.amount, 0)}</span>
          </div>
          <div className={styles["item"]}>
            Insurance Coverage:{" "}
            <span>
              {commaSeperatedCurrency(application?.insuranceCoverage, 0)}
            </span>
          </div>
          <div className={styles["item"]}>
            Preferred Monthly Payment:{" "}
            <span>
              {commaSeperatedCurrency(application?.preferredMonthlyPayment, 0)}
            </span>
          </div>
          <div className={styles["item"]}>
            Upfront Payment:{" "}
            <span>
              {commaSeperatedCurrency(application?.initialAvailableCash, 0)}
            </span>
          </div>
          <div className={styles["item"]}>
            Application Status:{" "}
            <span>{application?.simplifiedApplicationStatus}</span>
          </div>
          <div className={styles["item"]}>
            Create Date: <span>{formatDate(application?.createDate)}</span>
          </div>
          <div className={styles["item"]}>
            Submit Date: <span>{formatDate(application?.submitDate)}</span>
          </div>
        </div>
        <div className={styles["owner"]}>
          <div className={styles["title"]}>Applicant Information</div>
          <div className={styles["item"]}>
            First Name: <span>{applicationInfo.applicantInfo?.firstName}</span>
          </div>
          <div className={styles["item"]}>
            Last Name: <span>{applicationInfo.applicantInfo?.lastName}</span>
          </div>
          <div className={styles["item"]}>
            Date of Birth:{" "}
            <span>{formatDate(applicationInfo.applicantInfo?.doB)}</span>
          </div>
          <div className={styles["item"]}>
            Street Line 1: <span>{applicationInfo.applicantInfo?.street1}</span>
          </div>
          {!!applicationInfo.applicantInfo?.street2 && (
            <div className={styles["item"]}>
              Street Line 2:{" "}
              <span>{applicationInfo.applicantInfo?.street2}</span>
            </div>
          )}
          <div className={styles["item"]}>
            City: <span>{applicationInfo.applicantInfo?.city}</span>
          </div>
          <div className={styles["item"]}>
            State: <span>{applicationInfo.applicantInfo?.state}</span>
          </div>
          <div className={styles["item"]}>
            Zip Code: <span>{applicationInfo.applicantInfo?.zipcode}</span>
          </div>
          <div className={styles["item"]}>
            Cell Phone Number:{" "}
            <span>
              {formatPhoneNumber(applicationInfo.applicantInfo?.cellPhone)}
            </span>
          </div>
          {!!applicationInfo.applicantInfo?.workPhone && (
            <div className={styles["item"]}>
              Work Phone Number:{" "}
              <span>
                {formatPhoneNumber(applicationInfo.applicantInfo?.workPhone)}
              </span>
            </div>
          )}
          {!!applicationInfo.applicantInfo?.homePhone && (
            <div className={styles["item"]}>
              Home Phone Number:{" "}
              <span>
                {formatPhoneNumber(applicationInfo.applicantInfo?.homePhone)}
              </span>
            </div>
          )}
          <div className={styles["item"]}>
            Email Address:{" "}
            <span>{applicationInfo.applicantInfo?.emailAddress}</span>
          </div>
        </div>{" "}
        <div className={styles["owner"]}>
          <div className={styles["title"]}>Patient Information</div>
          <div className={styles["item"]}>
            First Name: <span>{applicationInfo.applicantInfo?.firstName}</span>
          </div>
          <div className={styles["item"]}>
            Last Name: <span>{applicationInfo.applicantInfo?.lastName}</span>
          </div>
          <div className={styles["item"]}>
            Date of Birth:{" "}
            <span>{formatDate(applicationInfo.applicantInfo?.doB)}</span>
          </div>
          <div className={styles["item"]}>
            Street Line 1: <span>{applicationInfo.applicantInfo?.street1}</span>
          </div>
          {!!applicationInfo.applicantInfo?.street2 && (
            <div className={styles["item"]}>
              Street Line 2:{" "}
              <span>{applicationInfo.applicantInfo?.street2}</span>
            </div>
          )}
          <div className={styles["item"]}>
            City: <span>{applicationInfo.applicantInfo?.city}</span>
          </div>
          <div className={styles["item"]}>
            State: <span>{applicationInfo.applicantInfo?.state}</span>
          </div>
          <div className={styles["item"]}>
            Zip Code: <span>{applicationInfo.applicantInfo?.zipcode}</span>
          </div>
          <div className={styles["item"]}>
            Cell Phone Number:{" "}
            <span>
              {formatPhoneNumber(applicationInfo.applicantInfo?.cellPhone)}
            </span>
          </div>
          {!!applicationInfo.applicantInfo?.workPhone && (
            <div className={styles["item"]}>
              Work Phone Number:{" "}
              <span>
                {formatPhoneNumber(applicationInfo.applicantInfo?.workPhone)}
              </span>
            </div>
          )}
          {!!applicationInfo.applicantInfo?.homePhone && (
            <div className={styles["item"]}>
              Home Phone Number:{" "}
              <span>
                {formatPhoneNumber(applicationInfo.applicantInfo?.homePhone)}
              </span>
            </div>
          )}
          <div className={styles["item"]}>
            Email Address:{" "}
            <span>{applicationInfo.applicantInfo?.emailAddress}</span>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ApplicationDetailsModal;
