import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Search01Icon,
  SidebarLeft01Icon,
  SidebarRight01Icon,
} from "hugeicons-react";
import Header from "../../../../components/header/header";
import { useCommonContext } from "../../../../common-context";
import TableOfContents from "./table-of-contents";
import { tocDataCollection } from "./table-of-contents-data";

const Layout = ({
  children = <></>,
  userGroup = "",
  userRole = "",
  handleSearch = () => {},
}) => {
  const [{ user, logo }] = useCommonContext();
  //open and close the sidebar
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // window.scrollTo(0, 0);

    const handleResize = () => {
      if (window.innerWidth < 900) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //////////mobile siwpe effect
  let startX = 0;
  let endX = 0;
  const threshold = 50; // Minimum swipe distance in pixels

  const openSideBar = () => setSidebarOpen(true);
  const closeSideBar = () => setSidebarOpen(false);

  useEffect(() => {
    const handleTouchStart = (event) => {
      startX = event.touches[0].clientX;
      endX = startX;
      // console.log("touchstart event.touches[0]", event.touches[0]);
    };

    const handleTouchMove = (event) => {
      endX = event.touches[0].clientX;
      // console.log("touchMove event.touches[0]", event.touches[0]);
    };

    const handleTouchEnd = () => {
      let diffX = endX - startX;
      // console.log("endX", endX, "startX", startX);
      // console.log(diffX, Math.abs(diffX), threshold);

      if (Math.abs(diffX) > threshold) {
        if (diffX > 0) {
          openSideBar(); // Swipe right opens sidebar
        }
        if (diffX < 0) {
          closeSideBar(); // Swipe left closes sidebar
        }
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  return (
    <div className={styles["container"]}>
      <Header userLogoUrl={logo.data?.url} user={user.data} />

      <main
        className={`${styles["main"]} ${
          sidebarOpen ? "" : styles["closed-side-bar"]
        }`}
      >
        {sidebarOpen ? (
          <SidebarLeft01Icon
            className={styles["menu-btn"]}
            onClick={() => {
              setSidebarOpen(false);
            }}
          />
        ) : (
          <SidebarRight01Icon
            className={styles["menu-btn"]}
            onClick={() => {
              setSidebarOpen(true);
            }}
          />
        )}
        <TableOfContents
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          userGroup={userGroup}
          userRole={userRole}
        />
        <div
          className={`${styles["content"]} ${
            sidebarOpen ? "" : styles["closed-side-bar"]
          }`}
        >
          <header>
            <div className={styles["search-container"]}>
              <input
                type="text"
                name="query"
                placeholder="Search"
                // defaultValue={searchFilterData.query}
                className={styles["search-text"]}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchQuery);
                  }
                }}
              />
              <Search01Icon
                className={styles["search-icon"]}
                onClick={() => {
                  handleSearch(searchQuery);
                }}
              />
            </div>
          </header>
          <content>{children}</content>
        </div>
      </main>
    </div>
  );
};

Layout.propTypes = {};

export default Layout;
