import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import imageUrlHelpers from "../../../../infrastructure/helpers/image-url-helpers";
const TutorialContent = ({ sectionId = "", userGroup, userRole }) => {
  // console.log(sectionId);
  return (
    <>
      {sectionId === "section1" && (
        <p className={styles["text"]}>
          DentiRate's platform connects dental practices with accessible patient
          financing solutions. This document serves as a detailed guide for
          effectively utilizing the platform, enabling healthcare professionals
          to simplify and optimize financing processes for their patients.
        </p>
      )}
      {sectionId === "subsection1-1" && (
        <>
          <p className={styles["text"]}>
            DentiRate is an AI-powered platform that simplifies dental financing
            by connecting healthcare providers, lenders, and patients in an
            all-in-one solution. It offers advanced features like financial
            product matching, helping patients find the best financing options
            based on their unique needs, and provides risk-based in-house
            financing for healthcare providers to offer customized payment
            plans. The intuitive design and AI-driven technology make the entire
            process (from application to approval) smooth, transparent, and
            stress-free.
          </p>
          <p className={styles["text"]}>
            For patients, DentiRate breaks down financial barriers, making
            necessary dental treatments more accessible without unnecessary
            hurdles. Healthcare providers benefit from increased patient
            acquisition and retention, as flexible financing encourages more
            patients to commit to recommended treatments. The platform
            integrates seamlessly into existing systems, enhancing the patient
            experience with minimal operational disruption.
          </p>
          <p className={styles["text"]}>
            By eliminating the complexities of traditional dental financing,
            DentiRate fosters healthier communities and supports better oral
            health outcomes. It enables dental practices to focus on delivering
            quality care while making treatments financially feasible for
            patients. In this way, DentiRate transforms dental care from a
            costly challenge into a manageable, straightforward process,
            creating a more inclusive approach to healthcare and improving
            smiles everywhere.
          </p>
        </>
      )}
      {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}>
            DentiRate provides two primary user roles to ensure effective
            management and flexibility within the platform: Admin and Office
            User.
          </p>
        </>
      )}
      {sectionId === "subsubsection1-2-1" && (
        <>
          <p className={styles["text"]}>
            Admins are key users who have elevated privileges to manage the
            DentiRate workflow in the dental offices effectively. The Admin role
            is typically assigned by the company to individuals who are
            responsible for overseeing the broader aspects of patient financing.
            Admins can perform all actions that Office Users are able to do,
            ensuring they can assist with any daily financing needs.
            Additionally, Admins have the ability to:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Adjust Workflows:</strong> Modify and optimize workflows
              to ensure best patient experience in your office(s).
            </li>
            <li>
              <strong>Manage Users:</strong> Add new users, remove existing
              users, and assign appropriate roles to maintain an efficient team
              structure.
            </li>
            <li>
              <strong>Location Management:</strong> Activate or deactivate
              location of office(s) as needed.
            </li>
            <li>
              <strong>Access Reports and KPIs:</strong> View comprehensive
              reports and key performance indicators (KPIs) to track the
              performance of dental offices, enabling data-driven decisions and
              strategic planning.
            </li>
          </ul>
          <p className={styles["text"]}>
            The Admin role is designed to provide the control necessary to
            ensure that all aspects of the platform are aligned with the
            company's operational goals, making it easier to manage both
            day-to-day and long-term activities.
          </p>
        </>
      )}
      {sectionId === "subsubsection1-2-2" && (
        <>
          <p className={styles["text"]}>
            Office Users are Treatment Coordinators and Office Managers who
            focus on the daily operations within the dental practice. Office
            Users have permissions that allow them to:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Create Financing Applications:</strong> Initiate new
              applications for patient financing, ensuring that patients can
              begin treatment without financial delay.
            </li>
            <li>
              <strong>View Applications and Status:</strong> See all financing
              applications and track their status to keep patients informed
              about their financing progress.
            </li>
            <li>
              <strong>Generate Basic Reports:</strong> Generate reports to
              follow up with patients, helping ensure no patient is overlooked
              in their financing or treatment process.
            </li>
          </ul>
          <p className={styles["text"]}>
            By clearly defining these roles, DentiRate ensures that each user
            has access to the tools they need, while maintaining appropriate
            levels of control and security over sensitive information.
          </p>
        </>
      )}
      {sectionId === "subsection1-3" && (
        <>
          <p className={styles["text"]}>
            Service Organization Control 2 (SOC 2) is a compliance framework
            established by the American Institute of Certified Public
            Accountants (AICPA). It focuses on evaluating the security,
            availability, and confidentiality of an organization's systems and
            data. By undergoing a SOC 2 audit, organizations demonstrate that
            they manage customer data in a controlled, monitored, and secure
            environment.
          </p>
          <p className={styles["text"]}>
            SOC 2 compliance is particularly critical for service providers,
            especially SaaS platforms, that store or process sensitive customer
            data in the cloud.
          </p>
          <h3 className={styles["inner-title"]}>Types of SOC 2 Audits:</h3>
          <ul className={styles["list"]}>
            <li>
              <strong>SOC 2 Type-1:</strong> Reviews the design and
              documentation of an organization’s controls at a specific moment
              in time.
            </li>
            <li>
              <strong>SOC 2 Type-2:</strong> Assesses how effectively these
              controls operate over a defined time period.
            </li>
          </ul>
          <h3 className={styles["inner-title"]}>
            Importance of SOC 2 Compliance:
          </h3>
          <p className={styles["text"]}>
            SOC 2 compliance highlights an organization’s dedication to
            protecting customer data. It establishes that the organization has
            implemented robust systems and processes to ensure:
          </p>
          <ul className={styles["list"]}>
            <li>Security and confidentiality of information.</li>
            <li>Trust and transparency with customers and stakeholders.</li>
            <li>
              Credibility in handling sensitive data, enhancing confidence in
              the organization’s services.
            </li>
          </ul>
        </>
      )}

      {sectionId === "section2" && <p className={styles["text"]}></p>}
      {sectionId === "subsection2-1" && (
        <>
          <p className={styles["text"]}>
            When your user account is created, you receive an automated email
            message that contains your username and a link to set your password.
            You must create a password before you can log in to DentiRate.
          </p>
          <p className={styles["text"]}>
            <strong>Important:</strong> You must set up the password within 24
            hours of receiving the email message. If 24 hours pass before you
            set up a password, you need to contact DentiRate support.
          </p>
          <p className={styles["text"]}>
            <strong>To set your password:</strong>
          </p>

          <ol className={styles["ordered-list"]}>
            <li>In the email message, click (or tap) the link.</li>
            <img
              src={`${imageUrlHelpers.urls.email}`}
              className={styles["image"]}
            />

            <li>
              Fill in the required fields and set a secure password and confirm
              your details.
            </li>
            <p className={styles["text"]}>
              <strong>Important:</strong> Passwords must be between 8 and 30
              characters in length and include at least one uppercase letter,
              one lowercase letter, one number, and one special character
              (!@#$%^&*()-_=+.?). Passwords are case-sensitive.
            </p>
            <li>Click (or tap) Continue.</li>
            <img
              src={`${imageUrlHelpers.urls.createNewUser}`}
              className={styles["image"]}
            />
          </ol>
          <p className={styles["text"]}>
            <strong>Note:</strong> Multi-factor authentication (MFA) is
            mandatory after account creation.
          </p>
        </>
      )}
      {sectionId === "subsection2-2" && (
        <>
          <p className={styles["text"]}>Passwords must:</p>
          <ol className={styles["ordered-list"]}>
            <li>
              Contain at least <strong>one digit</strong>.
            </li>
            <li>
              Contain at least <strong>one lowercase letter</strong>.
            </li>
            <li>
              Contain at least <strong>one uppercase letter</strong>.
            </li>
            <li>
              Contain at least <strong>one special character</strong>.
            </li>
            <li>
              Have a <strong>length between 8 and 30 characters</strong>.
            </li>
          </ol>
        </>
      )}
      {sectionId === "subsection2-3" && (
        <>
          <p className={styles["text"]}>
            After your user account has been created and you have set your
            password, you can log in to DentiRate.
          </p>
          <p className={styles["text"]}>
            <strong>To log in:</strong>
          </p>
          <ol className={styles["ordered-list"]}>
            <li>
              Navigate to the login page:{" "}
              <a
                href="https://portal.dentirate.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles["link"]}
              >
                https://portal.dentirate.com/
              </a>
            </li>
            <li>
              Enter your credentials and click <strong>"Continue"</strong>.
            </li>
            <img
              src={`${imageUrlHelpers.urls.login}`}
              className={styles["image"]}
            />
          </ol>
          <p className={styles["text"]}>
            <strong>Note:</strong> If you enter your password incorrectly five
            times, your user account will be locked for five minutes, during
            which time you will not be able to log in. Additionally, if after
            the initial five failed login attempts you enter your password
            incorrectly five more times, your user account will be blocked, and
            you need to contact DentiRate to reactivate your user account.
          </p>
        </>
      )}

      {sectionId === "subsection2-4" && (
        <>
          <p className={styles["text"]}>
            Steps to update and manage your password within the application:
          </p>
          <ol className={styles["ordered-list"]}>
            <li>
              Go to "User Menu" by clicking on your username on the top right
              corner of the web app.
            </li>
            <img
              src={`${imageUrlHelpers.urls.showMenu}`}
              className={styles["image"]}
            />
            <li>
              On the User menu, click (or tap) <strong>User Profile.</strong>
            </li>
            <img
              src={`${imageUrlHelpers.urls.menu}`}
              className={styles["image"]}
              style={{ maxWidth: "300px", border: "none" }}
            />
            <li>Select "Change Password".</li>
            <img
              src={`${imageUrlHelpers.urls.profile}`}
              className={styles["image"]}
            />
            <li>Enter the current password, then the new password.</li>
            <p className={styles["text"]}>
              <strong>Important:</strong> Passwords must be between 8 and 30
              characters in length and include at least one uppercase letter,
              one lowercase letter, one number, and one special character
              (!@#$%^&*()-_=+.?). Passwords are case-sensitive.
            </p>
            <li>Click (or tap) Continue.</li>
            <img
              src={`${imageUrlHelpers.urls.changePassword}`}
              className={styles["image"]}
            />
          </ol>
        </>
      )}
      {sectionId === "subsection2-5" && (
        <>
          <p className={styles["text"]}>
            How to recover the account if you forget the password.
          </p>
          <ol className={styles["ordered-list"]}>
            <li>
              Click <strong>"Forgot Password"</strong> on the login page.
            </li>
            <img
              src={`${imageUrlHelpers.urls.forgotPasswordFirst}`}
              className={styles["image"]}
            />
            <li>Enter your registered email.</li>
            <img
              src={`${imageUrlHelpers.urls.forgotPasswordSecond}`}
              className={styles["image"]}
            />
            <li>
              Click (or tap) <strong>Continue</strong>.
            </li>
            <p className={styles["text"]}>
              A confirmation message appears, and an email message is sent to
              you that contains instructions on how to reset your password.
            </p>

            <li>
              Open your email and click on the reset link sent to your inbox.
            </li>
            <img
              src={`${imageUrlHelpers.urls.forgotPasswordThird}`}
              className={styles["image"]}
            />
            <p className={styles["text"]}>
              The DentiRate website opens to reset your password.
            </p>
            <li>
              Change password. Type a new password in the New Password and
              Confirm Password boxes.
            </li>
            <img
              src={`${imageUrlHelpers.urls.forgotPasswordFourth}`}
              className={styles["image"]}
            />
            <p className={styles["text"]}>
              <strong>Important:</strong> Passwords must be between 8 and 30
              characters in length and include at least one uppercase letter,
              one lowercase letter, one number, and one special character
              (!@#$%^&*()-_=+.?). Passwords are case-sensitive.
            </p>
          </ol>
        </>
      )}

      {sectionId === "subsection2-6" && (
        <>
          <p className={styles["text"]}>
            You can log out of DentiRate manually at any time.
          </p>
          <p className={styles["text"]}>
            <strong>To log out:</strong>
          </p>
          <ol className={styles["ordered-list"]}>
            <li>
              On the <strong>User</strong> menu, click (or tap){" "}
              <strong>Logout</strong>.
            </li>
            <img
              src={`${imageUrlHelpers.urls.logout}`}
              className={styles["image"]}
            />
          </ol>
          <p className={styles["text"]}>
            <strong>Note:</strong> After a specified length of inactivity (no
            interaction with the DentiRate website from your computer),
            DentiRate logs you out automatically. The length of time after which
            a user is logged out is determined by a certain setting for that
            user's account.
          </p>
        </>
      )}

      {sectionId === "subsection3-1" && (
        <>
          <p className={styles["text"]}>
            The Home Page offers a detailed overview of application activity,
            historical trends, user engagement, locations, and application
            conversion rates.
          </p>
          <img
            src={imageUrlHelpers.urls.homeOverview}
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsection3-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection3-2-1" && (
        <>
          <p className={styles["text"]}>
            Located in the top-right corner, you can adjust the time frame for
            the application data displayed, choosing from:
          </p>
          <ul className={styles["list"]}>
            <li>Week to Date (WTD)</li>
            <li>Month to Date (MTD)</li>
            <li>Quarter to Date (QTD)</li>
            <li>Year to Date (YTD)</li>
            <img src={imageUrlHelpers.urls.time} className={styles["image"]} />
          </ul>
          <p className={styles["text"]}>
            <strong>Note:</strong> The selected time period applies to both the
            performance metrics table and the historical graph.
          </p>
        </>
      )}

      {sectionId === "subsubsection3-2-2" && (
        <>
          <p className={styles["text"]}>
            Displays application data for the chosen time period:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Submitted Applications:</strong> Total number of
              applications sent for pre-approval.
            </li>
            <li>
              <strong>Pre-Approved Applications:</strong> Number of applications
              that successfully received pre-approval.
            </li>
            <li>
              <strong>Prospect Applications:</strong> Total applications
              forwarded to lenders for further processing.
            </li>
            <img
              src={imageUrlHelpers.urls.overview}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection3-2-3" && (
        <>
          <p className={styles["text"]}>
            Visualizes application status trends over the selected period in a
            chart.
          </p>
          <img
            src={imageUrlHelpers.urls.homeGraph}
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsubsection3-2-4" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Active Users: Displays the current number of users engaged on the
              platform.
            </li>
            <li>
              Locations: Shows the total number of active locations associated
              with the platform.
            </li>
            <img
              src={imageUrlHelpers.urls.userLocation}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection3-2-5" && (
        <>
          <p className={styles["text"]}>
            This table provides a detailed breakdown of application data:
          </p>
          <p className={styles["text"]}>Metrics Included:</p>
          <ul className={styles["list"]}>
            <li>
              Amounts: The total dollar value of applications for each status.
            </li>
            <li>Count: Total number of applications for each status.</li>
            <li>Average Amount: Average dollar value per application.</li>
          </ul>
          <p className={styles["text"]}>Conversion Rates:</p>
          <ul className={styles["list"]}>
            <li>
              Pre-Approval Conversion Rate: The ratio of pre-approved
              applications to submitted applications.
            </li>
            <li>
              Prospect Conversion Rate: The ratio of prospect applications to
              pre-approved applications.
            </li>
          </ul>
          <p className={styles["text"]}>
            This comprehensive breakdown enables quick performance analysis and
            helps identify areas for improvement.
          </p>
          <img
            src={imageUrlHelpers.urls.performanceTable}
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "section4" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection4-1" && (
        <>
          <p className={styles["text"]}>
            Detailed guidance ensures that Treatment Coordinators (TCs) can
            accurately collect required information, obtain necessary consents,
            and proceed with pre-approval loan submissions seamlessly.
          </p>
        </>
      )}

      {sectionId === "subsubsection4-1-1" && (
        <>
          <p className={styles["text"]}>
            The Treatment Coordinator should clearly explain the financing
            process, emphasizing that the applicant's information will be used
            solely for pre-approvals and loan options presentation. It is
            important to reassure applicants that soft credit checks conducted
            by third-party lenders and DentiRate for IHF processing during this
            stage will not impact their credit score.
          </p>
        </>
      )}

      {sectionId === "subsubsection4-1-2" && (
        <>
          <p className={styles["text"]}>
            Consent must be obtained either digitally through the DentiRate
            platform or by having the applicant sign a printed hardcopy,
            ensuring full compliance with privacy and data protection
            regulations. Initial consent is also obtained to send a link to the
            applicant's device through SMS and email, facilitating compliant
            communication.
          </p>
          <img src={imageUrlHelpers.urls.consent} className={styles["image"]} />
        </>
      )}
      {sectionId === "subsubsection4-1-3" && (
        <>
          <p className={styles["text"]}>
            The New Application form is where TC provides basic applicant
            information in a structured format to collect all necessary details.
            This section is designed to gather information that has already been
            provided to the dental office, allowing the initiation of identity
            verification directly on the applicant's device.
          </p>
          <img
            src={imageUrlHelpers.urls.applicationForm}
            className={styles["image"]}
          />
          <p className={styles["inner-title"]}>
            <strong>Contract Information</strong>
          </p>
          <p className={styles["text"]}>
            This section allows TC to specify key details about the office and
            treatment:
          </p>
          <ul className={styles["list"]}>
            <li>
              Office: Select the office location where the treatment is being
              provided.
            </li>
            <li>
              Treatment Type: Choose the type of treatment the applicant is
              applying for (General, Ortho, and Other specialties).
            </li>
            <li>
              Treatment Duration: Enter the expected duration of the treatment
              (optional).
            </li>
            <li>
              Chart Number: Optionally, include the applicant’s chart number for
              reference.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Personal Information</strong>
          </p>
          <p className={styles["text"]}>
            Collect contact information to verify the applicant’s identity:
          </p>
          <ul className={styles["list"]}>
            <li>
              First Name and Last Name: Entering the name of the responsible
              party.
            </li>
            <li>
              Date of Birth: Use the date picker to select the applicant’s birth
              date.
            </li>
            <li>
              Preferred Language: Choose between English and Spanish to ensure
              the applicant receives communication in their preferred language.
            </li>
            <li>Address: Capture the applicant's full address.</li>
            <li>
              Email Address, and Cell Phone Number: Essential for sending the
              application link.
            </li>
            <li>
              Main Applicant: Indicates that the applicant is the main
              applicant.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Credit & Financial Information</strong>
          </p>
          <p className={styles["text"]}>
            Gather critical financial data for the loan application:
          </p>
          <ul className={styles["list"]}>
            <li>Treatment Cost ($): Enter the total cost of the treatment.</li>
            <li>
              Insurance Coverage ($): Specify the portion covered by insurance.
            </li>
            <li>
              Down Payment ($): Include any upfront payment being made by the
              applicant.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Application Request Type</strong>
          </p>
          <p className={styles["text"]}>
            Admins can configure the type of financing application. Available
            options are displayed as radio buttons:
          </p>
          <ul className={styles["list"]}>
            <li>
              Apply for both Third-Party and In-House Financing: Process
              third-party loans and IHF simultaneously and show all options to
              the applicant.
            </li>
            <li>
              Apply for Third-Party first, if declined, try In-House Financing:
              IHF options are only offered if external lender pre-approval is
              not secured.
            </li>
            <li>
              Only Apply for In-House Financing: Limit the application to
              in-house financing.
            </li>
            <li>
              Only Apply for Third-Party Financing: Process applications
              exclusively for third-party lenders.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Actions</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Save: Save the application progress for future editing.</li>
            <li>
              Submit: Finalize and send the application, which generates a
              unique application link for the applicant.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection4-2" && (
        <>
          <p className={styles["text"]}>
            Note that only applications with an "Initiated" status (not yet
            submitted) can be updated. Instructions for updating an existing
            application:
          </p>
          <ul className={styles["list"]}>
            <li>Locate the application in the "Applications" tab.</li>
            <li>
              Click "Edit"{" "}
              <img src={imageUrlHelpers.urls.applicationEdit} alt="edit-icon" />{" "}
              next to the application you want to update.
            </li>
            <li>Make the necessary changes.</li>
            <li>Save the updated information.</li>
            <img
              src={imageUrlHelpers.urls.editApplication}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsection4-3" && (
        <>
          <p className={styles["text"]}>
            Instructions for duplicating an existing application:
          </p>
          <ul className={styles["list"]}>
            <li>Find the application you wish to duplicate.</li>
            <li>
              Click "Duplicate"{" "}
              <img
                src={imageUrlHelpers.urls.applicationDuplicate}
                alt="edit-icon"
              />{" "}
              under the application options.
            </li>
            <li>Confirm the duplicated application.</li>
            <img
              src={imageUrlHelpers.urls.duplicateApplication}
              className={styles["image"]}
            />
          </ul>
          <p className={styles["text"]}>
            <strong>Tip:</strong> Use this feature to save time if the same
            applicant is not satisfied with the loan options and wants to apply
            again with more information.
          </p>
        </>
      )}

      {sectionId === "subsection4-4" && (
        <>
          <p className={styles["text"]}>
            The Extend Application feature enables the Treatment Coordinator to
            adjust and enhance an applicant's existing loan application to
            secure additional funding for any remaining balance. This feature is
            particularly valuable when the applicant has been partially funded
            by a lender or requires further financing through IHF. It complies
            with lender contract terms, as certain lenders may impose
            restrictions on extending loans or approving multiple loans for a
            single applicant. Steps for Extending an Application:
          </p>

          <p className={styles["inner-title"]}>
            <strong>Accessing the Extension Panel:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Navigate to the "Applications" section on the platform.</li>
            <li>
              Locate the relevant application and click the Extend Application
              icon{" "}
              <img
                src={imageUrlHelpers.urls.applicationExtend}
                alt="edit-icon"
              />{" "}
              .
            </li>
            <img
              src={imageUrlHelpers.urls.extend}
              className={styles["image"]}
            />
          </ul>
          <p className={styles["inner-title"]}>
            <strong>Completing the Extension Form:</strong>
          </p>
          <ul className={styles["list"]}>
            <img
              src={imageUrlHelpers.urls.extendForm}
              className={styles["image"]}
            />
            <p className={styles["inner-title"]}>
              <strong>Treatment Cost ($):</strong>
            </p>
            <ul className={styles["list"]}>
              <li>
                Enter the remaining treatment cost or the additional amount
                required.
              </li>
            </ul>

            <p className={styles["inner-title"]}>
              <strong>Application Request Type:</strong>
            </p>
            <p className={styles["text"]}>
              Select one of the following configurable options to determine the
              type of application extension:
            </p>
            <ul className={styles["list"]}>
              <li>
                Both Third-Party and In-House Financing: Process third-party
                loans and IHF simultaneously and show all options to the
                applicant.
              </li>
              <li>
                First Third-Party, Then In-House Financing: IHF options are only
                offered if external lender pre-approval is not secured.
              </li>
              <li>
                Only Apply for In-House Financing: Limit the application to
                in-house financing.
              </li>
              <li>
                Only Apply for Third-Party Financing: Process applications
                exclusively for third-party lenders.
              </li>
            </ul>

            <p className={styles["inner-title"]}>
              <strong>Selecting a Lender and Loan Amount:</strong>
            </p>
            <ul className={styles["list"]}>
              <li>
                Select Lender: From the dropdown list, choose the lender that
                has already approved and issued a loan, or BNPL facilitator, or
                IHF.
              </li>
              <li>
                Amount ($): Enter the exact loan amount issued by the selected
                lender.
              </li>
              <li>
                Click Add to include the lender and loan details in the
                application.
              </li>
            </ul>

            <p className={styles["inner-title"]}>
              <strong>Submitting the Extended Application:</strong>
            </p>
            <ul className={styles["list"]}>
              <li>Review all entered details carefully.</li>
              <li>
                Click Submit to finalize the extension and initiate the
                additional loan process.
              </li>
            </ul>
          </ul>
        </>
      )}

      {sectionId === "subsection4-5" && (
        <>
          <p className={styles["text"]}>
            To efficiently locate and manage applications in the DentiRate
            platform, use the following tools and features:
          </p>

          <p className={styles["inner-title"]}>
            <strong>Search Bar:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Use the search bar located at the top of the "Applications" tab.
            </li>
            <li>
              Enter keywords such as the applicant's name, application ID, or
              date to find a specific application.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Filters:</strong>
          </p>
          <p className={styles["text"]}>
            Apply filters to narrow down your search:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Amount:</strong> Filter applications by a specific loan
              amount range (e.g., "No min – No max").
            </li>
            <li>
              <strong>Status:</strong> Filter based on application status, such
              as "Initiated," "Submitted," or "Approved."
            </li>
            <li>
              <strong>Treatment Type:</strong> Choose from treatment categories
              such as "General Dentistry," "Orthodontics," or "Other
              Specialties."
            </li>
            <li>
              <strong>Locations:</strong> Select specific office locations if
              your organization operates multiple branches.
            </li>
          </ul>
          <img
            src={imageUrlHelpers.urls.applyFilters}
            className={styles["image"]}
          />
          <p className={styles["inner-title"]}>
            <strong>Viewing Application Details:</strong>
          </p>
          <p className={styles["text"]}>
            Once you locate an application, you can view the following details
            directly from the list:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Amount:</strong> The treatment cost entered in the
              application.
            </li>
            <li>
              <strong>Treatment Type:</strong> Indicates the type of dental
              treatment.
            </li>
            <li>
              <strong>Preferred Monthly Payment:</strong> Shows the monthly
              payment amount preferred by the applicant.
            </li>
            <li>
              <strong>Contact Information:</strong> Includes the applicant's
              email and phone number.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Action Buttons:</strong>
          </p>
          <p className={styles["text"]}>
            The DentiRate platform includes action buttons that allow Treatment
            Coordinators to manage applications efficiently. These buttons are
            accessible within the application list and provide quick options for
            editing, duplicating, or extending applications.
          </p>
          <ul className={styles["list"]}>
            <li>
              <img src={imageUrlHelpers.urls.applicationEdit} alt="edit-icon" />
              <strong>Edit:</strong>

              <ol
                className={styles["ordered-list"]}
                style={{ paddingLeft: "1rem" }}
              >
                <li>Click the edit icon to modify an existing application.</li>
                <li>
                  This feature is helpful for correcting errors, updating
                  applicant details, or making changes to treatment information
                  before submission.
                </li>
              </ol>
            </li>

            <li>
              <img
                src={imageUrlHelpers.urls.applicationDuplicate}
                alt="duplicate-icon"
              />
              <strong>Duplicate:</strong>
              <ol
                className={styles["ordered-list"]}
                style={{ paddingLeft: "1rem" }}
              >
                <li>
                  Use the duplicate icon to create a new application based on an
                  existing one.
                </li>
                <li>
                  This feature is especially useful for creating applications
                  for the same applicant or when a similar set of details is
                  required for a different applicant.
                </li>
              </ol>
            </li>

            <li>
              <img
                src={imageUrlHelpers.urls.applicationExtend}
                alt="extend-icon"
              />
              <strong>Extend:</strong>
              <ol
                className={styles["ordered-list"]}
                style={{ paddingLeft: "1rem" }}
              >
                <li>
                  The extend icon allows you to adjust an application for
                  additional financing needs.
                </li>
                <li>
                  Use this option when the applicant requires additional funding
                  for remaining treatment costs or to modify the loan amount
                  after partial approval.
                </li>
              </ol>
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection4-6" && (
        <>
          <p className={styles["text"]}>
            Explanation of how to check and understand the status of an
            application:
          </p>
          <ul className={styles["list"]}>
            <li>Each application will display a status next to it.</li>
          </ul>
          <img
            src={imageUrlHelpers.urls.applicationStatus}
            className={styles["image"]}
          />
          <p className={styles["text"]}>
            <strong>List of application status:</strong>
          </p>
          <ul className={styles["list"]}>
            <li className={styles["initiated"]}>Initiated</li>
            <li className={styles["pending-applicant-submission"]}>
              Pending applicant submission
            </li>
            <li className={styles["submitted"]}>Submitted</li>
            <li className={styles["processing"]}>Processing</li>
            <li className={styles["processed-successfuly-with-approval"]}>
              Approved
            </li>
            <li className={styles["processed-no-approval"]}>No approval</li>
            <li className={styles["applicant-applied"]}>Applied</li>
            <li className={styles["abandoned"]}>Abandoned</li>
            <li className={styles["processing-error"]}>
              <span style={{ color: "white" }}>Error</span>
            </li>
          </ul>
        </>
      )}

      {sectionId === "section5" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection5-1" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection5-1-1" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Navigate to the Locations section from the left-hand menu in the
              DentiRate platform.
            </li>
            <li>
              Click "New Location" button at the top-right corner of the
              Locations tab.
            </li>
            <img
              src={imageUrlHelpers.urls.navigateToAddLocation}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection5-1-2" && (
        <>
          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>
              Location Information:
            </strong>{" "}
            This section collects general information about the dental office's
            business entity.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Corporate/Legal Name:</strong> The official name of the
              business as registered with the government.
            </li>
            <li>
              <strong>Associated Practice Name or Store Number:</strong> An
              identifier or specific name associated with this location (e.g.,
              branch name or store number).
            </li>
            <li>
              <strong>Office/DBA Name:</strong> The "Doing Business As" name of
              the practice, which may differ from the legal name.
            </li>
            <li>
              <strong>Tax ID Number:</strong> The business's unique tax
              identification number issued by the government.
            </li>
            <li>
              <strong>Type of Ownership:</strong> Specifies the ownership
              structure (e.g., Sole Proprietorship, LLC, Corporation).
            </li>
            <li>
              <strong>Total Number of Employees:</strong> The total number of
              staff members employed at this location.
            </li>
            <li>
              <strong>Date Business Started:</strong> The date when the practice
              began operations.
            </li>
            <li>
              <strong>Practice/Medical Specialty, or Business Type:</strong> The
              type of dental services provided (e.g., General Dentistry,
              Orthodontics, Endodontics).
            </li>
            <li>
              <strong>Practice Management Software:</strong> The software system
              used to manage patient records and administrative tasks.
            </li>
            <li>
              <strong>Office Phone Number:</strong> The main contact phone
              number for the office.
            </li>
            <li>
              <strong>Fax Number:</strong> The office's fax number (optional).
            </li>
            <li>
              <strong>Office Website:</strong> The practice’s website URL
              (optional).
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>Physical Address:</strong>{" "}
            This section specifies the physical location of the dental office.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Street Line 1:</strong> The first line of the street
              address.
            </li>
            <li>
              <strong>Street Line 2:</strong> The second line of the address
              (e.g., suite number, unit number).
            </li>
            <li>
              <strong>City:</strong> The city where the office is located.
            </li>
            <li>
              <strong>State:</strong> The state or province where the office is
              located.
            </li>
            <li>
              <strong>Zip Code:</strong> The postal/zip code of the office's
              physical address.
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>Mailing Address:</strong>{" "}
            If the mailing address is different from the physical address,
            provide details here.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Street Line 1:</strong> The first line of the mailing
              address.
            </li>
            <li>
              <strong>Street Line 2:</strong> The second line of the address (if
              applicable).
            </li>
            <li>
              <strong>City:</strong> The city for mailing correspondence.
            </li>
            <li>
              <strong>State:</strong> The state or province for the mailing
              address.
            </li>
            <li>
              <strong>Zip Code:</strong> The postal/zip code for mail delivery.
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>
              Owner Information:
            </strong>{" "}
            This section records details about the dental office owner.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>First Name:</strong> The owner’s first name.
            </li>
            <li>
              <strong>Last Name:</strong> The owner’s last name.
            </li>
            <li>
              <strong>Medical/Business/State License Number:</strong> The
              license number associated with the owner’s medical practice or
              business.
            </li>
            <li>
              <strong>Street Line 1:</strong> The first line of the owner's
              address.
            </li>
            <li>
              <strong>Street Line 2:</strong> The second line of the owner’s
              address (if applicable).
            </li>
            <li>
              <strong>City:</strong> The city of the owner’s residence.
            </li>
            <li>
              <strong>State:</strong> The state or province of the owner’s
              address.
            </li>
            <li>
              <strong>Zip Code:</strong> The postal/zip code of the owner’s
              address.
            </li>
            <li>
              <strong>Phone Number:</strong> The owner’s phone number for
              contact.
            </li>
            <li>
              <strong>Email Address:</strong> The owner’s email address.
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>Managing Doctor:</strong>{" "}
            This section provides information about the doctor managing the
            location.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>First Name:</strong> The managing doctor’s first name.
            </li>
            <li>
              <strong>Last Name:</strong> The managing doctor’s last name.
            </li>
            <li>
              <strong>Medical License Number:</strong> The doctor’s medical
              license number.
            </li>
            <li>
              <strong>Medical License State:</strong> The state where the
              medical license is registered.
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>
              Contact Information:
            </strong>{" "}
            Details about the primary contact person for this location.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>First Name:</strong> First name of the primary contact
              person.
            </li>
            <li>
              <strong>Last Name:</strong> Last name of the primary contact
              person.
            </li>
            <li>
              <strong>Office Phone Number:</strong> The contact's phone number
              at the office.
            </li>
            <li>
              <strong>Email Address:</strong> The contact person’s email
              address.
            </li>
          </ul>

          <p className={styles["text"]}>
            <strong className={styles["inner-title"]}>Bank Information:</strong>{" "}
            This section is required for setting up financial transactions for
            the dental office.
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Bank Name:</strong> The name of the bank where the
              office’s account is held.
            </li>
            <li>
              <strong>Routing Number:</strong> The bank's routing number for
              financial transfers.
            </li>
            <li>
              <strong>Account Number:</strong> The dental office’s bank account
              number.
            </li>
            <li>
              <strong>Bank Letter or Void Check as an Attachment:</strong> A
              document (bank letter or voided check) proving account ownership.
            </li>
          </ul>
          <img
            src={imageUrlHelpers.urls.newLocationForm}
            className={styles["image"]}
          />
          <p className={styles["inner-title"]}>
            <strong>Submit the Form:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Fields marked with a red asterisk (
              <span style={{ color: "#c00f0c" }}>*</span>) are required and must
              be filled out before submitting the form.
            </li>
            <li>
              After verifying the information, click the "Submit" button to save
              the new location’s details.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection5-1-3" && (
        <>
          <p className={styles["text"]}>
            Once the location is successfully created:
          </p>
          <ul className={styles["list"]}>
            <li>
              An <strong>invitation email</strong> will be sent to the provided
              email address.
            </li>
            <li>
              The email contains a link to activate their account and set a
              secure password.
            </li>
          </ul>
          <p className={styles["text"]}>
            This process ensures that locations are set up correctly and have
            access to their assigned locations.
          </p>
        </>
      )}

      {sectionId === "subsection5-2" && (
        <>
          <p className={styles["text"]}>
            The <strong>Locations</strong> section in DentiRate allows
            administrators to update locations details effectively.
          </p>
        </>
      )}

      {sectionId === "subsubsection5-2-1" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Navigate to the Locations section from the left-side menu in the
              DentiRate platform.
            </li>
            <li>
              Click <strong>"Show More"</strong> to view the detail of a
              registered location.
            </li>
            <img
              src={imageUrlHelpers.urls.locationShowMore}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection5-2-2" && (
        <>
          <ul className={styles["list"]}>
            <li>
              To activate or deactivate a location, toggle the switch{" "}
              <img src={imageUrlHelpers.urls.toggleActive} alt="switch-img" />{" "}
              next to the location's name to <strong>Active</strong> or{" "}
              <strong>Inactive</strong>.
            </li>
            <img
              src={imageUrlHelpers.urls.locationStatusUpdate}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection5-2-3" && (
        <>
          <p className={styles["text"]}>
            To update a location's information, follow these steps:
          </p>
          <ul className={styles["list"]}>
            <li>
              Click on the edit icon{" "}
              <img src={imageUrlHelpers.urls.locationEditIcon} /> located in the
              top-right corner of the location's box.
            </li>
            <li>Make the necessary updates to the location's details.</li>
            <li>Submit your changes.</li>
          </ul>
          <p className={styles["text"]}>
            The DentiRate support team will receive your request. After
            verification, the changes will be applied. If additional information
            or supporting documents are required, the support team will contact
            you for further clarification.
          </p>
          <img
            src={imageUrlHelpers.urls.locationEditBtn}
            className={styles["image"]}
          />
          <p className={styles["text"]}>
            These features enable the admin to maintain accurate location
            details and manage them effectively based on operational needs. This
            ensures seamless workflows, precise access control, and alignment
            with organizational policies.
          </p>
        </>
      )}

      {sectionId === "section6" && <></>}

      {sectionId === "subsection6-1" && (
        <>
          <p className={styles["text"]}>
            The <strong>Billing</strong> section in DentiRate provides a
            detailed overview of all generated bills, their corresponding
            payment statuses, and associated details. This feature allows admins
            to track and manage billing efficiently. Steps to View Bills:
          </p>
          <img
            src={imageUrlHelpers.urls.billingOverview}
            className={styles["image"]}
          />

          <p className={styles["inner-title"]}>
            <strong>Access the Billing Section:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Navigate to the Billing section from the left-hand menu.</li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Filter and Search:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Use filters such as Amount Range and Status to refine the list of
              bills.
            </li>
            <li>
              You can also search for specific bills using keywords or date
              ranges.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Bill Details:</strong>
          </p>
          <p className={styles["text"]}>
            Each bill entry displays the following:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Number of Leads:</strong> The number of leads generated
              for the billing period.
            </li>
            <li>
              <strong>Amount Due:</strong> The total amount payable for the
              billing cycle.
            </li>
            <li>
              <strong>Due Date:</strong> The date by which the payment is
              expected.
            </li>
            <li>
              <strong>Payment Status:</strong> The current status of the
              payment:
              <ul className={styles["list"]}>
                <li>
                  <strong>Void:</strong> The bill has been canceled.{" "}
                  <img
                    src={imageUrlHelpers.urls.billStatusVoid}
                    alt="void-img"
                  />
                </li>
                <li>
                  <strong>Paid:</strong> The bill has been settled successfully.{" "}
                  <img
                    src={imageUrlHelpers.urls.billStatusPaid}
                    alt="paid-img"
                  />
                </li>
                <li>
                  <strong>Issued:</strong> The bill is pending payment.{" "}
                  <img
                    src={imageUrlHelpers.urls.billStatusIssued}
                    alt="issued-img"
                  />
                </li>
              </ul>
            </li>
            <li>
              <strong>Action Button:</strong> Click the link icon{" "}
              <img src={imageUrlHelpers.urls.billLink} alt="bill-link" /> to
              view or download a detailed invoice.
            </li>
          </ul>
        </>
      )}

      {sectionId === "section7" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection7-1" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection7-1-1" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Navigate to the User Management section from the left-hand menu in
              the DentiRate platform.
            </li>
            <li>
              Click <strong>"New User" Button:</strong> Locate and click the
              "New User" button at the top-right corner of the Users tab.
            </li>
            <img
              src={imageUrlHelpers.urls.navigateToAddUser}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection7-1-2" && (
        <>
          <p className={styles["text"]}>
            To add a new user, follow these steps to complete the form and
            assign appropriate locations:
          </p>
          <img
            src={imageUrlHelpers.urls.addNewUser}
            className={styles["image"]}
          />
          <ul className={styles["list"]}>
            <li>
              <strong>First Name:</strong> Enter the first name of the user in
              the provided field.
            </li>
            <li>
              <strong>Last Name:</strong> Enter the last name of the user.
            </li>
            <li>
              <strong>Email:</strong> Provide a valid email address for the
              user. This email will be used for login and communication.
            </li>
            <li>
              <strong>Healthcare Group Locations:</strong>
              <ul className={styles["list"]}>
                <li>
                  Use the dropdown menu to select the location(s) where the user
                  is assigned.
                </li>
                <li>
                  If the user is associated with multiple locations, ensure all
                  relevant locations are selected.
                </li>
              </ul>
            </li>
            <li>
              <strong>Submit the Form:</strong>
              <ul className={styles["list"]}>
                <li>
                  After verifying the information, click the "Create User"
                  button to save the new user’s details.
                </li>
              </ul>
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection7-1-3" && (
        <>
          <p className={styles["text"]}>
            Once the user is successfully created:
          </p>
          <ul className={styles["list"]}>
            <li>
              An <strong>invitation email</strong> will be sent to the provided
              email address.
            </li>
            <li>
              The email contains a link to activate their account and set a
              secure password.
            </li>
          </ul>
          <p className={styles["text"]}>
            This process ensures that users are set up correctly and have access
            to their assigned locations.
          </p>
        </>
      )}

      {sectionId === "subsection7-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection7-3" && (
        <>
          <p className={styles["text"]}>
            The <strong>User Management</strong> section in DentiRate allows
            administrators to update user details, manage their access, and
            assign locations effectively.
          </p>
        </>
      )}

      {sectionId === "subsubsection7-3-1" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Navigate to the <strong>User Management</strong> section from the
              left-side menu in the DentiRate platform.
            </li>
            <li>
              Locate the user you want to update in the list displayed under
              User Management.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection7-3-2" && (
        <>
          <ul className={styles["list"]}>
            <li>
              To activate or deactivate a user, toggle the switch next to the
              user's name to <strong>Active</strong>{" "}
              <img src={imageUrlHelpers.urls.toggleActive} /> or{" "}
              <strong>Inactive</strong>{" "}
              <img src={imageUrlHelpers.urls.toggleInactive} />.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection7-3-3" && (
        <>
          <p className={styles["text"]}>
            For users assigned to multiple locations:
          </p>
          <ul className={styles["list"]}>
            <li>
              Click "Show More" to view the complete list of associated
              locations.
            </li>
            <li>Review the locations linked to the user.</li>
            <li>
              After completing your review, click "Show Less" to collapse the
              location list for a cleaner view.
            </li>
          </ul>
          <img
            src={imageUrlHelpers.urls.userManagementInterface}
            className={styles["image"]}
          />
          <p className={styles["text"]}>
            Using these features, the admin can ensure user access and location
            assignments are accurate and up-to-date, enhancing both operational
            efficiency and compliance with organizational policies. Regularly
            reviewing and managing user statuses and locations helps maintain
            workflows while ensuring that only authorized personnel have access
            to specific areas and functions.
          </p>
        </>
      )}

      {sectionId === "subsubsection7-3-4" && (
        <>
          <ul className={styles["list"]}>
            <li>
              Locate the user and click the <strong>pencil icon</strong>{" "}
              <img src={imageUrlHelpers.urls.applicationEdit} alt="edit-icon" />{" "}
              next to the user's name to edit their information.
            </li>
          </ul>
        </>
      )}

      {sectionId === "section8" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection8-1" && (
        <>
          <p className={styles["text"]}>
            The dashboard provides an intuitive summary of key metrics and
            activity on the DentiRate platform. It offers actionable insights to
            monitor performance and user engagement effectively.
          </p>
          <p className={styles["inner-title"]}>
            <strong>Key Elements of the Dashboard:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Active Users:</strong> The number of users currently using
              the platform.
            </li>
            <li>
              <strong>Locations:</strong> The total number of active locations
              associated with the platform.
            </li>
            <li>
              <strong>Performance Metrics:</strong>
              <ul className={styles["list"]}>
                <li>
                  <strong>Submitted Applications:</strong> Total applications
                  sent for pre-approval.
                </li>
                <li>
                  <strong>Pre-Approved Applications:</strong> Total applications
                  that received pre-approvals.
                </li>
                <li>
                  <strong>Prospect Applications:</strong> Total applications
                  forwarded to lenders.
                </li>
              </ul>
            </li>
          </ul>
          <img
            src={imageUrlHelpers.urls.dashboardOverview}
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsection8-2" && (
        <>
          <p className={styles["text"]}>
            The <strong>Summary Report</strong> provides detailed metrics and
            KPIs to track application performance.
          </p>
          <img
            src={imageUrlHelpers.urls.summaryReport}
            alt="summary-report-img"
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsection8-3" && (
        <>
          <p className={styles["text"]}>
            The <strong>Download Center</strong> allows users to generate
            reports for performance analysis and compliance. Steps to Generate a
            Report:
          </p>
          <p className={styles["text"]}>
            <strong>Select Groups:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Choose between Users, Locations, or Lenders.</li>
          </ul>
          <p className={styles["text"]}>
            <strong>Set Date Range:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Use the date picker to define the reporting period.</li>
          </ul>
          <p className={styles["text"]}>
            <strong>Create Report:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Click Create Report to generate and download the desired report.
            </li>
          </ul>
          <img
            src={imageUrlHelpers.urls.downloadCenter}
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsection8-4" && (
        <>
          <ul className={styles["list"]}>
            <li>
              <strong className={styles["inner-title"]}>
                Submitted Applications:
              </strong>{" "}
              The number of applications submitted for lender review.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Pre-Approved Applications:
              </strong>{" "}
              Applications approved by lenders.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Prospect Applications:
              </strong>{" "}
              Applications forwarded for final review.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Pre-Approved Rate:
              </strong>{" "}
              Percentage of pre-approved applications relative to total
              submissions.
            </li>
            <li>
              <strong className={styles["inner-title"]}>Prospect Rate:</strong>{" "}
              Percentage of prospect applications relative to total submissions.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Submitted Amount:
              </strong>{" "}
              Total funding amount requested via submitted applications.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Pre-Approved Amount:
              </strong>{" "}
              Total funding approved by lenders.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Prospect Amount:
              </strong>{" "}
              Total funding for prospect applications.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Average Submitted Amount:
              </strong>{" "}
              The average funding requested per submitted application.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Average Pre-Approved Amount:
              </strong>{" "}
              The average funding approved per pre-approved application.
            </li>
            <li>
              <strong className={styles["inner-title"]}>
                Average Prospect Amount:
              </strong>{" "}
              The average funding amount for prospects.
            </li>
          </ul>
        </>
      )}

      {sectionId === "section9" && (
        <>
          <p className={styles["text"]}>
            The <strong>Partnership Hub</strong> section provides a list of
            lenders with whom your organization has contracts. By clicking on a
            lender's name, you will be directed to their respective payment
            management hub.
          </p>
          <img
            src={imageUrlHelpers.urls.partnerHub}
            alt="partnership-hub-img"
            className={styles["image"]}
          />
          <p className={styles["text"]}>
            For more detailed information on how the payment management system
            works for each lender, please refer to Appendix A.
          </p>
        </>
      )}

      {sectionId === "section10" && (
        <>
          <p className={styles["text"]}>
            The application process begins in the dental office, where the
            dentist conducts a comprehensive exam and recommends appropriate
            treatments. From there, the Treatment Coordinator (TC) guides
            patients through their insurance benefits and available financing
            options for out-of-pocket expenses. By simplifying the often-complex
            landscape of dental financing, the TC and DentiRate work together to
            provide a smooth and efficient experience. This holistic approach
            allows patients to focus on their health and well-being, while
            providers can dedicate their attention to delivering excellent care,
            knowing that financial logistics are being handled professionally in
            the background. The result is a streamlined, patient-centric journey
            that makes dental treatments more accessible by removing financial
            barriers, ultimately enabling patients to receive the timely and
            effective care they need.
          </p>
        </>
      )}

      {sectionId === "subsection10-1" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection10-1-1" && (
        <>
          <p className={styles["text"]}>
            The journey starts with obtaining explicit consent from the
            applicant:
          </p>
          <p className={styles["inner-title"]}>
            <strong>Explain the Process:</strong>
          </p>
          <p className={styles["text"]}>
            The Treatment Coordinator should clearly explain the financing
            process, emphasizing that the applicant's information will be used
            solely for pre-approvals and loan options presentation. It is
            important to reassure applicants that soft credit checks conducted
            by third-party lenders and DentiRate for IHF processing during this
            stage will not impact their credit score.
          </p>

          <p className={styles["inner-title"]}>
            <strong>Obtain Consent:</strong>
          </p>
          <p className={styles["text"]}>
            Consent must be obtained either digitally through the DentiRate
            platform or by having the applicant sign a printed hardcopy,
            ensuring full compliance with privacy and data protection
            regulations. Initial consent is also obtained to send a link to the
            applicant's device through SMS and email, facilitating compliant
            communication.
          </p>

          <img
            src={imageUrlHelpers.urls.applicationConsent}
            alt="consent-screen-agreement-details"
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "subsubsection10-1-2" && (
        <>
          <p className={styles["text"]}>
            The <strong>New Application</strong> form is where TC provides basic
            applicant information in a structured format to collect all
            necessary details. This section is designed to gather information
            that has already been provided to the dental office, allowing the
            initiation of identity verification directly on the applicant's
            device.
          </p>
          <img
            src={imageUrlHelpers.urls.applicationForm}
            alt="new-application-form"
            className={styles["image"]}
          />
          <p className={styles["inner-title"]}>
            <strong>Contract Information:</strong>
          </p>
          <p className={styles["text"]}>
            This section allows TC to specify key details about the office and
            treatment:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Office:</strong> Select the office location where the
              treatment is being provided.
            </li>
            <li>
              <strong>Treatment Type:</strong> Choose the type of treatment the
              applicant is applying for (General, Ortho, and Other specialties).
            </li>
            <li>
              <strong>Treatment Duration:</strong> Enter the expected duration
              of the treatment (optional).
            </li>
            <li>
              <strong>Chart Number:</strong> Optionally, include the applicant’s
              chart number for reference.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Personal Information:</strong>
          </p>
          <p className={styles["text"]}>
            Collect contact information to verify the applicant’s identity:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>First Name and Last Name:</strong> Entering the name of
              the responsible party.
            </li>
            <li>
              <strong>Date of Birth:</strong> Use the date picker to select the
              applicant’s birth date.
            </li>
            <li>
              <strong>Preferred Language:</strong> Choose between English and
              Spanish to ensure the applicant receives communication in their
              preferred language.
            </li>
            <li>
              <strong>Address:</strong> Capture the applicant's full address.
            </li>
            <li>
              <strong>Email Address, and Cell Phone Number:</strong> Essential
              for sending the application link.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Credit & Financial Information:</strong>
          </p>
          <p className={styles["text"]}>
            Gather critical financial data for the loan application:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Treatment Cost ($):</strong> Enter the total cost of the
              treatment.
            </li>
            <li>
              <strong>Insurance Coverage ($):</strong> Specify the portion
              covered by insurance.
            </li>
            <li>
              <strong>Down Payment ($):</strong> Include any upfront payment
              being made by the applicant.
            </li>
            <li>
              <strong>Preferred Monthly Payment:</strong> Indicate the
              applicant's preferred payment amount.
            </li>
            <li>
              <strong>SSN1:</strong> Social Security Number for credit
              verification (optional if "SSN is not available" is checked).
            </li>
            <li>
              <strong>Annual Income ($):</strong> Include the applicant's yearly
              income for loan assessment.
            </li>
            <li>
              <strong>Available Credit ($):</strong> Specify any existing credit
              available on applicant’s credit card for BNPL options.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Application Request Type:</strong>
          </p>
          <p className={styles["text"]}>
            Admins can configure the type of financing application. Available
            options are displayed as radio buttons:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>
                Apply for both Third-Party and In-House Financing:
              </strong>{" "}
              Process third-party loans and IHF simultaneously and show all
              options to the applicant.
            </li>
            <li>
              <strong>
                Apply for Third-Party first, if declined, try In-House
                Financing:
              </strong>{" "}
              IHF options are only offered if external lender pre-approval is
              not secured.
            </li>
            <li>
              <strong>Only Apply for In-House Financing:</strong> Limit the
              application to in-house financing.
            </li>
            <li>
              <strong>Only Apply for Third-Party Financing:</strong> Process
              applications exclusively for third-party lenders.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Actions:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Save:</strong> Save the application progress for future
              editing.
            </li>
            <li>
              <strong>Submit:</strong> Finalize and send the application, which
              generates a unique application link for the applicant.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection10-1-3" && (
        <>
          <p className={styles["text"]}>
            The <strong>Extend Application</strong> feature enables the
            Treatment Coordinator to adjust and enhance an applicant's existing
            loan application to secure additional funding for any remaining
            balance. This feature is particularly valuable when the applicant
            has been partially funded by a lender or requires further financing
            through IHF. It complies with lender contract terms, as certain
            lenders may impose restrictions on extending loans or approving
            multiple loans for a single applicant. Steps for Extending an
            Application:
          </p>
          <img
            src={imageUrlHelpers.urls.applicationExtentionForm}
            alt="application-extension-form"
            className={styles["image"]}
          />
          <p className={styles["inner-title"]}>
            <strong>Accessing the Extension Panel:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Navigate to the "Applications" section on the platform.</li>
            <li>
              Locate the relevant application and click the Extend Application
              icon{" "}
              <img
                src={imageUrlHelpers.urls.applicationExtend}
                alt="extend-icon"
              />
              .
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Completing the Extension Form:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Treatment Cost ($):</strong> Enter the remaining treatment
              cost or the additional amount required.
            </li>
            <li>
              <strong>Preferred Monthly Payment:</strong> Specify the monthly
              payment amount desired by the applicant.
            </li>
            <li>
              <strong>Annual Income:</strong> Provide updated annual income
              details if needed for additional assessment.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Application Request Type:</strong>
          </p>
          <p className={styles["text"]}>
            Select one of the following configurable options to determine the
            type of application extension:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Both Third-Party and In-House Financing:</strong> Process
              third-party loans and IHF simultaneously and show all options to
              the applicant.
            </li>
            <li>
              <strong>First Third-Party, Then In-House Financing:</strong> IHF
              options are only offered if external lender pre-approval is not
              secured.
            </li>
            <li>
              <strong>Only Apply for In-House Financing:</strong> Limit the
              application to in-house financing.
            </li>
            <li>
              <strong>Only Apply for Third-Party Financing:</strong> Process
              applications exclusively for third-party lenders.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Selecting a Lender and Loan Amount:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Select Lender:</strong> From the dropdown list, choose the
              lender that has already approved and issued a loan, or BNPL
              facilitator, or IHF.
            </li>
            <li>
              <strong>Amount ($):</strong> Enter the exact loan amount issued by
              the selected lender.
            </li>
            <li>
              <strong>Click Add:</strong> Include the lender and loan details in
              the application.
            </li>
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Submitting the Extended Application:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Review all entered details carefully.</li>
            <li>
              Click Submit to finalize the extension and initiate the additional
              loan process.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection10-1-4" && (
        <>
          <p className={styles["text"]}>
            Once the application is submitted, the platform sends a personalized
            link to the applicant via email and SMS.
          </p>
          <img
            src={imageUrlHelpers.urls.applicationLinkEmail}
            alt="application-link-email"
            className={styles["image"]}
          />
          <br />
          <img
            src={imageUrlHelpers.urls.applicationLinkSms}
            alt="application-link-sms"
            className={styles["image"]}
            style={{ maxWidth: "300px" }}
          />
          <p className={styles["inner-title"]}>
            <strong>Instructions for the Applicant:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Open the link on their device.</li>
            <li>
              Follow the on-screen instructions to begin the loan application
              process.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection10-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection10-2-1" && (
        <>
          <p className={styles["text"]}>
            Applicants begin by accessing the personalized link sent via SMS or
            email.
          </p>
          <p className={styles["inner-title"]}>
            <strong>Identity Verification:</strong>
          </p>
          <p className={styles["text"]}>
            The platform requires the applicant to input:
          </p>
          <ul className={styles["list"]}>
            <li>Year of Birth</li>
            <li>Postal Code</li>
            <img
              src={imageUrlHelpers.urls.verification1}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.verification2}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.verification3}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
          </ul>

          <p className={styles["inner-title"]}>
            <strong>Terms & Conditions:</strong>
          </p>
          <p className={styles["text"]}>
            Applicants must review and agree to the terms and privacy policy to
            proceed.
          </p>

          <img
            src={imageUrlHelpers.urls.applicationConsentMobile}
            alt="applicant-consent-screen"
            className={styles["image"]}
            style={{ maxHeight: "500px", margin: "5px 15px 10px 0" }}
          />
        </>
      )}

      {sectionId === "subsubsection10-2-2" && (
        <>
          <p className={styles["text"]}>
            Once identity verification is complete, DentiRate presents
            pre-qualified loan options tailored to the applicant’s profile.
          </p>
          <p className={styles["inner-title"]}>
            <strong>Displayed Information:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>Interest Rates</li>
            <li>Monthly Payment Amounts</li>
            <li>Loan Duration</li>
            <img
              src={imageUrlHelpers.urls.loanOptions1}
              alt="loan-options-screen"
              className={styles["image"]}
              style={{ maxHeight: "400px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.loanOptions2}
              alt="loan-options-screen"
              className={styles["image"]}
              style={{ maxHeight: "400px", margin: "5px 15px 10px 0" }}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection10-2-3" && (
        <>
          <p className={styles["text"]}>
            The applicant reviews the loan options and selects their preferred
            choice by clicking "Apply."
          </p>
          <p className={styles["inner-title"]}>
            <strong>Third-Party Loans:</strong>
          </p>
          <p className={styles["text"]}>
            Redirects the applicant to the lender’s portal for completion.
          </p>
          <p className={styles["inner-title"]}>
            <strong>In-House Financing (IHF):</strong>
          </p>
          <p className={styles["text"]}>
            Redirects the applicant to the IHF application portal for further
            processing.
          </p>
        </>
      )}

      {sectionId === "subsection10-3" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsubsection10-3-1" && (
        <>
          <p className={styles["text"]}>
            On the lender's portal, the applicant completes the loan application
            process.
          </p>
          <p className={styles["text"]}>This may include:</p>
          <ul className={styles["list"]}>
            <li>Additional identity verification steps</li>
            <li>Uploading supporting documents (e.g., proof of income)</li>
            <li>Signing agreements digitally</li>
          </ul>
          <p className={styles["text"]}>
            Once completed, the lender finalizes the loan terms and notifies
            both the applicant and the healthcare facility of the decision.
          </p>
        </>
      )}

      {sectionId === "subsection10-4" && (
        <>
          <p className={styles["text"]}>Verification Process:</p>
          <p className={styles["text"]}>
            After the loan application is completed, team members can verify the
            transaction directly in the lender's portal (you can use the partner
            hub in the DentiRate platform to access the lender portal).
          </p>

          <img
            src={imageUrlHelpers.urls.partnerHubSection}
            alt="partner-hub-section"
            className={styles["image"]}
          />
        </>
      )}

      {sectionId === "section11" && <p className={styles["text"]}></p>}

      {sectionId === "subsection11-1" && (
        <p className={styles["text"]}>
          The platform offers patients DentiRate provides applicants with a
          clear view of, user-friendly interface to explore their loan options,
          with presenting critical details on such as interest rates, repayment
          terms, and monthly payment amounts.
        </p>
      )}

      {sectionId === "subsubsection11-1-1" && (
        <>
          <p className={styles["text"]}>
            Applicants begin by accessing the personalized link sent via SMS or
            email.
          </p>
          <p className={styles["inner-title"]}>
            <strong>Identity Verification:</strong>
          </p>
          <p className={styles["text"]}>
            The platform requires the applicant to input:
          </p>
          <ul className={styles["list"]}>
            <li>Year of Birth</li>
            <li>Postal Code</li>
            <img
              src={imageUrlHelpers.urls.verification1}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.verification2}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.verification3}
              alt="applicant-verification"
              className={styles["image"]}
              style={{ maxHeight: "300px", margin: "5px 15px 10px 0" }}
            />
          </ul>
          <p className={styles["inner-title"]}>
            <strong>Terms & Conditions:</strong>
          </p>
          <p className={styles["text"]}>
            Applicants must review and agree to the terms and privacy policy to
            proceed.
          </p>
          <img
            src={imageUrlHelpers.urls.applicationConsentMobile}
            alt="applicant-consent-screen"
            className={styles["image"]}
            style={{ maxHeight: "500px", margin: "5px 15px 10px 0" }}
          />
        </>
      )}

      {sectionId === "subsubsection11-1-2" && (
        <>
          <p className={styles["text"]}>
            Once identity verification is complete, DentiRate presents
            pre-qualified loan options tailored to the applicant’s financial
            profile and treatment needs. The loan options are displayed in a
            clear and organized format, making it easy for applicants to
            evaluate their choices.
          </p>
          <p className={styles["inner-title"]}>
            <strong>Key Details Displayed for Each Loan Option:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Financing Type: Specifies whether the financial product is a loan,
              line of credit, or other available financing options.
            </li>
            <li>
              Financing Amount: The total amount of money offered by the lender
              for the treatment.
            </li>
            <li>
              Number of Installments: Indicates the repayment period or term,
              shown as the number of monthly payments.
            </li>
            <li>
              Interest Rate: The percentage rate applied to the loan amount,
              which impacts the total cost of financing.
            </li>
            <li>
              Monthly Payment: The calculated monthly installment amount that
              the applicant will need to pay.
            </li>
          </ul>
          <p className={styles["inner-title"]}>
            <strong>How to Proceed:</strong>
          </p>
          <ul className={styles["list"]}>
            <li>
              Review Loan Details: Carefully evaluate the information provided
              for each pre-qualified loan option.
            </li>
            <li>
              Sorting Options: Use available filters (e.g., Recommended, Minimum
              Monthly Payment, Best Interest Rate, Highest Amount) to help
              identify the most suitable loan.
            </li>
            <li>
              Select the Preferred Loan: Click the "Apply" button under the
              desired loan option to proceed with the application.
            </li>
            <img
              src={imageUrlHelpers.urls.loanOptions1}
              alt="loan-options-screen"
              className={styles["image"]}
              style={{ maxHeight: "400px", margin: "5px 15px 10px 0" }}
            />
            <img
              src={imageUrlHelpers.urls.loanOptions2}
              alt="loan-options-screen"
              className={styles["image"]}
              style={{ maxHeight: "400px", margin: "5px 15px 10px 0" }}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection11-1-3" && (
        <>
          <p className={styles["text"]}>
            After the applicant's details are submitted and reviewed, DentiRate
            provides a range of pre-approved financial products tailored to the
            applicant's profile. To make the selection process easier and more
            efficient, the platform includes sorting and filtering options.
          </p>
          <p className={styles["text"]}>Steps to Sort Loan Options:</p>
          <ul className={styles["list"]}>
            <li>
              <strong>Review Pre-Approved Products:</strong> Upon completion of
              the application review, the screen displays the number of
              pre-approved financial products.
            </li>
            <li>
              <strong>Sorting Filters:</strong> Use the following sorting
              buttons to organize the loan options based on specific criteria:
            </li>
            <ol className={styles["ordered-list"]}>
              <img
                src={imageUrlHelpers.urls.sortLoanOptions}
                className={styles["image"]}
              />
              <li>
                <strong>Recommended:</strong> Displays loans that best fit the
                applicant's preferences and financial profile.
              </li>
              <li>
                <strong>Minimum Monthly Payment:</strong> Prioritizes loans with
                the lowest monthly payments, which may appeal to applicants
                focusing on affordability.
              </li>
              <li>
                <strong>Best Interest Rate:</strong> Highlights loans with the
                lowest interest rates, making them the most cost-effective over
                time.
              </li>
              <li>
                <strong>Highest Amount:</strong> Shows loans offering the
                maximum financing amount for the treatment.
              </li>
            </ol>
          </ul>
        </>
      )}

      {sectionId === "subsubsection11-1-4" && (
        <>
          <p className={styles["text"]}>
            Each loan option displays critical details, including:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Financing Type:</strong> Loan or other options.
            </li>
            <li>
              <strong>Financing Amount:</strong> Total amount offered.
            </li>
            <li>
              <strong>Number of Installments:</strong> The repayment period or
              term in months.
            </li>
            <li>
              <strong>Interest Rate:</strong> The percentage rate applied to the
              loan.
            </li>
            <li>
              <strong>Monthly Payment:</strong> The calculated monthly
              installment amount.
            </li>
            <img
              src={imageUrlHelpers.urls.loanDetails}
              className={styles["image"]}
            />
          </ul>
        </>
      )}

      {sectionId === "subsubsection11-1-5" && (
        <>
          <p className={styles["text"]}>
            The applicant reviews the loan options and selects their preferred
            choice by clicking "Apply."
          </p>
          <p className={styles["inner-title"]}>
            <strong>Third-Party Loans:</strong>
          </p>{" "}
          <p className={styles["text"]}>
            Redirects the applicant to the lender’s portal for completion.
          </p>
          <p className={styles["inner-title"]}>
            <strong>In-House Financing (IHF):</strong>{" "}
          </p>
          <p className={styles["text"]}>
            Redirects the applicant to the IHF application portal for further
            processing.
          </p>
        </>
      )}

      {sectionId === "subsection11-2" && <p className={styles["text"]}></p>}

      {sectionId === "subsubsection11-2-1" && (
        <>
          <p className={styles["text"]}>
            A flexible borrowing option specifically for dental expenses, where
            applicants are assigned a credit limit that they can draw from as
            needed, with the ability to make minimum monthly payments. Common
            examples include CareCredit and Alphaeon Credit, which are widely
            used for dental and healthcare costs.
          </p>
          <p className={styles["text"]}>
            This option is ideal for managing ongoing or unexpected dental
            expenses, such as emergency procedures or simple orthodontic
            adjustments that might not be fully covered by insurance.
          </p>
          <p className={styles["text"]}>
            The key feature is that the credit becomes reusable as the balance
            is repaid, making it an ongoing resource for future dental needs.
          </p>
          <p className={styles["text"]}>
            An example of a $5,000 orthodontic procedure, an applicant may have
            a credit limit of $7,500. They can borrow $5,000 for the procedure
            and make minimum monthly payments, such as $150, until the balance
            is paid off. As payments are made, the available credit limit
            increases again, providing flexibility for future dental needs.
          </p>
        </>
      )}

      {sectionId === "subsubsection11-2-2" && (
        <>
          <p className={styles["text"]}>
            A fixed loan amount repaid over a specific term in equal monthly
            installments, tailored for planned dental treatments. For instance,
            DentiRate offers installment loans though its lending partners for
            procedures, allowing applicants to budget with predictable monthly
            payments.
          </p>
          <p className={styles["text"]}>
            This option is designed for larger, planned dental expenses such as
            braces, implants, or other major treatments.
          </p>
          <p className={styles["text"]}>
            The key feature is that payments are predictable with fixed interest
            rates, providing applicants with clarity and stability regarding
            their financial commitments.
          </p>
          <p className={styles["text"]}>
            An example of a $5,000 orthodontic procedure, an applicant may take
            out an installment loan with a 12-month term and an interest rate of
            10%. The monthly payment would be approximately $439, including
            principal and interest, ensuring the applicant knows exactly what
            they need to pay each month.
          </p>
        </>
      )}

      {sectionId === "subsubsection11-2-3" && (
        <>
          <p className={styles["text"]}>
            A short-term financing option that allows applicants to divide the
            cost of dental treatments into smaller, often interest-free
            installments. Services like Splitit are popular for use in smaller
            dental procedures.
          </p>
          <p className={styles["text"]}>
            This option is suitable for lower-cost treatments requiring quick
            repayment, such as teeth whitening or minor restorative work. The
            key feature is a simple approval process with potential
            zero-interest offers, making it accessible for applicants with a
            limited credit history.
          </p>
          <p className={styles["text"]}>
            An example of a $5,000 orthodontic procedure, an applicant might
            choose a BNPL option that splits the cost into four interest-free
            payments of $1,250 each, paid over three months, allowing them to
            manage the cost in more manageable installments.
          </p>
        </>
      )}

      {sectionId === "subsubsection11-2-4" && (
        <>
          <p className={styles["text"]}>
            Financing provided directly by the dental practice, usually as an
            alternative if third-party financing is declined. This allows dental
            providers to work closely with applicants to create customized
            payment plans.
          </p>
          <p className={styles["text"]}>
            This option is tailored for applicants unable to secure external
            loans, making it ideal for those with limited credit history or
            those preferring a direct arrangement with their dental provider.
          </p>
          <p className={styles["text"]}>
            The key feature is flexible terms with fewer restrictions compared
            to third-party options, often with repayment schedules that are
            personalized based on the applicant's situation.
          </p>
          <p className={styles["text"]}>
            An example of a $5,000 orthodontic procedure, the dental provider
            might offer in-house financing with no interest for 12 months,
            resulting in monthly payments of approximately $417. If the
            applicant needs more flexibility, the provider could extend the term
            with adjusted payment amounts.
          </p>
        </>
      )}

      {sectionId === "subsubsection11-2-5" && (
        <>
          <p className={styles["text"]}>
            DentiRate allows applicants to take advantage of a combination of
            different financing options to make dental treatments more
            affordable. By mixing various options, DentiRate can tailor a
            customized solution that suits the financial situation of each
            applicant.
          </p>
          <p className={styles["text"]}>
            For instance, an applicant undergoing a $5,000 orthodontic procedure
            might use a combination of a BNPL option for an initial payment of
            $1,250, followed by an in-house financing plan for the remaining
            $3,750. This combination allows the applicant to benefit from
            interest-free installments initially, while also having the
            flexibility to negotiate terms directly with the dental provider for
            the remainder of the balance.
          </p>
          <p className={styles["text"]}>
            Such combined solutions help make dental care accessible to more
            people by offering multiple ways to spread out costs and minimize
            financial burden.
          </p>
        </>
      )}

      {sectionId === "subsection11-3" && (
        <>
          <p className={styles["text"]}>
            DentiRate leverages its proprietary AI algorithm to assist
            applicants in finding the best financial product that matches their
            specific needs. The algorithm evaluates several factors such as
            credit profile, treatment type, loan amount, and applicant
            preferences to recommend suitable financing solutions. This
            data-driven approach ensures that applicants receive tailored
            recommendations that align with their financial situation, making
            the decision process much easier.
          </p>
          <p className={styles["text"]}>
            Moreover, DentiRate gives applicants the flexibility to sort and
            compare available loan options. Applicants can sort the loans based
            on interest rate, minimum monthly payment, or loan amount, allowing
            them to prioritize what matters most to their financial strategy.
          </p>
          <p className={styles["text"]}>
            For instance, some applicants might prefer the lowest possible
            interest rate, while others may prioritize a lower monthly payment
            to fit within their budget.
          </p>
        </>
      )}

      {sectionId === "section12" && <p className={styles["text"]}></p>}

      {sectionId === "subsection12-1" && (
        <>
          <p className={styles["text"]}>
            If you encounter any issues or have questions, you can reach out to
            the support team using the following options:
          </p>
          <ul className={styles["list"]}>
            <li>
              <strong>Live Chat:</strong> Available directly on the platform.
            </li>
            <li>
              <strong>Call Support Team:</strong> Dial (833) 560-7400 for
              assistance.
            </li>
            <li>
              <strong>Submit a Ticket:</strong> Visit{" "}
              {/* https://dentirateststg.wpenginepowered.com/submit-ticket/ */}
              <a
                href="https://www.dentirate.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles["link"]}
              >
                https://www.dentirate.com/contact/
              </a>{" "}
              to submit your inquiry.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection12-2" && <p className={styles["text"]}></p>}

      {sectionId === "subsubsection12-2-1" && (
        <>
          <p className={styles["text"]}>
            What to do if a user does not have access to certain locations
            within the application:
          </p>
          <ul className={styles["list"]}>
            <li>
              Ensure the user has the correct role and permissions. Contact the
              admin if access needs to be updated.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection12-2-2" && (
        <>
          <p className={styles["text"]}>
            Steps to resolve issues if a user is unable to log in to their
            account:
          </p>
          <ul className={styles["list"]}>
            <li>
              Verify the username and password are correct. If still unable to
              log in, use the "Forgot Password" feature or contact support.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsubsection12-2-3" && (
        <>
          <p className={styles["text"]}>
            Recommended actions if the user encounters specific error messages
            during the application process.
          </p>
          <ul className={styles["list"]}>
            <li>Note the exact error message and try the following:</li>
            <ul className={styles["list"]}>
              <li>Clear browser cache and cookies.</li>
              <li>Ensure the internet connection is stable.</li>
            </ul>
            <li>
              Contact support if the issue persists, providing the error message
              details.
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection12-3" && (
        <>
          <p className={styles["text"]}>
            For assistance with applicant concerns, you can:
          </p>
          <ul className={styles["list"]}>
            <li>
              Call Support Team: Dial <strong>(833) 560-7400</strong>.
            </li>
            <li>
              Submit a Ticket: Visit{" "}
              {/* https://dentirateststg.wpenginepowered.com/submit-ticket/ */}
              <a
                href="https://www.dentirate.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles["link"]}
              >
                https://www.dentirate.com/contact/
              </a>
              .
            </li>
          </ul>
        </>
      )}

      {sectionId === "subsection12-4" && <p className={styles["text"]}></p>}

      {sectionId === "subsection12-5" && <p className={styles["text"]}></p>}

      {sectionId === "subsection12-6" && <p className={styles["text"]}></p>}

      {sectionId === "section13" && <p className={styles["text"]}></p>}

      {sectionId === "subsection13-1" && (
        <p className={styles["text"]}>
          Download user-specific guides from the Resources tab.
        </p>
      )}

      {sectionId === "subsection13-2" && (
        <p className={styles["text"]}>
          Patients can access security documents to understand how their data is
          handled.
        </p>
      )}

      {sectionId === "subsection13-3" && <p className={styles["text"]}></p>}

      {sectionId === "section14" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}

      {sectionId === "subsection14-1" && (
        <>
          <p className={styles["text"]}>
            After completing your registration, you will receive your username
            and temporary password via email to access the Merchant Portal. The
            Merchant Portal allows you to manage plans, transactions, and
            reports efficiently.
          </p>
          <p className={styles["text"]}>
            <a
              href="https://www.splitit.com/merchant-portal-demo"
              target="_blank"
              rel="noopener noreferrer"
              className={styles["link"]}
            >
              Click here
            </a>{" "}
            to view the Merchant Portal Demo.
          </p>
          <p className={styles["text"]}>
            For additional support,{" "}
            <a
              href="https://www.splitit.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles["link"]}
            >
              click here
            </a>
            .
          </p>
        </>
      )}

      {/* {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}
      {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}
      {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}
      {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )}
      {sectionId === "subsection1-2" && (
        <>
          <p className={styles["text"]}></p>
        </>
      )} */}
    </>
  );
};

TutorialContent.propTypes = {};

export default TutorialContent;
