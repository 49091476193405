import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalContainer from "../modal-control";
import styles from "./styles.module.scss";
import RadioButton from "../radio-button";
import { Col, FormControl, Row } from "react-bootstrap";
import { NumericFormat, PatternFormat } from "react-number-format";
import ValidationWrapper from "../validation-wrapper";
import _ from "lodash";
import CheckBox from "../check-box/check-box";
import SelectControl from "../select-control";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import useDuplicateApp from "../../hooks/use-duplicate-application";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DuplicateApplicationModal = ({
  show = false,
  handleClose = () => {},
  id = "",
  refreshapp,
}) => {
  const [applicationExtendInfo, setApplicationExtendInfo] = useState({
    id: id,
    applicationRequestType: "",
    annualIncome: 0,
    treatmentType: "",
    ssn: "",
    noSsn: true,
    preferredMonthlyPayment: 0,
    insuranceCoverage: 0,
    initialAvailableCash: 0,
    amount: 0,
  });
  const [applicationValidations, setApplicationValidations] = useState({
    amount: true,
    applicationRequestType: true,
    treatmentType: true,
    initialAvailableCash: true,
    ssn: true,
    insuranceCoverage: true,
  });
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [step, setStep] = useState(0);

  const innerChange = (name, newValue) => {
    setApplicationExtendInfo({
      ...applicationExtendInfo,
      [name]: newValue,
    });
  };
  const navigate = useNavigate();

  const duplicateApp = useDuplicateApp();
  const validateAll = () => {
    setApplicationValidations({
      ...applicationValidations,
      amount: !!applicationExtendInfo.amount,
      applicationRequestType: !!applicationExtendInfo.applicationRequestType,
    });

    return (
      !!applicationExtendInfo.amount &&
      !!applicationExtendInfo.applicationRequestType
    );
  };

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };

  const onSubmit = () => {
    const validation = validateAll();
    if (!validation) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    const temp = _.cloneDeep(applicationExtendInfo);
    // console.log(temp);
    let response = {};
    let actionStatus = "";
    duplicateApp(temp)
      .then((res) => {
        response = res.data?.data;
        actionStatus = _.get(res, "headers.action-status", null);
        // console.log(actionStatus);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          case "Unauthorized":
            navigate("/login");
            break;
          case "ValidationError":
            showErrorMessage("Validation Error");
            break;
          case "Failed":
            showErrorMessage("Server not responding");
            break;
          case "NotExists":
            showErrorMessage("Account Not Exists");
            break;
          case "DuplicateEmail":
            showErrorMessage("Email has been used already!");
            break;
          case "InvitationThrottled":
            showErrorMessage("This invitation Already has been sent!");
            break;
          case "InvalidInviter ":
            showErrorMessage("InvalidInviter ");
            break;
          case "LockedOut":
            showErrorMessage("This account is locked out.");
            break;
          case "AdminLockedOut":
            showErrorMessage("Your account was just locked out.");
            break;
          case "Success":
            setStep(1);
            break;
          default:
            showErrorMessage("An error occured.");
            break;
        }
      });
  };
  useEffect(() => {
    setApplicationExtendInfo({
      ...applicationExtendInfo,
      applicationRequestType: "",
      annualIncome: 0,
      treatmentType: "",
      ssn: "",
      noSsn: true,
      preferredMonthlyPayment: 0,
      insuranceCoverage: 0,
      initialAvailableCash: 0,
      amount: 0,
    });
    setStep(0);
  }, [show]);
  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose(false);
        // refreshapp();
      }}
      modalSize={"lg"}
      modalTitle={" Duplicate Application"}
      centered
    >
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />
      {step === 0 && (
        <div className={styles["modal-content"]}>
          <div className={styles["required-note"]}>
            <span className={styles["red-star"]}>*</span> required fields
          </div>
          <Row>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                // isValid={applicationValidations.treatmentType}
                title="Treatment Type"
                titleStar={false}
                errorMessage="Invalid treatment type"
              >
                <SelectControl
                  className={`${styles["input"]}`}
                  options={treatmentType.collection}
                  value={
                    treatmentType.collection.find(
                      (i) =>
                        i.value ===
                        treatmentType.getValueByText(
                          applicationExtendInfo.treatmentType
                        )
                    ) || ""
                  }
                  onChange={(v) => {
                    // console.log(v);
                    innerChange("treatmentType", v.value || "");
                  }}
                  placeholder=""
                  isClearable={false}
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.amount}
                title="$ Treatment Cost"
                titleStar={true}
                errorMessage="Invalid contract value"
                inputId="amount"
              >
                <NumericFormat
                  id="amount"
                  name="number"
                  className={`form-control ${
                    !applicationValidations.amount && "danger"
                  }`}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={applicationExtendInfo.amount || ""}
                  onValueChange={(values) => {
                    innerChange("amount", values.floatValue);
                  }}
                  maxLength={8}
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                // isValid={applicationValidations.initialAvailableCash}
                title={"Upfront payment"}
                titleStar={false}
                inputId="down-payment"
                errorMessage="Invalid Upfront payment"
              >
                <NumericFormat
                  id="down-payment"
                  name="number"
                  className={`form-control `}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={applicationExtendInfo.initialAvailableCash || ""}
                  onValueChange={(values) => {
                    innerChange("initialAvailableCash", values.floatValue);
                  }}
                  maxLength={50}
                />
              </ValidationWrapper>
            </Col>
            {/* <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              // isValid={applicationValidations.annualIncome}
              title={"Annual Income"}
              inputId="input-annual-income"
              errorMessage="Invalid annual income"
            >
              <NumericFormat
                id="input-annual-income"
                name="number"
                className="form-control"
                thousandSeparator={true}
                prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={applicationExtendInfo.annualIncome || ""}
                onValueChange={(values) => {
                  innerChange("annualIncome", values.floatValue);
                }}
                maxLength={12}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={4}>
            <ValidationWrapper
              isValid={applicationValidations.ssn}
              title={"SSN"}
              titleStar={false}
              inputId="input-ssn"
              errorMessage={"Invalid SSN"}
            >
              <PatternFormat
                id="input-ssn"
                className={`form-control ${
                  !applicationValidations.ssn && "danger"
                }`}
                autoComplete="off"
                value={applicationExtendInfo.ssn}
                onValueChange={(values) => {
                  console.log(values.floatValue);
                  innerChange("ssn", values.floatValue);
                }}
                format="###-##-####"
                mask="_"
                disabled={applicationExtendInfo.noSsn}
              />
              <div className={styles["checkbox-container"]}>
                <CheckBox
                  text="SSN is not available"
                  onClick={(e) =>
                    innerChange(
                      "noSsn",
                      !(applicationExtendInfo.noSsn || false)
                    )
                  }
                  Checked={applicationExtendInfo.noSsn || false}
                />
              </div>
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={4}>
            <ValidationWrapper
              // isValid={applicationValidations.preferredMonthlyPayment}
              title={"Preferred Monthly Payment"}
              inputId="input-preferred-payment"
              errorMessage="Invalid monthly payment"
            >
              <NumericFormat
                id="input-preferred-payment"
                name="number"
                className="form-control"
                thousandSeparator={true}
                prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={applicationExtendInfo.preferredMonthlyPayment || ""}
                onValueChange={(values) => {
                  innerChange("preferredMonthlyPayment", values.floatValue);
                }}
                maxLength={50}
              />
            </ValidationWrapper>
          </Col> */}
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                // isValid={applicationValidations.insuranceCoverage}
                title={"Insurance Coverage"}
                titleStar={false}
                inputId="Insurance-Coverage"
                errorMessage="Invalid Insurance Coverage"
              >
                <NumericFormat
                  id="Insurance-Coverage"
                  name="number"
                  className={`form-control`}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={applicationExtendInfo.insuranceCoverage || null}
                  onValueChange={(values) => {
                    innerChange("insuranceCoverage", values.floatValue);
                  }}
                  maxLength={50}
                />
              </ValidationWrapper>
            </Col>
          </Row>
          <ValidationWrapper
            isValid={applicationValidations.applicationRequestType}
            title={"Application Request Type"}
            titleStar={true}
            inputId="application-request-type"
            errorMessage="One of the above options must be selected"
          >
            <Row id="application-request-type">
              <Col xs={12} md={12} lg={6}>
                <RadioButton
                  text="Apply for both third-party and in-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange(
                      "applicationRequestType",
                      "BothThirdPartyAndIHF"
                    )
                  }
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <RadioButton
                  text="Apply for third-party first; if declined, try in-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange(
                      "applicationRequestType",
                      "FirstThirdPartyThenIHF"
                    )
                  }
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <RadioButton
                  text="Only apply for in-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange("applicationRequestType", "OnlyIHF")
                  }
                />
              </Col>
              <Col xs={12} md={12} lg={6}>
                <RadioButton
                  text="Only apply for third-party financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange("applicationRequestType", "OnlyThirdParty")
                  }
                />
              </Col>
            </Row>
          </ValidationWrapper>
          <button className={styles["add-btn"]} onClick={onSubmit}>
            Submit
          </button>
        </div>
      )}
      {step === 1 && (
        <div className={styles["modal-content"]}>
          <h4>Your Application Has Been Successfuly Duplicated.</h4>
          <button
            className={styles["add-btn"]}
            onClick={() => {
              handleClose(false);
              refreshapp();
            }}
          >
            OK
          </button>
        </div>
      )}
    </ModalContainer>
  );
};

DuplicateApplicationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  id: PropTypes.string,
  refreshapp: PropTypes.func,
};

export default DuplicateApplicationModal;
