import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import logo from "../../images/logo.png";
import styles from "./styles.module.scss";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { apiLoadingStatus, apiUrls } from "../../infrastructure/constants/api";
import FormCustomControl from "../../components/form-custom-control";
import { useQuery } from "../../hooks";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  AlertCircleIcon,
  CheckmarkCircle02Icon,
  Mail01Icon,
  MultiplicationSignCircleIcon,
  UserAdd01Icon,
  UserCheck01Icon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import useCreateNewUser from "../../hooks/create-new-user";
import ValidationWrapper from "../validation-wrapper";
import { apiHelperMethods } from "../../infrastructure/helpers";

const CreatUserControl = () => {
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    newPassword: "",
    confirmPassword: "",
    email: "",
    invitationCode: "",
  });
  const [validUserName, setValidUserName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [minCharactersPass, setMinCharactersPass] = useState(null);
  const [upperCasePass, setUpperCasePass] = useState(null);
  const [minNumPass, setMinNumPass] = useState(null);
  const [symbolFormatPass, setSymbolFormatPass] = useState(null);
  const [isShown, setIsShown] = useState({
    password: false,
    confirm: false,
  });
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const location = useLocation();
  let actionStatus = undefined;
  const queryHelper = useQuery();
  const navigate = useNavigate();
  const createNewuser = useCreateNewUser();
  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      // toastId: "login error",
    });
  };
  // avoid double parsing
  // const queryEmail = useMemo(
  //   () => queryHelper.getValue("email"),
  //   [location.search]
  // );
  // // avoid double parsing
  // const [invitationCode] = useMemo(
  //   () => queryHelper.getValue("invitationCode"),
  //   [location.search]
  // );

  // avoid double parsing
  // old trick to render both
  const [queryEmail, invitationCode] = useMemo(
    () => [
      queryHelper.getValue("email"),
      queryHelper.getValue("invitationCode"),
    ],
    [location.search]
  );

  function validatePassword(event) {
    const symbolFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (event.target.value.length < 8) {
      setMinCharactersPass(false);
    } else {
      setMinCharactersPass(true);
    }
    if (!/\d/.test(event.target.value)) {
      // console.log("setminNumPass", !/\d/.test("passw4ord.newPassword"));
      setMinNumPass(false);
    } else {
      setMinNumPass(true);
    }
    if (event.target.value !== event.target.value.toLowerCase()) {
      setUpperCasePass(true);
    } else {
      setUpperCasePass(false);
    }
    if (!symbolFormat.test(event.target.value)) {
      setSymbolFormatPass(false);
    } else {
      setSymbolFormatPass(true);
    }
  }

  const validateName = () => {
    !userInfo.firstName ? setValidUserName(false) : setValidUserName(true);
  };

  const validateLastName = () => {
    !userInfo.lastName ? setValidLastName(false) : setValidLastName(true);
  };

  const validatePasswords = () => {
    const symbolFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (userInfo.newPassword === undefined || userInfo.newPassword === "") {
      setMinCharactersPass(false);
    } else {
      if (userInfo.newPassword.length < 8) {
        setMinCharactersPass(false);
      } else {
        setMinCharactersPass(true);
      }
    }
    if (!/\d/.test(userInfo.newPassword)) {
      // console.log("setminNumPass", !/\d/.test("passw4ord.newPassword"));
      setMinNumPass(false);
    } else {
      setMinNumPass(true);
    }
    if (userInfo.newPassword !== userInfo.newPassword?.toLowerCase()) {
      setUpperCasePass(true);
    } else {
      setUpperCasePass(false);
    }
    if (!symbolFormat.test(userInfo.newPassword)) {
      setSymbolFormatPass(false);
    } else {
      setSymbolFormatPass(true);
    }
  };

  function onContinue() {
    validateName();
    validateLastName();
    validatePasswords();
    if (
      userInfo.newPassword === "" ||
      !minCharactersPass ||
      !minNumPass ||
      !upperCasePass ||
      !symbolFormatPass ||
      userInfo.newPassword !== userInfo.confirmPassword ||
      !userInfo.firstName ||
      !userInfo.lastName
    ) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);

    createNewuser({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      password: userInfo.confirmPassword,
      invitationCode: invitationCode,
      email: queryEmail,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          case "Unauthorized":
            navigate("/login");
            break;
          case "Failed":
          case "ValidationError":
            showErrorMessage("New user is invalid.");
            break;
          case "LinkHasExpired":
            showErrorMessage("This invitation link has been expired.");
            break;
          case "LinkNotExits":
            showErrorMessage("This invitation link does not exist.");
            break;
          case "DuplicatePhone":
            showErrorMessage("Duplicate phone");
            break;
          case "InvitationUsed":
            showErrorMessage(
              "This invitation link has been already registered."
            );
            break;
          case "NoPermission":
            showErrorMessage("You do not pave permissions.");
            break;
          case "DuplicateEmail":
            showErrorMessage("This email has been already registered.");
            break;
          case "Success":
            setMessage("Your account has been successfully created!");
            setStep(3);
            break;
          case "SuccessVerificationFailed":
            showErrorMessage("Your account has not been successfully created!");
            break;
          default:
            showErrorMessage("Unknown Error");
            break;
        }
      });
  }

  useEffect(() => {
    setLoadingStatus(apiLoadingStatus.loading);
    const url = apiUrls.userIdentityUrls.InvitiationCode;
    let actionStatus = null;
    let isValid = false;
    apiHelperMethods
      .getWithReturn(url, { encryptedCode: invitationCode, email: queryEmail })
      .then((res) => {
        isValid = res.data.data.isValid;
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch(
        (err) =>
          (actionStatus = _.get(err, "response.headers.action-status", null))
      )
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          case "Failed":
          case "ValidationError":
            setMessage("This link is invalid.");
            setStep(3);
            break;
          case "Expired":
            setMessage("This invitation link has been expired.");
            setStep(3);
            break;
          case "NotExist":
            setMessage("This invitation link does not exist.");
            setStep(3);
            break;
          case "AdminLockedOut":
            setMessage("The user has been locked out by site admin.");
            setStep(3);
            break;
          case "Used":
            setMessage("This email has been already registered.");
            setStep(3);
            break;
          case "Success":
            isValid && setStep(2);
            break;
          default:
            setMessage("Unknown Error");
            setStep(3);
            break;
        }
      });
  }, []);
  return (
    <div className={styles["main"]}>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />

      {step === 2 && (
        <Container className={styles["container"]}>
          <FormCustomControl
            onContinue={onContinue}
            displayBackContinue={false}
          >
            {/* <Row className={styles["logo-container"]}>
              <Col>
                <img src={logo} alt='logo' />
              </Col>
            </Row> */}
            <div className={styles["welcome-container"]}>
              <UserAdd01Icon size={30} />
              <span>Create New User</span>
            </div>
            <div className={styles["mail-container"]}>
              <div className={styles["for-what"]}>For Email Adress:</div>

              <div className={styles["email"]}>
                <Mail01Icon size={22} /> {queryEmail}
              </div>
              {/* <Form.Label className={styles["input-lable"]} htmlFor="cell">
                Cell Phone No.<span className={styles["important"]}>*</span>
              </Form.Label>
              <Input
                className={styles["input-text-box"]}
                placeholder="Enter phone number"
                value={userInfo.cellPhone}
                onChange={(value) =>
                  setUserInfo({
                    ...userInfo,
                    cellPhone: value,
                  })
                }
                country="US"
              /> */}
            </div>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={12}>
                <Form.Label
                  className={styles["input-lable"]}
                  htmlFor="firstName"
                >
                  First Name<span className={styles["important"]}>*</span>
                </Form.Label>
                <ValidationWrapper
                  isValid={validUserName}
                  errorMessage="Invalid First Name"
                  wrapperStyle={{ padding: "0" }}
                >
                  <Form.Control
                    type="text"
                    id="firstName"
                    value={userInfo.firstName}
                    onChange={(e) => {
                      !e.target.value
                        ? setValidUserName(false)
                        : setValidUserName(true);
                      setUserInfo({
                        ...userInfo,
                        firstName: e.target.value,
                      });
                    }}
                    name="firstName"
                    placeholder="Please enter your first name"
                    disabled={loadingStatus === apiLoadingStatus.loading}
                    className={`${
                      validUserName
                        ? styles["input-text-box"]
                        : styles["input-text-box-warning"]
                    }`}
                  />
                </ValidationWrapper>
              </Col>
              <Col md={12}>
                <Form.Label
                  className={styles["input-lable"]}
                  htmlFor="lastName"
                >
                  Last Name<span className={styles["important"]}>*</span>
                </Form.Label>
                <ValidationWrapper
                  isValid={validLastName}
                  errorMessage="Invalid Last Name"
                  wrapperStyle={{ padding: "0" }}
                >
                  <Form.Control
                    type="text"
                    id="lastName"
                    value={userInfo.lastName}
                    onChange={(e) => {
                      !e.target.value
                        ? setValidLastName(false)
                        : setValidLastName(true);
                      setUserInfo({
                        ...userInfo,
                        lastName: e.target.value,
                      });
                    }}
                    name="lastName"
                    placeholder="Please enter your last name"
                    disabled={loadingStatus === apiLoadingStatus.loading}
                    className={`${
                      validLastName
                        ? styles["input-text-box"]
                        : styles["input-text-box-warning"]
                    }`}
                  />
                </ValidationWrapper>
              </Col>
            </Row>

            <Row className={styles["last-row"]}>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className={styles["input-lable"]}>
                    New Password
                  </Form.Label>
                  <div className={styles["input-container"]}>
                    <Form.Control
                      className={
                        (minCharactersPass == null &&
                          minNumPass == null &&
                          upperCasePass == null &&
                          symbolFormatPass == null) ||
                        (minCharactersPass &&
                          minNumPass &&
                          upperCasePass &&
                          symbolFormatPass)
                          ? styles["input-text-box"]
                          : styles["input-text-box-warning"]
                      }
                      type={isShown.password ? "text" : "password"}
                      placeholder="Please enter your password"
                      defaultValue={userInfo.newPassword || ""}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          newPassword: e.target.value,
                        });
                        validatePassword(e);
                      }}
                      disabled={loadingStatus === apiLoadingStatus.loading}
                    />
                    <div
                      className={styles["view-icon"]}
                      onMouseDown={() => {
                        setIsShown({
                          ...isShown,
                          password: true,
                        });
                      }}
                      onMouseUp={() => {
                        setIsShown({
                          ...isShown,
                          password: false,
                        });
                      }}
                      onTouchStart={() => {
                        setIsShown({
                          ...isShown,
                          password: true,
                        });
                      }}
                      onTouchEnd={() => {
                        setIsShown({
                          ...isShown,
                          password: false,
                        });
                      }}
                    >
                      {!isShown.password ? <ViewIcon /> : <ViewOffIcon />}
                    </div>
                  </div>
                  <Form.Text
                    className={`${styles["hint"]} ${
                      minCharactersPass === true && styles["checked"]
                    } ${
                      minCharactersPass === false && styles["warning-error"]
                    }`}
                  >
                    {minCharactersPass === null && <AlertCircleIcon />}
                    {minCharactersPass === true && <CheckmarkCircle02Icon />}
                    {minCharactersPass === false && (
                      <MultiplicationSignCircleIcon />
                    )}
                    At least 8 Characters
                  </Form.Text>

                  <Form.Text
                    className={`${styles["hint"]} ${
                      upperCasePass === true && styles["checked"]
                    } ${upperCasePass === false && styles["warning-error"]}`}
                  >
                    {upperCasePass === null && <AlertCircleIcon />}
                    {upperCasePass === true && <CheckmarkCircle02Icon />}
                    {upperCasePass === false && (
                      <MultiplicationSignCircleIcon />
                    )}{" "}
                    At least one Uppercase
                  </Form.Text>

                  <Form.Text
                    className={`${styles["hint"]} ${
                      symbolFormatPass === true && styles["checked"]
                    } ${symbolFormatPass === false && styles["warning-error"]}`}
                  >
                    {symbolFormatPass === null && <AlertCircleIcon />}
                    {symbolFormatPass === true && <CheckmarkCircle02Icon />}
                    {symbolFormatPass === false && (
                      <MultiplicationSignCircleIcon />
                    )}{" "}
                    At least one Symbol
                  </Form.Text>

                  <Form.Text
                    className={`${styles["hint"]} ${
                      minNumPass === true && styles["checked"]
                    } ${minNumPass === false && styles["warning-error"]}`}
                  >
                    {minNumPass === null && <AlertCircleIcon />}
                    {minNumPass === true && <CheckmarkCircle02Icon />}
                    {minNumPass === false && (
                      <MultiplicationSignCircleIcon />
                    )}{" "}
                    At least one Number
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className={styles["input-lable"]}>
                    Re-enter new password
                  </Form.Label>
                  <div className={styles["input-container"]}>
                    <Form.Control
                      className={
                        userInfo.confirmPassword === "" ||
                        userInfo.confirmPassword === userInfo.newPassword
                          ? styles["input-text-box"]
                          : styles["input-text-box-warning"]
                      }
                      type={isShown.confirm ? "text" : "password"}
                      placeholder="Please re-enter your password"
                      defaultValue={userInfo.confirmPassword || ""}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          confirmPassword: e.target.value,
                        });
                      }}
                      disabled={loadingStatus === apiLoadingStatus.loading}
                    />
                    <div
                      className={styles["view-icon"]}
                      onMouseDown={() => {
                        setIsShown({
                          ...isShown,
                          confirm: true,
                        });
                      }}
                      onMouseUp={() => {
                        setIsShown({
                          ...isShown,
                          confirm: false,
                        });
                      }}
                      onTouchStart={() => {
                        setIsShown({
                          ...isShown,
                          password: true,
                        });
                      }}
                      onTouchEnd={() => {
                        setIsShown({
                          ...isShown,
                          password: false,
                        });
                      }}
                    >
                      {!isShown.confirm ? <ViewIcon /> : <ViewOffIcon />}
                    </div>
                  </div>
                  {userInfo.newPassword !== userInfo.confirmPassword &&
                    userInfo.confirmPassword !== "" && (
                      <Form.Text
                        className={`${styles["hint"]} ${styles["warning-error"]}`}
                      >
                        <MultiplicationSignCircleIcon />
                        Passwords don't matched!
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>
            </Row>
          </FormCustomControl>
        </Container>
      )}

      {step === 3 && (
        <Container className={styles["container"]}>
          <Row className={styles["logo-container"]}>
            <Col>
              <img src={logo} alt="logo" />
            </Col>
          </Row>
          <Row className={styles["welcome-container"]}>
            <Col>
              <h4>Create New User</h4>
            </Col>
          </Row>
          <FormCustomControl
            onContinue={() => {
              navigate("/home");
            }}
            continueLabel="OK"
            displayBackContinue={false}
          >
            <p>
              <UserCheck01Icon color="#007775" width="30px" height="30px" />
            </p>
            <div className={styles["email-container"]}>
              <p>{message}</p>
            </div>
          </FormCustomControl>
        </Container>
      )}
    </div>
  );
};
export default CreatUserControl;
