import { over, times } from "lodash";

const getBaseUrl = () => process.env.REACT_APP_IMAGE_BASE_URL || "";

const getFullUrl = (relativePath = "") => {
  const baseUrl = getBaseUrl().replace(/\/+$/, "");
  const path = relativePath.replace(/^\/+/, "");
  return `${baseUrl}/${path}`;
};

const urls = {
  changePassword: getFullUrl("university/section2/change_password.png"),
  createNewUser: getFullUrl("university/section2/create-new-user.png"),
  email: getFullUrl("university/section2/email-click-to-start.png"),
  forgotPasswordFirst: getFullUrl("university/section2/forgot_password_1.png"),
  forgotPasswordSecond: getFullUrl("university/section2/forgot_password_2.png"),
  forgotPasswordThird: getFullUrl("university/section2/forgot_password_3.png"),
  forgotPasswordFourth: getFullUrl("university/section2/forgot_password_4.png"),
  login: getFullUrl("university/section2/login.png"),
  logout: getFullUrl("university/section2/logout.png"),
  profile: getFullUrl("university/section2/profile.png"),
  menu: getFullUrl("university/section2/menu.jpg"),
  showMenu: getFullUrl("university/section2/show-menu.png"),
  ///section3
  homeOverview: getFullUrl("university/section3/home_master.png"),
  homeGraph: getFullUrl("university/section3/graph.png"),
  overview: getFullUrl("university/section3/overview.png"),
  performanceTable: getFullUrl(
    "university/section3/performance_matrix_table.png"
  ),
  time: getFullUrl("university/section3/time.png"),
  userLocation: getFullUrl("university/section3/user_location.png"),
  ///section4
  applicationForm: getFullUrl("university/section4/application-form.png"),
  applicationStatus: getFullUrl("university/section4/application-status.png"),
  applyFilters: getFullUrl("university/section4/apply-filters.png"),
  consent: getFullUrl("university/section4/consent.png"),
  duplicateApplication: getFullUrl(
    "university/section4/duplicate-application.png"
  ),
  editApplication: getFullUrl("university/section4/edit-application.png"),
  extend: getFullUrl("university/section4/extend.png"),
  extendForm: getFullUrl("university/section4/extend-form.png"),
  ///section5
  locationEditBtn: getFullUrl("university/section5/location-edit-btn.png"),
  locationStatusUpdate: getFullUrl(
    "university/section5/location-status-update.png"
  ),
  navigateToAddLocation: getFullUrl(
    "university/section5/navigate-to-add-location.png"
  ),
  newLocationForm: getFullUrl("university/section5/new-location-form.png"),
  locationShowMore: getFullUrl("university/section5/location-show-more.png"),
  ///section6
  billingOverview: getFullUrl("university/section6/billing-overview.png"),
  ///section7
  addNewUser: getFullUrl("university/section7/add-new-user.png"),
  navigateToAddUser: getFullUrl("university/section7/navigate-to-add-user.png"),
  userManagementInterface: getFullUrl(
    "university/section7/user-management-interface.png"
  ),
  ///section8
  downloadCenter: getFullUrl("university/section8/download-center.png"),
  dashboardOverview: getFullUrl("university/section8/dashboard-overview.png"),
  summaryReport: getFullUrl("university/section8/summary-report.png"),
  ///section9
  partnerHub: getFullUrl("university/section9/partner-hub.png"),
  ///section10
  addNewApplicationForm: getFullUrl(
    "university/section10/add-new-application-form.png"
  ),
  partnerHubSection: getFullUrl("university/section10/partner-hub.png"),

  applicationExtentionForm: getFullUrl(
    "university/section10/application-extention-form.png"
  ),
  applicationLinkEmail: getFullUrl(
    "university/section10/application-link-email.png"
  ),
  applicationLinkSms: getFullUrl(
    "university/section10/application-link-sms.png"
  ),
  applicationConsent: getFullUrl(
    "university/section10/application-consent.png"
  ),
  ///common between10-11
  applicationConsentMobile: getFullUrl(
    "university/section10/application-consent-mobile.png"
  ),
  loanOptions1: getFullUrl("university/section10/loan-options1.png"),
  loanOptions2: getFullUrl("university/section10/loan-options2.png"),
  verification1: getFullUrl("university/section10/verification1.png"),
  verification2: getFullUrl("university/section10/verification2.png"),
  verification3: getFullUrl("university/section10/verification3.png"),
  ///section11
  loanDetails: getFullUrl("university/section11/loan-details.png"),
  sortLoanOptions: getFullUrl("university/section11/sort-loan-options.png"),
  ///icons
  toggleActive: getFullUrl("university/icons/active.png"),
  toggleInactive: getFullUrl("university/icons/deactive.png"),
  locationEditIcon: getFullUrl("university/icons/location-edit.png"),
  billStatusIssued: getFullUrl("university/icons/bill-status-issued.png"),
  billStatusVoid: getFullUrl("university/icons/bill-status-void.png"),
  billStatusPaid: getFullUrl("university/icons/bill-status-paid.png"),
  billLink: getFullUrl("university/icons/bill-link.png"),
  applicationEdit: getFullUrl("university/icons/application-edit.png"),
  applicationDuplicate: getFullUrl(
    "university/icons/application-duplicate.png"
  ),
  applicationExtend: getFullUrl("university/icons/application-extend.png"),
};

const imageUrlHelpers = {
  getBaseUrl,
  getFullUrl,
  urls,
};

export default imageUrlHelpers;
