const userIdentityUrls = {
  createUser: `/api/account/createuser`,
  user: `/api/account/user`,
  changePassword: `/api/account/recoverychange`,
  login: `/api/account/login`,
  recoveryInit: `/api/account/recoveryinit`,
  recoveryOpen: `/api/account/recoveryopen`,
  logo: `/api/Organization/SimpleLogo`,
  mfa: `/api/account/validateLoginMfa`,
  logout: `/api/Account/Logout`,
  applicationPaginated: `api/application/getpaginated`,
  billingsPaginated: `/api/billing/Invoice/GetInvoicesPaginated`,
  newApplication: `api/application/addCosumerApplication`,
  addNewUser: `api/account/sendInvitation`,
  editUserBio: "/api/Account/UpdateUserBio",
  paginatedUserList: `/api/Account/PaginatedUserList`,
  updateUserOrganizationActivation: `/api/Account/UpdateUserOrganizationActivation`,
  paginatedInvitationsList: `/api/Account/PaginatedInvitationList`,
  AddConsumerApplication: "/api/Application/AddConsumerApplication",
  UpdateApplication: "/api/Application/UpdateApplication",
  GetApplicationById: "/api/Application/GetSimplifiedVersionById",
  udatePassword: `/api/Account/UpdatePassword`,
  duplicateApplication: "/api/Application/DuplicateConsumerApplication",
  extendApplication: "/api/Application/ExtendConsumerApplication",
  ihfAgreement: "/api/Applicant/SubmitInHouseFinancingAgreement",
  InvitiationCode: "/api/Account/InvitiationCode",
  // Add as many as you wish
};

export default userIdentityUrls;
