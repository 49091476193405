import {
  Calendar04Icon,
  Copy01Icon,
  Edit02Icon,
  MedicineBottle01Icon,
  Payment02Icon,
  Wallet03Icon,
  Call02Icon,
  Mail02Icon,
  IdIcon,
  IdVerifiedIcon,
  Hospital02Icon,
  ArrowTurnForwardIcon,
  Location01Icon,
  Note01Icon,
  ArrowDown01Icon,
  ArrowRight01Icon,
  FolderDetailsIcon,
  MoreHorizontalCircle01Icon,
} from "hugeicons-react";
import styles from "./styles.module.scss";
import { Col, Row } from "react-bootstrap";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import applicationStatus from "../../infrastructure/constants/enums/simplified-application-status";
import useHgLocations from "../../hooks/use-hg-locations1";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../utils/storage";
import { sendPostByJson } from "../../utils/httpUtils";
import { toastError } from "../../utils/check";
import PropTypes from "prop-types";
import TooltipControl from "../tooltip-control/tooltip-control";
import ExtendApplicationModal from "./extend-application-modal";
import ApplicationChildren from "./application-children";
import DuplicateApplicationModal from "./duplicate-application-modal";
import moment from "moment";
import ApplicationDetailsModal from "./application-details-modal";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";

const ApplicationCard = ({
  setStep = () => {},
  amount = 0,
  firstName = "",
  lastName = "",
  createDate = null,
  preferredMonthlyPayment = "",
  emailAddress = "",
  status = "",
  personId = "",
  chartNumber = "-",
  location = 0,
  phoneNumber = "",
  isExtendable = true,
  childrenCollection = [],
  refreshapp = () => {},
  application = {},
}) => {
  const navigate = useNavigate();
  const { getLocationByIds } = useHgLocations();
  const [locationName, setLocationName] = useState("");
  const source = parseInt(getItem("source"));
  const toastId = useRef(null);
  const appDetails = useRef(null);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [showDuplicatedModal, setShowDuplicatedModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const dateDifference = moment(new Date()).subtract(6, "months");
  // console.log(moment(createDate).isAfter(dateDifference));
  const getLoc = async (location) => {
    const loc = await getLocationByIds([location]);
    return loc;
  };
  useEffect(() => {
    getLoc(location).then((res) => {
      setLocationName(res[0]?.nickname);
    });
    return () => setLocationName("");
  }, [location]);

  return (
    <div className={styles["container"]}>
      <ExtendApplicationModal
        show={showExtendModal}
        handleClose={setShowExtendModal}
        id={personId}
        amount={amount}
        refreshapp={refreshapp}
      />
      <DuplicateApplicationModal
        show={showDuplicatedModal}
        handleClose={() => {
          setShowDuplicatedModal();
        }}
        id={personId}
        refreshapp={refreshapp}
      />
      <ApplicationDetailsModal
        show={showDetailsModal}
        handleClose={() => {
          setShowDetailsModal();
        }}
        application={application}
      />
      <Row className={styles["first-row"]}>
        <Col md={12} lg={6} className={styles["name-date-box"]}>
          <div className={styles["name-box"]}>
            {firstName} {lastName}
          </div>
          <div className={styles["date-box"]}>
            <Calendar04Icon className={styles["date-icon"]} />
            <span>
              {`${createDate?.getDate()}/${
                createDate?.getMonth() + 1
              }/${createDate?.getFullYear()}`}
              {createDate?.day}
            </span>
          </div>
        </Col>

        <Col md={12} lg={6} className={styles["action-box"]}>
          <span
            className={`${styles["application-status"]} ${
              status === "Initiated"
                ? styles["initiated"]
                : status === "ProcessedNoLender"
                ? styles["processed-no-lender"]
                : status === "PendingApplicantSubmission"
                ? styles["pending-applicant-submission"]
                : status === "Submitted"
                ? styles["submitted"]
                : status === "Processing"
                ? styles["processing"]
                : status === "Approved"
                ? styles["processed-successfuly-with-approval"]
                : status === "NoApproval"
                ? styles["processed-no-approval"]
                : status === "ProcessedNoLender"
                ? styles["processed-no-lender"]
                : status === "Error"
                ? styles["processing-error"]
                : status === "LenderLinkClicked"
                ? styles["lender-link-clicked"]
                : status === "Applied"
                ? styles["applicant-applied"]
                : status === "Abandoned"
                ? styles["abandoned"]
                : styles["none"]
            }`}
          >
            {applicationStatus.getLabel(status)}
          </span>
          <div className={styles["buttons-box"]}>
            <TooltipControl
              content={isExtendable ? "Extend Application" : "Not Extendable"}
            >
              <button
                className={
                  styles[isExtendable ? "icon-box" : "icon-box-disabled"]
                }
                disabled={!isExtendable}
              >
                <ArrowTurnForwardIcon
                  className={styles["icon"]}
                  onClick={() => setShowExtendModal(true)}
                />
              </button>
            </TooltipControl>

            <TooltipControl
              content={
                moment(createDate).isAfter(dateDifference)
                  ? "Duplicate Application"
                  : "Not Duplicable"
              }
            >
              <button
                className={
                  styles[
                    moment(createDate).isAfter(dateDifference)
                      ? "icon-box"
                      : "icon-box-disabled"
                  ]
                }
                disabled={!moment(createDate).isAfter(dateDifference)}
              >
                <Copy01Icon
                  className={styles["icon"]}
                  onClick={() => {
                    setShowDuplicatedModal(true);
                  }}
                />
              </button>
            </TooltipControl>
            {status === "Initiated" && (
              <TooltipControl content={"Edit Application"}>
                <button className={styles["icon-box"]}>
                  <Edit02Icon
                    className={styles["icon"]}
                    onClick={() => navigate(`/applications/edit/${personId}`)}
                  />
                </button>
              </TooltipControl>
            )}
            <TooltipControl content="Application Details">
              <button
                className={styles["icon-box"]}
                onClick={() => {
                  setShowDetailsModal(true);
                }}
              >
                <MoreHorizontalCircle01Icon className={styles["icon"]} />
              </button>
            </TooltipControl>
          </div>
        </Col>
      </Row>

      <Row
        className={styles["second-row"]}
        onClick={() => {
          setShowDetailsModal(true);
        }}
      >
        <Col sm={12} md={6} lg={4} className={styles["first-col"]}>
          <IdIcon className={styles["icon"]} />
          {personId}
        </Col>
        <Col className={styles["second-col"]}>
          <IdVerifiedIcon className={styles["icon"]} />
          {!!chartNumber ? chartNumber : "-"}
        </Col>
        <Col className={styles["third-col"]}>
          <Hospital02Icon className={styles["icon"]} />
          {locationName}
        </Col>
      </Row>

      <div className={styles["third-row"]}>
        <div className={styles["first-col"]}>
          <div className={styles["title-col"]}>
            <div className={styles["middle-icons"]}>
              <Wallet03Icon className={styles["wallet-icon"]} />
            </div>
            <div className={styles["title"]}>Amount</div>
          </div>
          <div className={styles["value-col"]}>
            <span className={styles["value"]}>
              {/* {amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              })} */}
              {commaSeperatedCurrency(amount, 0)}
            </span>
          </div>
        </div>

        <div className={styles["seprator"]}></div>

        <div className={styles["middle-col"]}>
          <div className={styles["title-col"]}>
            <div className={styles["middle-icons"]}>
              <MedicineBottle01Icon className={styles["medical-icon"]} />
            </div>
            <div className={styles["title"]}>Treatment Type</div>
          </div>
          <div className={styles["value-col"]}>
            <div className={styles["value"]}>
              {
                treatmentType.collection.find(
                  (i) =>
                    i.value ===
                    treatmentType.getValueByText(application.treatmentType)
                ).label
              }
            </div>
          </div>
        </div>

        <div className={styles["seprator"]}></div>

        <div className={styles["last-col"]}>
          <div className={styles["title-col"]} style={{ paddingRight: 0 }}>
            <div className={styles["middle-icons"]}>
              <Payment02Icon className={styles["payment-icon"]} />
            </div>
            <div className={styles["title"]}>Prefered Monthly Payment</div>
          </div>
          <div className={styles["value-col"]}>
            <span className={styles["value"]}>
              {preferredMonthlyPayment !== 0
                ? commaSeperatedCurrency(preferredMonthlyPayment, 0)
                : "-"}
            </span>
          </div>
        </div>
      </div>

      <div
        className={`${styles["last-row"]} ${
          showChildren && styles["child-open"]
        }`}
      >
        <div className={styles["first-part"]}>
          <div className={styles["first-col"]}>
            <span className={styles["last-icons"]}>
              <Call02Icon className={styles["call-icon"]} />
            </span>
            <span className={styles["last-values"]}>{phoneNumber}</span>
          </div>
          <div className={styles["second-col"]}>
            <span className={styles["last-icons"]}>
              <Mail02Icon className={styles["email-icon"]} />
            </span>
            <span className={styles["last-values"]}>{emailAddress}</span>
          </div>
        </div>
        {childrenCollection.length > 0 && (
          <div
            className={styles["tools"]}
            onClick={() => {
              setShowChildren(!showChildren);
              // console.log(appDetails.current.scrollHeight);
              // appDetails.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div className={styles["locationIcon"]}>
              <Note01Icon />
            </div>
            <div className={styles["number-of-children"]}>
              {childrenCollection.length}
            </div>
            <button
              className={styles["show-more"]}
              onClick={() => setShowChildren(!showChildren)}
            >
              {!showChildren ? `Show More` : "Show Less"}
              <span
                className={`${styles["icon"]} ${
                  showChildren && styles["rotate"]
                }`}
              >
                <ArrowDown01Icon />
              </span>
            </button>
          </div>
        )}
      </div>

      {
        <div
          ref={appDetails}
          style={{
            transition: "0.5s",
            overflow: "hidden",
            maxHeight: `${
              showChildren &&
              !!childrenCollection &&
              childrenCollection.length > 0
                ? appDetails.current.scrollHeight
                : 0
            }px`,
          }}
        >
          {childrenCollection?.map((child, index) => (
            <ApplicationChildren
              key={index}
              amount={child.requestedAmount}
              createDate={child.createDate}
              treatmentType={child.treatmentType || treatmentType}
              preferredMonthlyPayment={child.preferredMonthlyPayment}
              status={child.simplifiedApplicationStatus}
              applicationInitiationType={child.applicationInitiationType}
              setShowDetailsModal={setShowDetailsModal}
              personId={child.id}
            />
          ))}
        </div>
      }
    </div>
  );
};

ApplicationCard.propTypes = {
  setStep: PropTypes.func,
  amount: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createDate: PropTypes.instanceOf(Date), // assuming createDate is a Date object
  preferredMonthlyPayment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number, // Allow number if it might be a numeric value
  ]),
  treatmentType: PropTypes.string,
  emailAddress: PropTypes.string,
  status: PropTypes.string,
  personId: PropTypes.string,
  chartNumber: PropTypes.string,
  location: PropTypes.number,
  phoneNumber: PropTypes.string,
  refreshapp: PropTypes.func,
};
export default ApplicationCard;
