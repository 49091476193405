import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import logo from "../../images/logo.svg";
import userLogo from "../../images/company-logo-default.svg";
import {
  GlobalEducationIcon,
  HelpCircleIcon,
  Logout03Icon,
  Mail01Icon,
  Mail02Icon,
  Settings01Icon,
  UserIcon,
} from "hugeicons-react";
import { isMobile } from "react-device-detect";
import { useLogout } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { useCommonContext } from "../../common-context";

const Header = ({
  // userLogoUrl = userLogo,
  user = {
    id: 0,
    firstName: null,
    lastName: null,
  },
  onLogout = () => {},
}) => {
  const [{ sqLogo }] = useCommonContext();

  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null);

  const logout = useLogout();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout()
      .then(() => {
        document.location.reload(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Function to handle click outside the menu or ESC key press
    const handleClickOutsideOrEsc = (event) => {
      // Close the menu if clicked outside or ESC key is pressed
      if (
        (menuRef.current && !menuRef.current.contains(event.target)) ||
        event.key === "Escape"
      ) {
        setOpenMenu(false);
      }
    };

    // Bind event listeners
    document.addEventListener("mousedown", handleClickOutsideOrEsc);
    document.addEventListener("keydown", handleClickOutsideOrEsc);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOrEsc);
      document.removeEventListener("keydown", handleClickOutsideOrEsc);
    };
  }, []);

  return (
    <header id="header-component" className={styles["fixed-container"]}>
      <div className={styles["container"]}>
        <div className={styles["logo-container"]}>
          <Link to="/">
            <img src={logo} alt="DentiRate" />
          </Link>
        </div>
        {!!user && (
          <div className={styles["user-container-wrapper"]}>
            {!isMobile && (
              <div
                // herf="#"
                // onClick={() => false}
                className={styles["user-container"]}
                style={{ cursor: "default" }}
              >
                {sqLogo?.data && (
                  <img src={sqLogo?.data?.url} alt="user-logo" />
                )}
              </div>
            )}
            <div
              onClick={() => setOpenMenu(!openMenu)}
              className={styles["user-container"]}
            >
              <UserIcon />
              {!isMobile && (
                <div className={styles["user-name"]}>
                  {user.firstName} {user.lastName}
                </div>
              )}
              {openMenu && (
                <div ref={menuRef} className={`${styles["menu"]}`}>
                  <div className={`${styles["title"]} ${styles["row"]}`}>
                    <Mail02Icon />
                    {user.email}
                  </div>
                  <div className={styles["row-wrapper"]}>
                    <button
                      className={`${styles["first"]} ${styles["row"]}`}
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <UserIcon />
                      User Profile
                    </button>
                    {/* <button
                      className={`${styles["second"]} ${styles["row"]}`}
                      onClick={() => {
                        console.log("Settings!");
                      }}
                    >
                      <Settings01Icon />
                      Settings
                    </button> */}

                    <button
                      className={`${styles["second"]} ${styles["row"]}`}
                      onClick={() => {
                        navigate("/university");
                      }}
                    >
                      <HelpCircleIcon />
                      University
                    </button>

                    <button
                      className={`${styles["second"]} ${styles["row"]}`}
                      onClick={handleLogout}
                    >
                      <Logout03Icon />
                      Log Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  userLogoUrl: PropTypes.string,
  onLogout: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default Header;
