import { useEffect, useMemo, useState } from "react";
import { useCommonContext } from "../../../common-context";
import ApplicationCard from "../../../components/application-card";
import FilterSearch from "../../../components/filter-search/application-filter-search";
import Layout from "../../../layout";
import styles from "./styles.module.scss";
import _ from "lodash";
import { toast } from "react-toastify";
import useGetApplication from "../../../hooks/use-get-application";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import Pagination from "../../../components/pagination-control/pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks";
import applicationStatus from "../../../infrastructure/constants/enums/simplified-application-status";
import treatmentType from "../../../infrastructure/constants/enums/treatment-types";
import { queryStringHelpers } from "../../../infrastructure/helpers";
import NewApplicationModal from "./new-application-modal";
import { HgLendersSelectControl } from "../../../components/select-control";
import ExtendApplicationModal from "../../../components/application-card/extend-application-modal";
import { NoteAddIcon } from "hugeicons-react";
import { Container } from "react-bootstrap";

const Application = () => {
  // external lib
  const navigate = useNavigate();
  const location = useLocation();

  // internal hooks
  const queryHelper = useQuery();
  const handleApplication = useGetApplication();

  // common context
  const [{ user, logo }] = useCommonContext();

  // states
  const [applicationFormMessage, setApplicationFormMessage] = useState({
    show: false,
    message: "",
  });

  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [applications, setApplications] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reload, setReload] = useState(false);
  // console.log(modalData);
  const refreshapp = () => {
    setReload((prev) => !prev);
    // console.log(reload); // Toggle the reload state
  };
  const searchFilterData = useMemo(() => {
    return {
      start: parseInt(queryHelper.getValue("start"), 10) || 0,
      records: 10,
      query: queryHelper.getValue("query"),
      minAmount: parseInt(queryHelper.getValue("minAmount"), 10) || undefined,
      maxAmount: parseInt(queryHelper.getValue("maxAmount"), 10) || undefined,
      applicationStatusCollection: _.uniq(
        (queryHelper.getAllValues("status") || [])
          .map((i) => applicationStatus.getValueByText(i))
          .filter((i) => !!i)
      ),
      treatmentType: treatmentType.getValueByText(
        queryHelper.getValue("treatmentType")
      ),
      locationIdCollection: (queryHelper.getAllValues("location") || [])
        .map((i) => parseInt(i, 10))
        .filter((i) => !!i),
      healthcareGroupId:
        parseInt(queryHelper.getValue("healthcareGroupId"), 10) || undefined,
    };
  }, [location.search]);

  // functions
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  // console.log(applications);
  const handleFilterChange = (newFilter) => {
    const temp = _.cloneDeep(newFilter);

    // convert applicationStatusCollection --> status
    temp.status = temp.applicationStatusCollection;
    // then delete it
    delete temp.applicationStatusCollection;
    // why did I do it? because it seems prettier!!!

    // convert locationIdCollection --> location
    temp.location = temp.locationIdCollection;
    // then delete it
    delete temp.locationIdCollection;
    const queryString = queryStringHelpers.stringify(temp);
    navigate(`/lead` + !!queryString ? "?" + queryString : "");
  };

  useEffect(() => {
    let actionStatus = null;
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);

    const tempQuery = {
      query: searchFilterData.query,
      minAmount: searchFilterData.minAmount,
      maxAmount: searchFilterData.maxAmount,
      simplifiedApplicationStatus: searchFilterData.applicationStatusCollection,
      treatmentType: searchFilterData.treatmentType,
      locationIdCollection: searchFilterData.locationIdCollection,
      start: searchFilterData.start,
      records: searchFilterData.records,
      healthcareGroupId: searchFilterData.healthcareGroupId,
    };

    handleApplication(tempQuery)
      .then(([res, status, totalRecords]) => {
        setLoadingStatus(apiLoadingStatus.loaded);
        actionStatus = status;
        setApplications(res);
        setTotalRecords(totalRecords);
        // console.log(res);
      })
      .catch((err) => {
        setLoadingStatus(apiLoadingStatus.error);
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        switch (actionStatus) {
          case "InvalidCredentials":
            showErrorMassage("Invalid Credientials");
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credientials Newly LockedOut");
            break;
          case "UsedBefore":
            showErrorMassage("UsedBefore");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Your account was just locked out.");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Exists");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Unauthorized":
            navigate("/login");
            break;
          case "Success":
            break;
          default:
            showErrorMassage("Unknown error");
            break;
        }
      });
  }, [location.search, reload]);

  useEffect(() => {
    if (!!location?.state?.applicationFormMessage) {
      setApplicationFormMessage({
        ...applicationFormMessage,
        show: location.state?.applicationFormMessage?.show || false,
        message: location.state?.applicationFormMessage?.message || "",
      });
      // clean the state browser
      // navigate(location.pathname, { replace: true });
    }
    // return navigate(location.pathname, { replace: true });
  }, [location?.state?.applicationFormMessage]);

  useEffect(() => {
    document.title = "Applications | DentiRate";
    window.scrollTo(0, 0);
  }, [searchFilterData.start]);
  return (
    <>
      <Layout
        // user={{
        //   type: "admin",
        //   name: user.data.firstName + " " + user.data.lastName,
        //   logo: logo.data?.url,
        // }}
        isLoading={loadingStatus === apiLoadingStatus.loading}
      >
        {/* <LoadingSpinner
          isLoading={loadingStatus === apiLoadingStatus.loading}
        /> */}
        <Container className={styles["container"]}>
          <NewApplicationModal
            show={applicationFormMessage.show}
            message={applicationFormMessage.message}
            handleClose={() => {
              setApplicationFormMessage({
                ...applicationFormMessage,
                message: "",
                show: false,
              });
              // clean the state browser
              navigate(location.pathname, { replace: true });
            }}
          />

          <FilterSearch
            searchFilterData={searchFilterData}
            setSearchFilterData={handleFilterChange}
          >
            <button
              className={`${styles["new-application-btn"]} btn btn-primary`}
              onClick={() => navigate("/applications/new")}
            >
              <NoteAddIcon />
              New Application
            </button>
          </FilterSearch>
          {applications.map((application, index) => (
            <ApplicationCard
              key={index}
              amount={application.amount}
              firstName={application.firstName}
              lastName={application.lastName}
              createDate={application.createDate}
              preferredMonthlyPayment={application.preferredMonthlyPayment}
              treatmentType={application.treatmentType}
              emailAddress={application.emailAddress}
              status={application.simplifiedApplicationStatus}
              personId={application.id}
              chartNumber={application.hgPatientReferenceId}
              location={application.locationId}
              phoneNumber={application.cellPhone}
              isExtendable={application.isExtendable}
              refreshapp={refreshapp}
              childrenCollection={application.childrenCollection || []}
              application={application}
            />
          ))}

          {totalRecords > applications.length && (
            <Pagination
              start={searchFilterData.start}
              records={searchFilterData.records}
              spanWidth={5}
              totalCount={totalRecords}
              onClick={(value) =>
                handleFilterChange({
                  ...searchFilterData,
                  start: value,
                })
              }
              displayViewOptions={false}
              handleViewOptions={(records) =>
                handleFilterChange({
                  ...searchFilterData,
                  records: records,
                })
              }
              // className={styles["pagination"]}
            />
          )}
        </Container>
      </Layout>
    </>
  );
};

export default Application;
