import React, { useEffect } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import SelectControl, {
  HgSitesSelectControl,
} from "../../../components/select-control";
import { NumericFormat, PatternFormat } from "react-number-format";
import styles from "./styles.module.scss";
import { stateCollection } from "../../../infrastructure/constants";
import _ from "lodash";
import {
  getDefaultApplication,
  getDefaultApplicationValidations,
} from "./utils/utils";
import { commaSeperatedCurrency } from "../../../infrastructure/helpers";
import ValidationWrapper from "../../../components/validation-wrapper";
import { isMobile } from "react-device-detect";
import moment from "moment";
import treatmentType from "../../../infrastructure/constants/enums/treatment-types";
import languageType from "../../../infrastructure/constants/enums/languages";
import CheckBox from "../../../components/check-box/check-box";
import RadioButton from "../../../components/radio-button";
import relationship from "../../../infrastructure/constants/enums/relationship";

const preferredLanguageCollection = languageType;
const durationCollection = [];
for (let i = 0; i < 61; i++) {
  durationCollection.push({ label: `${i} Months`, value: i });
}

// console.log(_.isNil(0));
const ConsumerApplicationInputs = ({
  user = {},
  onChange = (name, value) => {},
  application = getDefaultApplication(),
  applicationValidations = getDefaultApplicationValidations(),
  validateInputs = (name, value) => {},
  isExistingApplication = false,
}) => {
  const innerChange = (name, newValue) => {
    const temp = { ...application };
    let value = newValue || null;
    switch (name) {
      case "initialAvailableCash":
      case "insuranceCoverage":
        value = newValue;
        break;
      case "treatmentInMonth":
        value = !_.isNil(newValue.value) ? newValue?.value : "";
        break;
      case "applicantInfo.preferredLanguage":
      case "applicantInfo.state":
      case "treatmentType":
      case "hg_SiteId":
        value = newValue.value || null;
        break;
      case "applicantInfo.availableCreditLine":
        value = newValue;
        temp.applicantInfo.availableCreditLine =
          (typeof value === "number" && value) || null;
        temp.applicantInfo.hasAvaiableCreditLine =
          !!temp.applicantInfo.availableCreditLine &&
          temp.applicantInfo.availableCreditLine > 0;
        break;
      case "applicantInfo.hasAvaiableCreditLine":
        value = newValue;
        temp.applicantInfo.availableCreditLine = value ? temp.amount : 0;
        break;
      case "applicantInfo.noSsn":
        if (!!value) {
          temp.applicantInfo.ssn = null;
          temp.applicantInfo.ssnLast4 = null;
          validateInputs("applicantInfo.ssn", undefined, {
            ...application,
            applicantInfo: {
              ...application.applicantInfo,
              noSsn: true,
            },
          });
        }

        break;
      default:
        break;
    }

    validateInputs(name, value, application);
    if (!!name) {
      _.set(temp, name, value);
      onChange(temp);
    }
  };

  useEffect(() => {
    if (!user.data.hg.inHouseFinancingEnabled) {
      innerChange("applicationRequestType", "OnlyThirdParty");
    }
  }, []);

  useEffect(() => {}, [application]);
  useEffect(() => {
    document.title = "New Application | DentiRate";
    window.scrollTo(0, 0);
    // console.log(application);
  }, []);
  return (
    <div div className={styles["input-container"]}>
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>Contract Information</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.hg_SiteId}
              title={"Office"}
              titleStar={true}
              errorMessage="Invalid office"
            >
              <HgSitesSelectControl
                onChange={(v) => innerChange("hg_SiteId", v)}
                className={`${styles["input"]} ${
                  !applicationValidations.hg_SiteId && styles["not-valid"]
                }`}
                value={application.hg_SiteId}
                isActive
                isClearable={false}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.treatmentType}
              title="Treatment Type"
              titleStar={true}
              errorMessage="Invalid treatment type"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !applicationValidations.treatmentType && styles["not-valid"]
                }`}
                options={treatmentType.collection}
                value={
                  !!application?.treatmentType
                    ? treatmentType.collection.find(
                        (i) =>
                          i.value ===
                          treatmentType.getValueByText(
                            application.treatmentType
                          )
                      )
                    : null
                }
                onChange={(v) => {
                  innerChange("treatmentType", v);
                }}
                placeholder=""
                isClearable={false}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.treatmentInMonth || true}
              title="Treatment Duration (month)"
              errorMessage=""
            >
              <SelectControl
                type="number"
                className={styles["input"]}
                options={durationCollection}
                isClearable={false}
                value={
                  !_.isNil(application?.treatmentInMonth)
                    ? durationCollection.find(
                        (i) => i.value === application.treatmentInMonth
                      )
                    : null
                }
                onChange={(value) => {
                  innerChange("treatmentInMonth", value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>

          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={
                applicationValidations.applicantInfo?.hgPatientReferenceId ||
                true
              }
              title="Chart No"
              errorMessage="Invalid patient reference ID"
            >
              <Form.Control
                type="text"
                className="form-control"
                value={application.applicantInfo.hgPatientReferenceId || ""}
                onChange={(e) => {
                  innerChange(
                    "applicantInfo.hgPatientReferenceId",
                    e.target.value
                  );
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
        </Row>
        {/* <Row>
          {application.treatmentType === "orthodontics" && (
            <Col className="relative" xs={12} md={12} lg={6}>
              <ValidationWrapper
                // isValid={applicationValidations.treatmentType}
                title="Ortho Type"
                // titleStar={true}
                // errorMessage="Invalid treatment type"
              >
                <SelectControl
                  className={`${styles["input"]} ${
                    !applicationValidations.treatmentType &&
                    styles["orthodontics"]
                  }`}
                  // options={orthoType.collection}
                  // value={
                  //   !!application?.orthoType
                  //     ? orthoType.collection.find(
                  //         (i) =>
                  //           i.value ===
                  //           orthoType.getValueByText(
                  //             application.orthoType
                  //           )
                  //       )
                  //     : null
                  // }
                  // onChange={(v) => innerChange("orthoType", v)}
                  placeholder=""
                />
              </ValidationWrapper>
            </Col>
          )}
        </Row> */}
      </div>

      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>Applicant Information</span>
          <div className={styles["line"]}></div>
        </div>

        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.firstName}
              title={"First Name"}
              titleStar={true}
              errorMessage="Invalid first name"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.firstName && "danger"
                }`}
                value={application.applicantInfo?.firstName || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.firstName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.lastName}
              title={"Last Name"}
              titleStar={true}
              errorMessage="Invalid last name"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.lastName && "danger"
                }`}
                value={application.applicantInfo?.lastName || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.lastName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>

          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.doB}
              title={"Date of Birth"}
              titleStar={true}
              errorMessage="Invalid date of birth (between 18 and 100)"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.doB && "danger"
                }`}
                value={
                  !!application.applicantInfo?.doB &&
                  moment(application.applicantInfo?.doB) &&
                  moment(application.applicantInfo?.doB).isValid()
                    ? moment(application.applicantInfo?.doB).format(
                        "yyyy-MM-DD"
                      )
                    : ""
                }
                onChange={(e) =>
                  innerChange("applicantInfo.doB", e.target.value)
                }
                type="date"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.preferredLanguage}
              title={"Preferred Language"}
              titleStar={true}
              errorMessage="Invalid preferred language"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !applicationValidations.applicantInfo.preferredLanguage &&
                  styles["not-valid"]
                }`}
                options={preferredLanguageCollection.collection}
                value={preferredLanguageCollection.collection.find(
                  (i) =>
                    i.value ===
                    preferredLanguageCollection.getValueByText(
                      application.applicantInfo.preferredLanguage
                    )
                )}
                isClearable={false}
                onChange={(v) =>
                  innerChange("applicantInfo.preferredLanguage", v)
                }
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
        </Row>

        <Row>
          {/* <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              // isValid={applicationValidations.applicantInfo.preferredLanguage}
              title={"Relationship to Patient"}
              titleStar={false}
              // errorMessage="Invalid preferred language"
            >
              <SelectControl
                className={`${styles["input"]}`}
                options={relationship.collection}
                value={relationship.collection.find(
                  (i) =>
                    i.value ===
                    relationship.getValueByText(
                      application.applicantInfo.isMainApplicant
                    )
                )}
                isClearable={false}
                onChange={(v) => {
                  innerChange("applicantInfo.isMainApplicant", v.value);
                }}
                placeholder=""
              />
            </ValidationWrapper>
          </Col> */}
          <div className={styles["checkbox-container"]}>
            <CheckBox
              text="Main Applicant"
              onchange={(e) => {
                // console.log(e.target.checked);
                innerChange("applicantInfo.isMainApplicant", e.target.checked);
              }}
              Checked={application.applicantInfo?.isMainApplicant}
              style={{ marginTop: "0px" }}
            />
          </div>
        </Row>
        <div className={styles["separator-dashed"]}></div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.street1}
              title={"Street Line 1"}
              titleStar={true}
              errorMessage="Invalid street line"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.street1 && "danger"
                }`}
                value={application.applicantInfo?.street1 || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.street1", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.street2}
              title={"Street Line 2"}
              errorMessage="Invalid street line"
            >
              <Form.Control
                value={application.applicantInfo?.street2 || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.street2", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={1}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.apartmentOrUnit}
              title={"Apt/Unit"}
              errorMessage="Invalid apt"
            >
              <Form.Control
                value={application.applicantInfo?.apartmentOrUnit || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.apartmentOrUnit", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.city}
              title={"City"}
              titleStar={true}
              errorMessage="Invalid city"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.city && "danger"
                }`}
                value={application.applicantInfo?.city || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.city", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.state}
              title={"State"}
              titleStar={true}
              errorMessage="Invalid state"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !applicationValidations.applicantInfo.state &&
                  styles["not-valid"]
                }`}
                options={stateCollection}
                value={
                  !!application.applicantInfo.state
                    ? stateCollection.find(
                        (i) => i.value === application.applicantInfo.state
                      )
                    : null
                }
                isClearable={false}
                onChange={(v) => innerChange("applicantInfo.state", v)}
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.zipcode}
              title={"Zip Code"}
              titleStar={true}
              inputId="input-zipcode"
              errorMessage="Invalid zip code"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.zipcode && "danger"
                }`}
                maxLength={5}
                value={application.applicantInfo?.zipcode || ""}
                onChange={(e) => {
                  if (e.target.value && e.target.value.match(/\D/gi)) {
                    return false;
                  }
                  innerChange("applicantInfo.zipcode", e.target.value);
                }}
                id="input-zipcode"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <div className={styles["separator-dashed"]}></div>

        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.cellPhone}
              title="Cell Phone Number"
              titleStar={true}
              inputId="input-cell-phone"
              errorMessage="Invalid cell phone"
            >
              <PatternFormat
                className={`form-control ${
                  !applicationValidations.applicantInfo.cellPhone && "danger"
                }`}
                id="input-cell-phone"
                name="number"
                autoComplete="off"
                customInput={FormControl}
                value={application.applicantInfo.cellPhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.cellPhone", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.emailAddress}
              title={"Email Address"}
              titleStar={true}
              inputId="input-emailAddress"
              errorMessage="Invalid email address"
            >
              <Form.Control
                className={`form-control ${
                  !applicationValidations.applicantInfo.emailAddress && "danger"
                }`}
                type="email"
                value={application.applicantInfo?.emailAddress || ""}
                onChange={(e) => {
                  innerChange("applicantInfo.emailAddress", e.target.value);
                }}
                id="input-emailAddress"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo?.homePhone || true}
              title="Home Phone Number"
              inputId="input-home-phone"
              errorMessage="Invalid home phone"
            >
              <PatternFormat
                id="input-home-phone"
                name="number"
                className="form-control"
                autoComplete="off"
                customInput={FormControl}
                value={application.applicantInfo.homePhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.homePhone", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo?.workPhone || true}
              title="Work Phone Number"
              inputId="input-work-phone"
              errorMessage="Invalid work phone"
            >
              <PatternFormat
                id="input-work-phone"
                name="number"
                className="form-control"
                autoComplete="off"
                customInput={FormControl}
                value={application.applicantInfo.workPhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.workPhone", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {!application?.applicantInfo.isMainApplicant && (
        <div className={styles["section"]}>
          <div className={styles["section-title"]}>
            <span>Patient Information</span>
            <div className={styles["line"]}></div>
          </div>

          <Row>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.firstName}
                title={"First Name"}
                titleStar={true}
                errorMessage="Invalid first name"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.firstName && "danger"
                  }`}
                  value={application.applicantInfo?.firstName || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.firstName", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.lastName}
                title={"Last Name"}
                titleStar={true}
                errorMessage="Invalid last name"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.lastName && "danger"
                  }`}
                  value={application.applicantInfo?.lastName || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.lastName", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.doB}
                title={"Date of Birth"}
                titleStar={true}
                errorMessage="Invalid date of birth (between 18 and 100)"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.doB && "danger"
                  }`}
                  value={
                    !!application.applicantInfo?.doB &&
                    moment(application.applicantInfo?.doB) &&
                    moment(application.applicantInfo?.doB).isValid()
                      ? moment(application.applicantInfo?.doB).format(
                          "yyyy-MM-DD"
                        )
                      : ""
                  }
                  onChange={(e) =>
                    innerChange("applicantInfo.doB", e.target.value)
                  }
                  type="date"
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.preferredLanguage}
                title={"Preferred Language"}
                titleStar={true}
                errorMessage="Invalid preferred language"
              >
                <SelectControl
                  className={`${styles["input"]} ${
                    !applicationValidations.applicantInfo.preferredLanguage &&
                    styles["not-valid"]
                  }`}
                  options={preferredLanguageCollection.collection}
                  value={preferredLanguageCollection.collection.find(
                    (i) =>
                      i.value ===
                      preferredLanguageCollection.getValueByText(
                        application.applicantInfo.preferredLanguage
                      )
                  )}
                  isClearable={false}
                  onChange={(v) =>
                    innerChange("applicantInfo.preferredLanguage", v)
                  }
                  placeholder=""
                />
              </ValidationWrapper>
            </Col>
          </Row>
          <div className={styles["separator-dashed"]}></div>
          <Row>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.street1}
                title={"Street Line 1"}
                titleStar={true}
                errorMessage="Invalid street line"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.street1 && "danger"
                  }`}
                  value={application.applicantInfo?.street1 || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.street1", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={2}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.street2}
                title={"Street Line 2"}
                errorMessage="Invalid street line"
              >
                <Form.Control
                  value={application.applicantInfo?.street2 || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.street2", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={1}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.apartmentOrUnit}
                title={"Apt/Unit"}
                errorMessage="Invalid apt"
              >
                <Form.Control
                  value={application.applicantInfo?.apartmentOrUnit || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.apartmentOrUnit", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={2}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.city}
                title={"City"}
                titleStar={true}
                errorMessage="Invalid city"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.city && "danger"
                  }`}
                  value={application.applicantInfo?.city || ""}
                  onChange={(e) =>
                    innerChange("applicantInfo.city", e.target.value)
                  }
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={2}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.state}
                title={"State"}
                titleStar={true}
                errorMessage="Invalid state"
              >
                <SelectControl
                  className={`${styles["input"]} ${
                    !applicationValidations.applicantInfo.state &&
                    styles["not-valid"]
                  }`}
                  options={stateCollection}
                  value={
                    !!application.applicantInfo.state
                      ? stateCollection.find(
                          (i) => i.value === application.applicantInfo.state
                        )
                      : null
                  }
                  isClearable={false}
                  onChange={(v) => innerChange("applicantInfo.state", v)}
                  placeholder=""
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={2}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.zipcode}
                title={"Zip Code"}
                titleStar={true}
                inputId="input-zipcode"
                errorMessage="Invalid zip code"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.zipcode && "danger"
                  }`}
                  maxLength={5}
                  value={application.applicantInfo?.zipcode || ""}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.match(/\D/gi)) {
                      return false;
                    }
                    innerChange("applicantInfo.zipcode", e.target.value);
                  }}
                  id="input-zipcode"
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
          </Row>
          <div className={styles["separator-dashed"]}></div>

          <Row>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.cellPhone}
                title="Cell Phone Number"
                titleStar={true}
                inputId="input-cell-phone"
                errorMessage="Invalid cell phone"
              >
                <PatternFormat
                  className={`form-control ${
                    !applicationValidations.applicantInfo.cellPhone && "danger"
                  }`}
                  id="input-cell-phone"
                  name="number"
                  autoComplete="off"
                  customInput={FormControl}
                  value={application.applicantInfo.cellPhone || ""}
                  onValueChange={(values) => {
                    innerChange("applicantInfo.cellPhone", values.value);
                  }}
                  type="tel"
                  format="+1 (###) ###-####"
                  mask="_"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={applicationValidations.applicantInfo.emailAddress}
                title={"Email Address"}
                titleStar={true}
                inputId="input-emailAddress"
                errorMessage="Invalid email address"
              >
                <Form.Control
                  className={`form-control ${
                    !applicationValidations.applicantInfo.emailAddress &&
                    "danger"
                  }`}
                  type="email"
                  value={application.applicantInfo?.emailAddress || ""}
                  onChange={(e) => {
                    innerChange("applicantInfo.emailAddress", e.target.value);
                  }}
                  id="input-emailAddress"
                  autoComplete="off"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={
                  applicationValidations.applicantInfo?.homePhone || true
                }
                title="Home Phone Number"
                inputId="input-home-phone"
                errorMessage="Invalid home phone"
              >
                <PatternFormat
                  id="input-home-phone"
                  name="number"
                  className="form-control"
                  autoComplete="off"
                  customInput={FormControl}
                  value={application.applicantInfo.homePhone || ""}
                  onValueChange={(values) => {
                    innerChange("applicantInfo.homePhone", values.value);
                  }}
                  type="tel"
                  format="+1 (###) ###-####"
                  mask="_"
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={
                  applicationValidations.applicantInfo?.workPhone || true
                }
                title="Work Phone Number"
                inputId="input-work-phone"
                errorMessage="Invalid work phone"
              >
                <PatternFormat
                  id="input-work-phone"
                  name="number"
                  className="form-control"
                  autoComplete="off"
                  customInput={FormControl}
                  value={application.applicantInfo.workPhone || ""}
                  onValueChange={(values) => {
                    innerChange("applicantInfo.workPhone", values.value);
                  }}
                  type="tel"
                  format="+1 (###) ###-####"
                  mask="_"
                />
              </ValidationWrapper>
            </Col>
          </Row>
        </div>
      )}

      <div className={`${styles["section"]} ${isMobile ? "" : "pb-4"}`}>
        <div className={styles["section-title"]}>
          <span>Credit & Financial Information</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <ValidationWrapper
            wrapperStyle={{ paddingTop: "0px" }}
            alertStyle={{
              borderTop: "1px solid #cc292950",
              borderRadius: "0px",
            }}
            isValid={
              application.amount >
                application.initialAvailableCash +
                  application.insuranceCoverage ||
              (application.amount === undefined &&
                application.initialAvailableCash === null &&
                application.insuranceCoverage === null) ||
              (application.amount === null &&
                application.initialAvailableCash === undefined &&
                application.insuranceCoverage === undefined)
            }
            errorMessage="Treatment Cost must be greater than the sum of Insurance Coverage and Upfront payment"
          >
            <Row>
              <Col className="relative" xs={12} md={6} lg={4}>
                <ValidationWrapper
                  isValid={applicationValidations.amount}
                  title="$ Treatment Cost"
                  titleStar={true}
                  errorMessage="Invalid contract value"
                >
                  <NumericFormat
                    name="number"
                    className={`form-control ${
                      !applicationValidations.amount && "danger"
                    }`}
                    thousandSeparator={true}
                    prefix={"$"}
                    placeholder=""
                    autoComplete="off"
                    customInput={FormControl}
                    allowNegative={false}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    value={application.amount || ""}
                    onValueChange={(values) => {
                      innerChange("amount", values.floatValue);
                    }}
                    maxLength={8}
                  />
                </ValidationWrapper>
              </Col>

              <Col className="relative" xs={12} md={6} lg={4}>
                <ValidationWrapper
                  isValid={applicationValidations.insuranceCoverage}
                  title={"Insurance Coverage"}
                  titleStar={true}
                  inputId="Insurance-Coverage"
                  errorMessage="Invalid Insurance Coverage"
                >
                  <NumericFormat
                    id="Insurance-Coverage"
                    name="number"
                    className={`form-control ${
                      !applicationValidations.insuranceCoverage && "danger"
                    }`}
                    thousandSeparator={true}
                    prefix={"$"}
                    placeholder=""
                    autoComplete="off"
                    customInput={FormControl}
                    allowNegative={false}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    value={application.insuranceCoverage || null}
                    onValueChange={(values) => {
                      innerChange("insuranceCoverage", values.floatValue);
                    }}
                    maxLength={50}
                  />
                </ValidationWrapper>
              </Col>

              <Col className="relative" xs={12} md={6} lg={4}>
                <ValidationWrapper
                  isValid={applicationValidations.initialAvailableCash}
                  title={"Upfront payment"}
                  titleStar={true}
                  inputId="down-payment"
                  errorMessage="Invalid Upfront payment"
                >
                  <NumericFormat
                    id="down-payment"
                    name="number"
                    className={`form-control ${
                      !applicationValidations.initialAvailableCash && "danger"
                    }`}
                    thousandSeparator={true}
                    prefix={"$"}
                    placeholder=""
                    autoComplete="off"
                    customInput={FormControl}
                    allowNegative={false}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    value={application.initialAvailableCash || ""}
                    onValueChange={(values) => {
                      innerChange("initialAvailableCash", values.floatValue);
                    }}
                    maxLength={50}
                  />
                </ValidationWrapper>
              </Col>
            </Row>
          </ValidationWrapper>
          <Col
            className="relative"
            xs={12}
            md={6}
            lg={3}
            style={{ top: "8px" }}
          ></Col>
        </Row>{" "}
        {user.data.hg.inHouseFinancingEnabled && (
          <div style={{ marginTop: "5px" }}>
            <ValidationWrapper
              isValid={applicationValidations.applicationRequestType}
              title={"Application Request Type"}
              titleStar={true}
              inputId="application-request-type"
              errorMessage="One of the above options must be selected"
              alertStyle={{
                marginTop: "5px",
              }}
            >
              <Row id="application-request-type">
                <Col xs={12} md={12} lg={6}>
                  <RadioButton
                    text="Apply for both third-party and in-house financing"
                    style={{ marginTop: "8px" }}
                    onClick={() =>
                      innerChange(
                        "applicationRequestType",
                        "BothThirdPartyAndIHF"
                      )
                    }
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <RadioButton
                    text="Apply for third-party first; if declined, try in-house financing"
                    style={{ marginTop: "8px" }}
                    onClick={() =>
                      innerChange(
                        "applicationRequestType",
                        "FirstThirdPartyThenIHF"
                      )
                    }
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <RadioButton
                    text="Only apply for in-house financing"
                    style={{ marginTop: "8px" }}
                    onClick={() =>
                      innerChange("applicationRequestType", "OnlyIHF")
                    }
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <RadioButton
                    text="Only apply for third-party financing"
                    style={{ marginTop: "8px" }}
                    onClick={() =>
                      innerChange("applicationRequestType", "OnlyThirdParty")
                    }
                  />
                </Col>
              </Row>
            </ValidationWrapper>
          </div>
        )}
        <div className={styles["required-note"]}>
          <span className={styles["red-star"]}>*</span> required fields
        </div>
      </div>
    </div>
  );
};

ConsumerApplicationInputs.propTypes = {};

export default ConsumerApplicationInputs;
