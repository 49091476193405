// Table of Contents Data
//userGroup: ["None", "DentiRate", "HealthcareGroup", Lender", "Regular"], // Visible to all groups
//userRole: ["None", "SuperAdministrator", "Administrator", "Moderator", "Regular"], // Visible to multiple roles
export const tocDataCollection = [
  {
    id: "section1",
    title: "Introduction",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection1-1",
        title: "About DentiRate",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection1-2",
        title: "User Roles",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [
          {
            id: "subsubsection1-2-1",
            title: "Admin Role",
            userGroup: ["HealthcareGroup"], // Visible to both groups
            userRole: ["Administrator"], // Visible to admins and editors
            subSections: [],
          },
          {
            id: "subsubsection1-2-2",
            title: "Office User Role",
            userGroup: ["HealthcareGroup"], // Visible to both groups
            userRole: ["Administrator"], // Visible to admins and editors
            subSections: [],
          },
        ],
      },
      {
        id: "subsection1-3",
        title: "SOC2 Compliance",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
    ],
  },
  {
    id: "section2",
    title: "Getting Started",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection2-1",
        title: "How to Create an Account",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection2-2",
        title: "Password Requirements",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection2-3",
        title: "Logging in",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection2-4",
        title: "Changing/Resetting Your Password",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection2-5",
        title: "Forgot Password",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
      {
        id: "subsection2-6",
        title: "Logging out",
        userGroup: ["HealthcareGroup"], // Visible to both groups
        userRole: ["Administrator"], // Visible to admins and editors
        subSections: [],
      },
    ],
  },
  {
    id: "section3",
    title: "Home",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection3-1",
        title: "Home Overview",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection3-2",
        title: "Key Features",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection3-2-1",
            title: "Time Period Selection",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection3-2-2",
            title: "Application Overview",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection3-2-3",
            title: "History of Application Status",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },

          {
            id: "subsubsection3-2-4",
            title: "Active Users and Locations",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection3-2-5",
            title: "Performance Metrics",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
    ],
  },
  {
    id: "section4",
    title: "Application Management",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection4-1",
        title: "Creating a New Application",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection4-1-1",
            title: "Explain the Process",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection4-1-2",
            title: "Obtain Consent",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection4-1-3",
            title: "Data Entry",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection4-2",
        title: "Updating an Application",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection4-3",
        title: "Duplicating Existing Applications",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection4-4",
        title: "Extending an Application for Additional Loans or IHF",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection4-5",
        title: "Finding and Viewing Applications",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection4-6",
        title: "Understanding Application Statuses",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section5",
    title: "Manage Location",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection5-1",
        title: "Registering a New Location with Lenders",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection5-1-1",
            title: "Navigate to Add a New Location",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection5-1-2",
            title: "Fill Out Location Information",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection5-1-3",
            title: "Confirmation",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection5-2",
        title: "Location Information",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection5-2-1",
            title: "View Detail of a Location",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection5-2-2",
            title: "Updating Locations Status (Active/Inactive)",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection5-2-3",
            title: "Update Locations Detail",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
    ],
  },
  {
    id: "section6",
    title: "Billing",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection6-1",
        title: "Viewing All Bills and Their Payment Status",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section7",
    title: "User Management",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection7-1",
        title: "Adding a New User",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection7-1-1",
            title: "Navigate to Add a New User",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection7-1-2",
            title: "Fill Out User Information",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection7-1-3",
            title: "Confirmation",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection7-2",
        title: "Viewing User Information",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection7-3",
        title: "Updating User Information",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection7-3-1",
            title: "Accessing User Management",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection7-3-2",
            title: "Updating User Status (Active/Inactive)",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection7-3-3",
            title: "Managing User Locations",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection7-3-4",
            title: "Updating User Information",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
    ],
  },
  {
    id: "section8",
    title: "Dashboard",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection8-1",
        title: "Dashboard Overview",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection8-2",
        title: "Understanding Summary Report",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection8-3",
        title: "Using the Download Center",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection8-4",
        title: "Key Performance Indicators (KPIs)",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section9",
    title: "Partner Hub",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [],
  },
  {
    id: "section10",
    title: "Common Workflows",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection10-1",
        title: "Application Initiation",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection10-1-1",
            title: "Applicant Consent",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection10-1-2",
            title: "Data Entry",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection10-1-3",
            title: "Extend Application",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection10-1-4",
            title: "Application Link",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection10-2",
        title: "Applicant Interaction (applicant device)",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection10-2-1",
            title: "Verification",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection10-2-2",
            title: "Loan Presentation",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection10-2-3",
            title: "Loan Selection",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection10-3",
        title: "Lender Interaction",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection10-3-1",
            title: "Loan Application",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection10-4",
        title: "Transaction Verification",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section11",
    title: "Guiding Applicants in Choosing a Loan",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection11-1",
        title: "Applicant views",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection11-1-1",
            title: "Verification",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-1-2",
            title: "Loan Presentation",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-1-3",
            title: "How to Sort Loan Options",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-1-4",
            title: "Loan Details",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-1-5",
            title: "Applying for a Loan",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection11-2",
        title: "What Are Different Loan Options",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [
          {
            id: "subsubsection11-2-1",
            title: "Healthcare Credit Card (Revolving Credit)",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-2-2",
            title: "Installment Loans",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-2-3",
            title: "Buy Now, Pay Later (BNPL)",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-2-4",
            title: "In-House Financing",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection11-2-5",
            title: "DentiRate's Combined Financing Solutions",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection11-3",
        title: "How to Sort and Find the Best Loan Options",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section12",
    title: "Support and Resources",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection12-1",
        title: "Contacting Support for Issues or Questions",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection12-2", //FAQ
        title: "FAQ",
        userGroup: ["HealthcareGroup", "Lender"],
        userRole: [
          "None",
          "SuperAdministrator",
          "Administrator",
          "Moderator",
          "Regular",
        ],
        subSections: [
          {
            id: "subsubsection12-2-1",
            title: "Access Issues",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection12-2-2",
            title: "Login Problems",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
          {
            id: "subsubsection12-2-3",
            title: "Error Messages",
            userGroup: ["HealthcareGroup"],
            userRole: ["Administrator"],
            subSections: [],
          },
        ],
      },
      {
        id: "subsection12-3",
        title: "Addressing Applicant Concerns",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection12-4",
        title: "Security and Privacy FAQs",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection12-5",
        title: "How DentiRate Handles Personal Information",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection12-6",
        title: "Assurance on Soft Pulls and Credit Score Impact",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section13",
    title: "Additional Resources",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection13-1",
        title: "Printable PDF Guides for Team Members",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection13-2",
        title: "Security Information Documents for Applicants",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection13-3",
        title: "Requesting Compliance and Security Certificates",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
    ],
  },
  {
    id: "section14",
    title: "Appendix A: Lenders Guidelines",
    userGroup: ["HealthcareGroup"],
    userRole: ["Administrator"],
    subSections: [
      {
        id: "subsection14-1",
        title: "A.1 SplitIt",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      {
        id: "subsection14-2",
        title: "A.2 ",
        userGroup: ["HealthcareGroup"],
        userRole: ["Administrator"],
        subSections: [],
      },
      // {
      //   id: "subsection14-3",
      //   title: "Lender Integration",
      //   userGroup: ["HealthcareGroup"],
      //   userRole: ["Administrator"],
      //   subSections: [],
      // },
      // {
      //   id: "subsection14-4",
      //   title: "Lender Support",
      //   userGroup: ["HealthcareGroup"],
      //   userRole: ["Administrator"],
      //   subSections: [],
      // },
    ],
  },
  //////Examples :
  // {
  //   id: "section1",
  //   title: "Introduction",
  //   userGroup: ["group1", "group2"], // Visible to both groups
  //   userRole: ["admin", "editor"], // Visible to admins and editors
  //   subSections: [],
  // },
  // {
  //   id: "section2",
  //   title: "Chapter 1: Getting Started",
  //   userGroup: ["group1"], // Only visible to group1
  //   userRole: ["admin", "editor"], // Visible to admins and editors in group1
  //   subSections: [
  //     {
  //       id: "subsection2-1",
  //       title: "What You'll Need",
  //       userGroup: ["group1", "group2"], // Visible to both groups
  //       userRole: ["admin", "editor", "viewer"], // Multiple roles allowed
  //       subSections: [],
  //     },
  //     {
  //       id: "subsection2-2",
  //       title: "Setting Up",
  //       userGroup: ["group1"], // Only visible to group1
  //       userRole: ["admin"], // Only visible to admins in group1
  //       subSections: [
  //         {
  //           id: "subsubsection2-2-1",
  //           title: "Installing Tools",
  //           userGroup: ["group1"],
  //           userRole: ["admin"],
  //           subSections: [],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: "section3",
  //   title: "Chapter 2: Advanced Topics",
  //   userGroup: ["group2"], // Only visible to group2
  //   userRole: ["viewer"], // Only visible to viewers in group2
  //   subSections: [],
  // },
  // {
  //   id: "section4",
  //   title: "Conclusion",
  //   userGroup: ["group1", "group2"], // Visible to all groups
  //   userRole: ["admin", "editor", "viewer"], // Visible to multiple roles
  //   subSections: [],
  // },
  // {
  //   id: "faq",
  //   title: "FAQ",
  //   userGroup: ["group1", "group2"], // Visible to all groups
  //   userRole: ["admin", "editor", "viewer"], // Visible to multiple roles
  //   subSections: [],
  // },
];
