import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Layout from "../../../layout";

const Courses = (props) => {
  useEffect(() => {
    document.title = "Courses | DentiRate";
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <h1>Courses</h1>
      <div>
        <h1>Embed YouTube Video</h1>
        <div style={{ width: "100%", maxWidth: "560px" }}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/erxGuBpuqrY?si=xpvUrlByIEdmbz9z"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen="true"
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/erxGuBpuqrY?si=xpvUrlByIEdmbz9z"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/erxGuBpuqrY?si=xpvUrlByIEdmbz9z"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/erxGuBpuqrY?si=xpvUrlByIEdmbz9z"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

Courses.propTypes = {};

export default Courses;
