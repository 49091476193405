import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Layout from "./layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tocDataCollection } from "./layout/table-of-contents-data";
import TutorialContent from "./contents/tutorial-content";
import { useCommonContext } from "../../../common-context";
import { data } from "jquery";

const Help = (props) => {
  const [{ user }] = useCommonContext();
  const userGroup = user.data.userType;
  const userRole = user.data.roleInOrganization;
  const navigate = useNavigate();
  const location = useLocation();
  const tocData = tocDataCollection;

  // Check if there is a hash fragment
  let hash = location.hash ? location.hash.substring(1) : ""; // Removes '#'
  let searchParams = new URLSearchParams(location.search); // Gets the search parameters (query string)

  let subSectionId = "";
  let query = "";

  if (hash) {
    // Extract values from the hash
    subSectionId = hash.split("?")[0];
    const hashParams = new URLSearchParams(hash.split("?")[1]);
    query = hashParams.get("query");
  } else {
    // Extract query parameter from the regular query string (no hash present)
    query = searchParams.get("query");
  }

  //////////search in ToC function
  // const searchToc = (query, data) => {
  //   if (!query) return [];

  //   const lowerCaseQuery = query.toLowerCase();

  //   const searchRecursive = (sections) => {
  //     let results = [];

  //     sections.forEach((section) => {
  //       if (section.title.toLowerCase().includes(lowerCaseQuery)) {
  //         results.push(section);
  //       }

  //       if (section.subSections && section.subSections.length > 0) {
  //         results = results.concat(searchRecursive(section.subSections));
  //       }
  //     });

  //     return results;
  //   };

  //   return searchRecursive(data);
  // };
  const searchToc = (query, data) => {
    if (!query) return [];

    const lowerCaseQuery = query.toLowerCase();
    let results = [];

    const searchRecursive = (sections) => {
      for (const section of sections) {
        if (section.title.toLowerCase().includes(lowerCaseQuery)) {
          // ✅ If match found, add it with all its sub-sections (but don't search deeper)
          results.push({ ...section });
        } else if (section.subSections && section.subSections.length > 0) {
          searchRecursive(section.subSections); // Continue searching
        }
      }
    };

    searchRecursive(data);
    return results;
  };

  const handleSearch = (query) => {
    navigate(`/help?query=${encodeURIComponent(query)}`);
  };
  /////////////////////////////////

  const findTitleById = (data, id) => {
    for (const section of data) {
      if (section.id === id) {
        return section.title; // Return the title if the id matches
      }
      if (section.subSections && section.subSections.length > 0) {
        // Recursively search in subSections
        const title = findTitleById(section.subSections, id);
        if (title) {
          return title; // Return the title if found in subSections
        }
      }
    }
    return null; // Return null if no matching id is found
  };

  function dataById(data, id) {
    function search(arr) {
      if (!id) return data;
      for (const item of arr) {
        if (item.id === id) {
          return item.subSections;
        }
        if (item.subSections.length > 0) {
          const result = search(item.subSections);
          if (result) return result;
        }
      }
      return null;
    }
    return search(data) || [];
  }

  // Recursive function to render the Table of Contents
  // const renderTOC = (data, currentUserGroup, currentUserRole) =>
  //   data && (
  //     <ul className={styles["list"]}>
  //       {data
  //         .filter(
  //           (section) =>
  //             section.userGroup.includes(currentUserGroup) &&
  //             section.userRole.includes(currentUserRole)
  //         ) // Check both userGroup and userRole
  //         .map((section) => (
  //           <li key={section.id}>
  //             {section.subSections && section.subSections.length > 0 ? (
  //               <>
  //                 <Link
  //                   to={`#${section.id}`}
  //                   onClick={() => {}}
  //                   className={styles["toc-link"]}
  //                 >
  //                   {section.title}
  //                 </Link>
  //                 {renderTOC(
  //                   section.subSections,
  //                   currentUserGroup,
  //                   currentUserRole
  //                 )}
  //               </>
  //             ) : (
  //               <Link
  //                 to={`#${section.id}`}
  //                 onClick={() => {}}
  //                 className={styles["toc-link"]}
  //               >
  //                 {section.title}
  //               </Link>
  //             )}
  //           </li>
  //         ))}
  //     </ul>
  //   );
  const highlightText = (text, query) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, "gi");

    return text.split(regex).map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className={styles.highlight}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const renderTOC = (data, currentUserGroup, currentUserRole, query = "") =>
    data && (
      <ul className={styles.list}>
        {data
          .filter(
            (section) =>
              section.userGroup.includes(currentUserGroup) &&
              section.userRole.includes(currentUserRole)
          )
          .map((section) => (
            <li key={section.id}>
              {section.subSections && section.subSections.length > 0 ? (
                <>
                  <Link to={`#${section.id}`} className={styles["toc-link"]}>
                    {highlightText(section.title, query)}
                  </Link>
                  {renderTOC(
                    section.subSections,
                    currentUserGroup,
                    currentUserRole,
                    query
                  )}
                </>
              ) : (
                <Link to={`#${section.id}`} className={styles["toc-link"]}>
                  {highlightText(section.title, query)}
                </Link>
              )}
            </li>
          ))}
      </ul>
    );

  useEffect(() => {
    document.title = `Tutorials${
      subSectionId && `: ${subSectionId}`
    } | DentiRate`;
    window.scrollTo(0, 0);
  }, [subSectionId]);
  return (
    <Layout
      userGroup="HealthcareGroup"
      userRole="Administrator"
      handleSearch={handleSearch}
    >
      {query ? (
        <>
          <h1 className={styles["title"]}>{`Search Results for "${query}"`}</h1>

          {searchToc(query, tocData).length > 0 ? (
            renderTOC(searchToc(query, tocData), userGroup, userRole, query)
          ) : (
            <p className={styles["text"]}>
              <span className={styles["no-results"]}>
                {" "}
                Sorry, we couldn't find any matches for your search.
              </span>{" "}
              Try refining your keywords or browsing the Table of Contents.
            </p>
          )}
        </>
      ) : (
        <>
          <h1 className={styles["title"]}>
            {findTitleById(tocData, subSectionId) || "Tutorials"}
          </h1>
          {!subSectionId ? (
            <p className={styles["text"]}>
              Please choose your proper section/sub-section from Table of
              Contents.
            </p>
          ) : (
            <TutorialContent sectionId={subSectionId} />
          )}
          {renderTOC(dataById(tocData, subSectionId), userGroup, userRole)}
        </>
      )}
    </Layout>
  );
};

Help.propTypes = {};

export default Help;
