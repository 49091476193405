import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Layout from "../../layout";
import { Container, Row, Col } from "react-bootstrap";
import { useCommonContext } from "../../common-context";
import {
  Book01Icon,
  Book02Icon,
  Book04Icon,
  BookBookmark01Icon,
} from "hugeicons-react";
import tutorialIcon from "../../images/icons/tutorial.svg";
import faqIcon from "../../images/icons/faq.svg";
import coursesIcon from "../../images/icons/courses.svg";
import { Link } from "react-router-dom";

const University = (props) => {
  const [{ user }] = useCommonContext();
  useEffect(() => {
    document.title = "University | DentiRate";
    window.scrollTo(0, 0);
    // console.log(user);
  }, []);
  return (
    <Layout isLoading={false}>
      <div className={styles["container"]}>
        <div className={styles["first-row"]}>
          <span>You have logged in as a</span>
          <span className={styles["user-role"]}>
            {` ${user.data.userType} ${user.data.roleInOrganization}`}
          </span>
        </div>

        <div className={styles["second-row"]}>
          <div className={styles["title"]}>Topics related to you</div>
          {/* <div>place for contents filter (all/seen/unseen)</div> */}
        </div>

        <Row className={styles["main-row"]}>
          <Col lg={4} md={6}>
            <Link className={styles["item"]} to="/help">
              <div className={styles["icon"]}>
                <img src={tutorialIcon} alt="Tutorial Icon" />
              </div>
              <div className={styles["title"]}>Tutorials</div>
            </Link>
          </Col>

          {/* <Col lg={4} md={6}>
            <Link className={styles["item"]} to="/university/courses">
              <div className={styles["icon"]}>
                <img src={coursesIcon} alt="Courses Icon" />
              </div>
              <div className={styles["title"]}>Courses</div>
            </Link>
          </Col> */}

          <Col lg={4} md={6}>
            <Link className={styles["item"]} to="/help#subsection12-2">
              <div className={styles["icon"]}>
                <img src={faqIcon} alt="FAQ Icon" />
              </div>
              <div className={styles["title"]}>FAQ</div>
            </Link>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

University.propTypes = {};

export default University;
