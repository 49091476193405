const ES = {
  language: "Idioma:",
  "language.Preferred": "Idioma Preferido",
  Amount: "Cantidad",
  Office: "Oficina",
  TreatmentType: "Tipo de Tratamiento",
  TreatmentDuration: "Duración del Tratamiento",
  ChartNo: "No. de Expediente",
  "Information.Contact": "Información de Contacto",
  "Information.Personal": "Información Personal",
  "Information.Credit": "Información de Crédito y Financiera",
  preferredMonthlyPayment: "Pago Mensual Preferido",
  "preferredMonthlyPayment.Description":
    "Háganos saber su pago mensual preferido para adaptar opciones de financiamiento a su presupuesto.",
  annualIncome: "Ingreso Anual del Hogar",
  "annualIncome.Description":
    "Su ingreso anual ayuda a determinar el financiamiento para el cual califica.",
  availableCredit: "Crédito Disponible en Sus Tarjetas de Crédito Actuales",
  "availableCredit.Description":
    "El total de crédito actualmente disponible en sus tarjetas de crédito.",
  ssnNumber: "Número de Seguro Social",
  AdvertisementDisclosure: "Divulgación de Anuncios",
  "there is no preapproval for your request":
    "No hay preaprobación para su solicitud",
  "contact suggested lender for further processing":
    "Contacte al prestamista sugerido para más procesamiento",
  "You can fill your preferred application":
    "Puede completar su solicitud preferida",
  here: "Aquí",
  "it may help you with lenders": "Puede ayudarle con los prestamistas",
  Congratulation: "¡Felicidades!",
  "We got you pre-approved with": "Lo hemos preaprobado para",
  lenders: "productos financieros",
  "Andhara bank": "Banco Andhara",
  Rated: "Calificado",
  "Monthly payment": "Pago Mensual",
  "Financing type": "Tipo de Financiamiento",
  "Financing amount": "Monto de Financiamiento",
  "Number of installments": "Número de cuotas",
  "Interest rate": "Tasa de Interés",
  Apply: "Solicitar",
  ApplyNow: "Solicitar Ahora",
  "About Us": "Acerca de Nosotros",
  "Terms of Service": "Términos de Servicio",
  "Privacy Policy": "Política de Privacidad",
  "Terms Conditions": "Términos y Condiciones",
  "Help Center": "Centro de Ayuda",
  "Cookies Settings": "Configuración de Cookies",

  TreatmentType_GENERALDENTISTRY: "Odontología General",
  TreatmentType_ORTHODONTICS: "Ortodoncia",
  TreatmentType_OTHERSPECIALTIES: "Otras Especialidades",

  Recommended: "Recomendado",
  MinimumMonthlyPayment: "Pago Mensual Mínimo",
  BestInterestRate: "Mejor Tasa de Interés",
  HighestAmount: "Monto Más Alto",

  LoanType_NONE: "Ninguno",
  LoanType_CREDITLINE: "Línea de Crédito",
  LoanType_LOAN: "Préstamo",
  LoanType_CREDITREVOLVER: "Crédito Revolvente",
  LoanType_INSTALLMENTPLAN: "Plan de Cuotas",
  LoanType_OTHER: "Otro",

  "Errors.UnknownError": "Ocurrió un Error",
  "Errors.InvalidLink": "Enlace Inválido",
  "Errors.InvalidApplication": "Solicitud Inválida",
  "Errors.Initiated": "Esta solicitud aún debe ser completada por la oficina.",
  "Errors.Submitted": "Esta solicitud fue enviada. Por favor espere...",
  "Errors.Processing":
    "Esta solicitud está siendo procesada. Por favor espere...",
  "Errors.Abandoned": "Esta solicitud ha sido abandonada.",
  "Errors.ProcessingError":
    "Hubo un error en el procesamiento de la solicitud. Por favor pida a la oficina que complete una nueva solicitud por usted.",
  "Errors.NotAccessible": "Esta solicitud ha expirado y ya no es accesible.",
  "Errors.UnableToLoadApplication": "No se puede cargar la solicitud.",
  "Errors.ApplicantTooManyAttempts":
    "Este enlace ya no es válido debido a demasiados intentos no exitosos.",
  "Errors.ApplicationExpired":
    "Esta solicitud ha expirado y ya no es accesible.",
  "Errors.InvalidEntries":
    "Entradas no válidas. Por favor revise su información y vuelva a intentarlo.",
  "Errors.Validation.YearOfBirth": "Año de nacimiento no válido",
  "Errors.Validation.ZipCode": "Código postal no válido",
  "Errors.Validation.Consent": "Necesita aceptar los términos y condiciones",
  Confirmation: "Por favor confirme sus datos para el siguiente paso.",
  "Confirmation.personal":
    "Por favor, confirme su información personal para el siguiente paso.",
  WelcomeToDentirate: "Bienvenido a DentiRate",
  YearOfBirth: "Año de nacimiento",
  "YearOfBirth.Enter":
    "Por favor complete los pasos para acceder a su solicitud.",
  ZipCode: "Código postal",
  "ZipCode.Enter": "Por favor, ingrese su código postal para continuar",
  Submit: "Enviar",
  SSN: "SSN",
  "SSN.Enter": "Por favor ingrese la información requerida",
  "SSN.noSsn": "El SSN no está disponible",
  "SSN.Description":
    "Proporcionar su SSN nos ayuda a encontrar las mejores opciones de financiamiento.",
  "SSN.CreditEffect": "“Este proceso no afectará su puntaje crediticio.”",
  IAgree: "Estoy de acuerdo",
  And: "y",

  "Button.Back": "Atrás",
  "Button.Continue": "Continuar",

  "ContinuingAgreement.Part1": "Al continuar, acepta nuestros ",
  "ContinuingAgreement.Part2": "Términos de servicio",

  "Consent.WeNeedYourConsent": "Necesitamos su consentimiento",
  "Consent.ReadLenderPartners":
    "Por favor, lee cuidadosamente los <terms>Términos y Condiciones</terms> de nuestros <partners>socios prestamistas</partners>.",
  "Consent.ContinuingAgreement.Part1":
    "Al continuar, acepta todos los elementos anteriores y nuestros ",
  "Consent.ContinuingAgreement.Part2": "Términos de servicio",
  "Consent.Agreement":
    'Al continuar, aceptas todos los puntos anteriores y nuestros <terms>Términos de servicio</terms> y <privacy>Política de privacidad</privacy>. Entiendo que al hacer clic en el botón ESTOY DE ACUERDO inmediatamente después de este aviso, estoy proporcionando "instrucciones escritas" a Dentirate bajo la Ley de Informe Justo de Crédito, autorizando a Dentirate a obtener información de mi perfil de crédito personal u otra información del Distribuidor. Autorizo a Dentirate a obtener dicha información únicamente para pre-calificarme para opciones de crédito relacionadas con el financiamiento de tratamientos dentales. La información crediticia accedida para mi solicitud de pre-calificación puede ser diferente de la información crediticia accedida por un otorgante de crédito en una fecha posterior a la fecha de mi solicitud original de pre-calificación para tomar la decisión de crédito.',

  "ConsentItem.ReadAndAgreed": "He leído y aceptado",
  "ConsentItem.TermsConditions": "Términos y condiciones",
  "ConsentItem.PrivacyPolicy": "Política de privacidad",
  "ConsentItem.LenderNamePossessive": "de {lenderName}",
  ApplicantPageTitle: "Tu solicitud | DentiRate",
  Identity: "Verifica tu identidad",
  FirstName: "Nombre de pila",
  LastName: "Apellido",
  doB: "Fecha de nacimiento",
  street1: "Línea 1 de la calle",
  street2: "Línea 2 de la calle",
  appUnit: "Aplicación/Unidad",
  city: "Ciudad",
  State: "Estado",
  CellPhone: "Número de teléfono celular",
  EmailAddress: "Dirección de correo electrónico",
  InsuranceCoverage: "Cobertura de seguro",
  DownPayment: "Depósito",
  notEditable:
    "Para los campos que no se pueden editar, comuníquese con nuestro equipo de soporte para realizar modificaciones.",
  prefilled: "Habilitar opción de llenado previo",
  locked:
    "Los campos están bloqueados actualmente. Para desbloquear y habilitar el botón Enviar, active la opción de llenado previo.",
  homePhone: "Número de teléfono de casa",
  workPhone: "Número de teléfono del trabajo",
};
export default ES;
