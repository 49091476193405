import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Col, FormControl, Row, Alert, Button, Form } from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  createValidationObject,
  getObjectPropertyNames,
} from "../../infrastructure/helpers/object-helpers";
import _ from "lodash";
import Logo from "./logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { IdIcon, InformationCircleIcon } from "hugeicons-react";
import CheckBox from "../../components/check-box/check-box";
import SelectControl from "../../components/select-control";
import languageType from "../../infrastructure/constants/enums/languages";
import ValidationWrapper from "../../components/validation-wrapper";
import { stateCollection } from "../../infrastructure/constants";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import useHgLocations from "../../hooks/use-hg-locations1";
import parsePhoneNumber from "libphonenumber-js";
import { noAuto } from "@fortawesome/fontawesome-svg-core";
import ToggleSwitch from "../../components/toggle-switch/toggle-switch";

const preferredLanguageCollection = languageType;

function formatDate(dateString) {
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const steps = {
  applicationInfo: 0,
  ssn: 1,
  payment: 2,
  nextStep: 3,
};
const ApplicantInfo = ({
  errorMessage = null,
  onUpdateApplicantInfo = () => {},
  application = {},
}) => {
  // console.log(application);
  const intl = useIntl();
  const [step, setStep] = useState(steps.applicationInfo);
  const [showInfo, setShowInfo] = useState(true);
  const [formData, setFormData] = useState({
    ...application.applicantInfo,
    noSsn: false,
    initialAvailableCash: application.initialAvailableCash,
    annualIncome: null,
    availableCreditLine: null,
  });
  const [locationName, setLocationName] = useState("");

  const [formDataValidation, setFormDataValidation] = useState(
    createValidationObject(formData)
  );
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );
  // console.log(formData);
  const handleChange = (name, value) => {
    // console.log(name);
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormDataValidation({
      ...formDataValidation,
      [name]: true,
    });
  };

  const validateAll = () => {
    let isValid = true;
    setFormDataValidation({
      ...formDataValidation,
      preferredLanguage:
        !!formData.preferredLanguage &&
        typeof formData.preferredLanguage === "string" &&
        formData.preferredLanguage.length > 0,
      street1:
        !!formData.street1 &&
        typeof formData.street1 === "string" &&
        formData.street1.length > 0,
      city:
        !!formData.city &&
        typeof formData.city === "string" &&
        formData.city.length > 0,
      state:
        !!formData.state &&
        typeof formData.state === "string" &&
        formData.state.length > 0,
      initialAvailableCash: formData.initialAvailableCash >= 0,
    });
    isValid =
      !!formData.preferredLanguage &&
      typeof formData.preferredLanguage === "string" &&
      formData.preferredLanguage.length > 0 &&
      !!formData.street1 &&
      typeof formData.street1 === "string" &&
      formData.street1.length > 0 &&
      !!formData.city &&
      typeof formData.city === "string" &&
      formData.city.length > 0 &&
      !!formData.state &&
      typeof formData.state === "string" &&
      formData.state.length > 0 &&
      formData.initialAvailableCash >= 0;
    // console.log(isValid);
    return isValid;
  };

  const validatePayment = () => {
    let isValid = true;
    setFormDataValidation({
      ...formDataValidation,
      annualIncome:
        !_.isNil(formData.annualIncome) && formData.annualIncome >= 0,
      availableCreditLine:
        !_.isNil(formData.availableCreditLine) &&
        formData.availableCreditLine >= 0,
    });
    isValid =
      !_.isNil(formData.annualIncome) &&
      formData.annualIncome >= 0 &&
      !_.isNil(formData.availableCreditLine) &&
      formData.availableCreditLine >= 0;
    return isValid;
  };

  const validateSsn = () => {
    let isValid = true;
    setFormDataValidation({
      ...formDataValidation,
      ssn:
        !!formData.noSsn ||
        (!!formData.ssn &&
          formData.ssn.length > 0 &&
          !!formData.ssn.trim().match(/^\d{9}$/gi)),
    });
    isValid =
      !!formData.noSsn ||
      (!!formData.ssn &&
        formData.ssn.length > 0 &&
        !!formData.ssn.trim().match(/^\d{9}$/gi));
    return isValid;
  };

  const onInitialInfoComplete = () => {
    const isValid = validateAll();
    if (!isValid) {
      return;
    }
    setStep((step) => step + 1);
  };

  const onPaymentComplete = () => {
    const isValid = validatePayment();
    if (!isValid) {
      return;
    }
    setStep((step) => step + 1);
  };
  const onSsnComplete = () => {
    const isValid = validateSsn();
    if (!isValid) {
      return;
    }
    setStep((step) => step + 1);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ssn: undefined,
    });
  }, [formData.noSsn]);
  return (
    <div
      className={
        step === steps.applicationInfo
          ? styles["verification-container"]
          : `${styles["verification-container"]} ${styles["small"]}`
      }
    >
      <Logo />
      <div className={styles["welcome-message"]}>
        <FormattedMessage id="WelcomeToDentirate" />
        <p>{application.applicantInfo.firstName}</p>
      </div>
      {!!errorMessage && typeof errorMessage === "string" && (
        <Alert className="mt-4" variant="danger">
          {errorMessage || getIntlMessage("Errors.InvalidLink")}
        </Alert>
      )}
      <form
        style={{ height: "100%" }}
        className="form mt-4 ml-4"
        onSubmit={(e) => {
          e.preventDefault();
          // if (step !== steps.nextStep) {
          //   return;
          // }
          // document.querySelectorAll("input").forEach((input) => input.blur());
          onUpdateApplicantInfo({
            newApplicantInfo: {
              ...formData,
              hasAvailableCreditLine: formData.availableCreditLine >= 0,
            },
            gotoNextStep: step === steps.nextStep,
          });
        }}
      >
        <div className={styles["flex-col"]}>
          {step !== steps.nextStep && (
            <>
              {/* Application Info Field */}
              {step === steps.applicationInfo && (
                <div>
                  <Row>
                    <div className={styles["row-label"]}>
                      <FormattedMessage id="Confirmation.personal" />
                    </div>
                  </Row>

                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="prefilled" />
                    </div>
                    <div className={styles["column-value"]}>
                      <ToggleSwitch
                        callbackFunction={() => setShowInfo(!showInfo)}
                        isChecked={showInfo}
                      />
                    </div>
                  </div>
                  {showInfo && (
                    <div className={` ${styles["description-container"]}`}>
                      <div>
                        <div className={` ${styles["description"]}`}>
                          <FormattedMessage id="notEditable" />
                        </div>
                      </div>
                    </div>
                  )}
                  {!showInfo && (
                    <div className={styles["prefill-error"]}>
                      <FormattedMessage id="locked" />
                    </div>
                  )}
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="TreatmentType" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo &&
                        ((application.treatmentType.toLowerCase() ===
                          "orthodontics" &&
                          getIntlMessage("TreatmentType_ORTHODONTICS")) ||
                          (application.treatmentType.toLowerCase() ===
                            "generaldentistry" &&
                            getIntlMessage("TreatmentType_GENERALDENTISTRY")) ||
                          (application.treatmentType.toLowerCase() ===
                            "otherspecialties" &&
                            getIntlMessage("TreatmentType_OTHERSPECIALTIES")) ||
                          "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="TreatmentDuration" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (application?.treatmentInMonth || "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="ChartNo" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo &&
                        (application.applicantInfo.hgPatientReferenceId || "-")}
                    </div>
                  </div>
                  <hr />
                  {/* <h5 className={styles["info-title"]}>
                    <span>
                      <FormattedMessage id="Information.Personal" />
                    </span>
                  </h5> */}
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="FirstName" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (application.applicantInfo.firstName || "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="LastName" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (application.applicantInfo.lastName || "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="doB" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo &&
                        (formatDate(application.applicantInfo.doB) || "-")}
                    </div>
                  </div>
                  <hr className={styles["last"]} />
                  <div className="relative">
                    <ValidationWrapper
                      isValid={formDataValidation.preferredLanguage}
                      title={getIntlMessage("language.Preferred")}
                      titleStar={true}
                      errorMessage="Invalid preferred language"
                    >
                      <SelectControl
                        className={`${styles["input"]} `}
                        options={preferredLanguageCollection.collection}
                        value={
                          showInfo &&
                          preferredLanguageCollection.collection.find(
                            (i) =>
                              i.value ===
                              preferredLanguageCollection.getValueByText(
                                formData.preferredLanguage
                              )
                          )
                        }
                        isClearable={false}
                        onChange={(v) =>
                          handleChange("preferredLanguage", v.value)
                        }
                        placeholder=""
                      />
                    </ValidationWrapper>
                  </div>
                  <div className="relative" xs={12} md={6} lg={3}>
                    <ValidationWrapper
                      isValid={formDataValidation.street1}
                      title={getIntlMessage("street1")}
                      titleStar={true}
                      errorMessage="Invalid street line"
                    >
                      <Form.Control
                        className={`form-control ${
                          !formDataValidation.street1 && "danger"
                        }`}
                        value={showInfo ? formData.street1 || "" : ""}
                        onChange={(e) =>
                          handleChange("street1", e.target.value)
                        }
                        autoComplete="off"
                        disabled={!showInfo}
                      />
                    </ValidationWrapper>
                  </div>
                  <div className="relative" xs={12} md={6} lg={2}>
                    <ValidationWrapper
                      isValid={formDataValidation.street2}
                      title={getIntlMessage("street2")}
                      errorMessage="Invalid street line"
                    >
                      <Form.Control
                        value={showInfo ? formData.street2 || "" : ""}
                        onChange={(e) =>
                          handleChange("street2", e.target.value)
                        }
                        autoComplete="off"
                        disabled={!showInfo}
                      />
                    </ValidationWrapper>
                  </div>
                  <div className="relative" xs={12} md={6} lg={1}>
                    <ValidationWrapper
                      isValid={formDataValidation.apartmentOrUnit}
                      title={getIntlMessage("appUnit")}
                      errorMessage="Invalid apt"
                    >
                      <Form.Control
                        value={showInfo ? formData.apartmentOrUnit || "" : ""}
                        onChange={(e) =>
                          handleChange("apartmentOrUnit", e.target.value)
                        }
                        autoComplete="off"
                        disabled={!showInfo}
                      />
                    </ValidationWrapper>
                  </div>
                  <div className="relative" xs={12} md={6} lg={2}>
                    <ValidationWrapper
                      isValid={formDataValidation.city}
                      title={getIntlMessage("city")}
                      titleStar={true}
                      errorMessage="Invalid city"
                    >
                      <Form.Control
                        className={`form-control ${
                          !formDataValidation.city && "danger"
                        }`}
                        value={showInfo ? formData.city || "" : ""}
                        onChange={(e) => handleChange("city", e.target.value)}
                        autoComplete="off"
                        disabled={!showInfo}
                      />
                    </ValidationWrapper>
                  </div>
                  <div className="relative" xs={12} md={6} lg={2}>
                    <ValidationWrapper
                      isValid={formDataValidation.state}
                      title={getIntlMessage("State")}
                      titleStar={true}
                      errorMessage="Invalid state"
                    >
                      <SelectControl
                        className={`${styles["input"]} ${
                          !formDataValidation.state && styles["not-valid"]
                        }`}
                        options={stateCollection}
                        isClearable={false}
                        value={
                          showInfo &&
                          (!!formData.state
                            ? stateCollection.find(
                                (i) => i.value === formData.state
                              )
                            : null)
                        }
                        onChange={(v) => handleChange("state", v.value)}
                        placeholder=""
                      />
                    </ValidationWrapper>
                  </div>
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="ZipCode" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (formData.zipcode || "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="CellPhone" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (formData.cellPhone || "-")}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="EmailAddress" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo && (formData.emailAddress || "-")}
                    </div>
                  </div>
                  <hr />
                  {!!formData.homePhone && (
                    <ValidationWrapper
                      isValid={formDataValidation.homePhone || true}
                      title={getIntlMessage("homePhone")}
                      inputId="input-home-phone"
                      errorMessage="Invalid home phone"
                    >
                      <PatternFormat
                        id="input-home-phone"
                        name="number"
                        className="form-control"
                        autoComplete="off"
                        customInput={FormControl}
                        value={showInfo ? formData.homePhone || "" : ""}
                        onValueChange={(values) => {
                          handleChange("homePhone", values.value);
                        }}
                        type="tel"
                        format="+1 (###) ###-####"
                        mask="_"
                      />
                    </ValidationWrapper>
                  )}
                  {!!formData.workPhone && (
                    <ValidationWrapper
                      isValid={formDataValidation.workPhone || true}
                      title={getIntlMessage("workPhone")}
                      inputId="input-work-phone"
                      errorMessage="Invalid work phone"
                    >
                      <PatternFormat
                        id="input-work-phone"
                        name="number"
                        className="form-control"
                        autoComplete="off"
                        customInput={FormControl}
                        value={showInfo ? formData.workPhone || "" : ""}
                        onValueChange={(values) => {
                          handleChange("workPhone", values.value);
                        }}
                        type="tel"
                        format="+1 (###) ###-####"
                        mask="_"
                      />
                    </ValidationWrapper>
                  )}
                  {/* <h5 className={styles["info-title"]}>
                    <span>
                      <FormattedMessage id="Information.Credit" />
                    </span>
                  </h5> */}
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="Amount" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo &&
                        commaSeperatedCurrency(application.amount, 0)}
                    </div>
                  </div>
                  <hr />
                  <div className={styles["inital-info-summary"]}>
                    <div className={styles["column-label"]}>
                      <FormattedMessage id="InsuranceCoverage" />
                    </div>
                    <div className={styles["column-value"]}>
                      {showInfo &&
                        commaSeperatedCurrency(
                          application.insuranceCoverage,
                          0
                        )}
                    </div>
                  </div>
                  <hr className={styles["last"]} />
                  <ValidationWrapper
                    isValid={formDataValidation.initialAvailableCash}
                    title={getIntlMessage("DownPayment")}
                    titleStar={true}
                    inputId="down-payment"
                    errorMessage="Invalid Upfront payment"
                  >
                    <NumericFormat
                      id="down-payment"
                      name="number"
                      className={`form-control `}
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder=""
                      autoComplete="off"
                      customInput={FormControl}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      value={showInfo ? application.initialAvailableCash : ""}
                      onValueChange={(values) => {
                        handleChange("initialAvailableCash", values.floatValue);
                      }}
                      maxLength={50}
                      disabled={!showInfo}
                    />
                  </ValidationWrapper>

                  <Button
                    variant="primary"
                    className={`${styles["continue"]}`}
                    type="button"
                    onClick={onInitialInfoComplete} // Navigate to the next step
                    disabled={!showInfo}
                  >
                    {getIntlMessage("Button.Continue")}
                  </Button>
                </div>
              )}

              {/* SSN Field */}
              {step === steps.ssn && (
                <div>
                  <div>
                    <div className={styles["row-label"]}>
                      <FormattedMessage id="SSN.Enter" />
                    </div>
                  </div>

                  <ValidationWrapper
                    isValid={formDataValidation.ssn}
                    title={getIntlMessage("SSN")}
                    titleStar={true}
                    inputId="input-ssn"
                    errorMessage={"Invalid SSN"}
                  >
                    <PatternFormat
                      id="input-ssn"
                      allowEmptyFormatting
                      valueIsNumericString={true}
                      className={`form-control `}
                      autoComplete="off"
                      value={formData.ssn || ""}
                      onValueChange={(values) => {
                        handleChange("ssn", values.value);
                      }}
                      format="###-##-####"
                      mask="_"
                      disabled={formData.noSsn}
                    />
                    <div className={styles["checkbox-container"]}>
                      <CheckBox
                        text={getIntlMessage("SSN.noSsn")}
                        onClick={(e) => {
                          handleChange("noSsn", !(formData.noSsn || false));
                          setFormDataValidation({
                            ...formDataValidation,
                            ssn: true,
                          });
                        }}
                        Checked={formData?.noSsn || false}
                      />
                    </div>
                  </ValidationWrapper>
                  <div className={` ${styles["description-container"]}`}>
                    <InformationCircleIcon
                      className={` ${styles["description-icon"]}`}
                    />
                    <div>
                      <div className={` ${styles["description"]}`}>
                        {getIntlMessage("SSN.Description")}
                      </div>
                      <div className={` ${styles["description2"]}`}>
                        {getIntlMessage("SSN.CreditEffect")}
                      </div>
                    </div>
                  </div>
                  <Row className={styles["btn-container"]}>
                    <Col xs={4} style={{ padding: 0 }}>
                      <Button
                        variant="dark"
                        className={`mt_3 w_100 ${styles["back"]}`}
                        type="button"
                        onClick={() => {
                          setStep((step) => step - 1); // Navigate back to the new step
                        }}
                      >
                        {getIntlMessage("Button.Back")}
                      </Button>
                    </Col>
                    <Col xs={8} style={{ padding: 0 }}>
                      <Button
                        variant="primary"
                        className={styles["continue"]} // Submit the form
                        onClick={onSsnComplete}
                      >
                        {getIntlMessage("Button.Continue")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}

              {/* Preferred Monthly Payment Field */}
              {step === steps.payment && (
                <div className={styles["payment"]}>
                  <ValidationWrapper
                    title={getIntlMessage("preferredMonthlyPayment")}
                    titleStar={false}
                    inputId="preferredMonthlyPayment"
                    errorMessage="Invalid preferred monthly payment"
                  >
                    <NumericFormat
                      thousandSeparator
                      prefix="$"
                      value={formData.preferredMonthlyPayment || ""}
                      onValueChange={(values) =>
                        handleChange("preferredMonthlyPayment", values.value)
                      }
                      className="form-control"
                    />
                  </ValidationWrapper>
                  {/* <div className={` ${styles["description"]}`}>
                      {getIntlMessage("preferredMonthlyPayment.Description")}
                    </div> */}

                  {/* Annual Income Field */}

                  <ValidationWrapper
                    isValid={formDataValidation.annualIncome}
                    title={getIntlMessage("annualIncome")}
                    titleStar={true}
                    inputId="annual-income"
                    errorMessage="Invalid annual income"
                  >
                    <NumericFormat
                      name="number"
                      className={`form-control`}
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder=""
                      autoComplete="off"
                      customInput={FormControl}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      inputId="annual-income"
                      value={formData.annualIncome}
                      onValueChange={(values) =>
                        handleChange("annualIncome", values.value)
                      }
                    />
                  </ValidationWrapper>
                  {/* <div className={` ${styles["description"]}`}>
                      {getIntlMessage("annualIncome.Description")}
                    </div> */}

                  {/* Available Credit Field */}
                  <ValidationWrapper
                    isValid={formDataValidation.availableCreditLine}
                    title={getIntlMessage("availableCredit")}
                    titleStar={true}
                    inputId="available-credit-line"
                    errorMessage="Invalid Available Credit"
                  >
                    <NumericFormat
                      id="available-credit-line"
                      name="number"
                      className={`form-control`}
                      thousandSeparator={true}
                      prefix={"$"}
                      placeholder=""
                      autoComplete="off"
                      customInput={FormControl}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      value={formData.availableCreditLine}
                      onValueChange={(values) => {
                        handleChange("availableCreditLine", values.floatValue);
                      }}
                      maxLength={7}
                    />
                  </ValidationWrapper>
                  {/* <div className={` ${styles["description"]}`}>
                      {getIntlMessage("availableCredit.Description")}
                    </div> */}

                  <Row className={styles["btn-container"]}>
                    <Col xs={4} style={{ padding: 0 }}>
                      <Button
                        variant="dark"
                        className={`mt_3 w_100 ${styles["back"]}`}
                        type="button"
                        onClick={() => {
                          setStep((step) => step - 1); // Navigate back to the new step
                        }}
                      >
                        {getIntlMessage("Button.Back")}
                      </Button>
                    </Col>
                    <Col xs={8} style={{ padding: 0 }}>
                      <Button
                        variant="primary"
                        className={styles["continue"]} // Submit the form
                        onClick={onPaymentComplete}
                      >
                        {getIntlMessage("Button.Continue")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}

              {/* Navigation Buttons */}
              {/* <Row
                className={styles["btn-container"]}
                style={{ marginTop: "20px" }}
              >
                {step !== steps.applicationInfo && (
                  <Col xs={4} style={{ padding: 0 }}>
                    <Button
                      variant="dark"
                      className={`mt_3 w_100 ${styles["back"]}`}
                      type="button"
                      onClick={() => {
                        setStep((step) => step - 1); // Navigate back to the new step
                      }}
                    >
                      {getIntlMessage("Button.Back")}
                    </Button>
                  </Col>
                )}
                {step !== steps.applicationInfo && (
                  <Col
                    xs={step === steps.applicationInfo ? 12 : 8}
                    style={{ padding: 0 }}
                  >
                    {
                      <Button
                        variant="primary"
                        className={`${styles["continue"]}`}
                        type="button"
                        onClick={() => {
                          setStep((step) => step + 1);
                        }} // Navigate to the next step
                        disabled={
                          !(
                            (
                              (!!formData.ssn && formData.ssn.length === 9) ||
                              formData.noSsn
                            )
                            //   && !!formData.preferredMonthlyPayment &&
                            // !!formData.annualIncome &&
                            // formData.availableCreditLine >= 0 &&
                            // formDataValidation.ssn &&
                            // formDataValidation.preferredMonthlyPayment &&
                            // formDataValidation.annualIncome &&
                            // formDataValidation.availableCreditLine
                          )
                        }
                      >
                        {getIntlMessage("Button.Continue")}
                      </Button>
                    }
                  </Col>
                )}
              </Row> */}
            </>
          )}

          {step === steps.nextStep && (
            <>
              <div>
                <Row>
                  <div className={styles["row-label"]}>
                    {getIntlMessage("Confirmation")}
                  </div>
                </Row>

                {/* SSN */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    {/* <IdIcon /> */}
                    <FormattedMessage id="ssnNumber" />
                  </div>
                  <div className={styles["column-value"]}>
                    {formData.ssn || "-"}
                  </div>
                </div>
                <hr />

                {/* Preferred Monthly Payment */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                    <FormattedMessage id="preferredMonthlyPayment" />
                  </div>
                  <div className={styles["column-value"]}>
                    $
                    {parseFloat(
                      formData.preferredMonthlyPayment || 0
                    ).toLocaleString()}
                  </div>
                </div>
                <hr />

                {/* Annual Income */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                    <FormattedMessage id="annualIncome" />
                  </div>
                  <div className={styles["column-value"]}>
                    ${parseFloat(formData.annualIncome || 0).toLocaleString()}
                  </div>
                </div>

                <hr />

                {/* Annual Income */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                    <FormattedMessage id="availableCredit" />
                  </div>
                  <div className={styles["column-value"]}>
                    $
                    {parseFloat(
                      formData.availableCreditLine || 0
                    ).toLocaleString()}
                  </div>
                </div>
              </div>

              <Row className={styles["btn-container"]}>
                <Col xs={4} style={{ padding: 0 }}>
                  <Button
                    variant="dark"
                    className={`mt_3 w_100 ${styles["back"]}`}
                    type="button"
                    onClick={() => {
                      setStep((step) => step - 1); // Navigate back to the new step
                    }}
                  >
                    {getIntlMessage("Button.Back")}
                  </Button>
                </Col>
                <Col xs={8} style={{ padding: 0 }}>
                  <Button
                    variant="primary"
                    className={styles["continue"]} // Submit the form
                    type="submit"
                  >
                    {getIntlMessage("Button.Continue")}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ApplicantInfo;
